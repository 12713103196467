import { useField } from 'formik';
import React from 'react';
import { useFormikError } from '../useFormikError';
import {
  MaskedSingleLineText,
  MaskedSingleLineTextProps,
} from './MaskedSingleLineText';
/**
 * This component should be used to render a Masked TextBox inside a Formik form.
 *
 * @example
 * <Field 
 *  name="title" 
 *  label="Title" 
 *  as={MaskedSingleLineTextField} 
    mask={'00:00:00.000'}
    placeholderChar={'0'} />
 */
export const MaskedSingleLineTextField: React.FC<
  Omit<MaskedSingleLineTextProps, 'error' | 'onChange'>
> = (props) => {
  const error = useFormikError(props.name);

  const [, meta, helpers] = useField(props.name);

  const handleChange = (value: string): void => {
    helpers.setValue(value);

    if (value === meta.initialValue) {
      helpers.setTouched(false);
    }
  };

  return (
    <MaskedSingleLineText {...props} onChange={handleChange} error={error} />
  );
};
