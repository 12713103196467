import * as React from 'react';
import { Link } from 'react-router-dom';
import classes from './NavigationFooter.module.scss';

export interface NavigationFooterProps {
  path: string;
}

export const NavigationFooter: React.FC<NavigationFooterProps> = ({ path }) => {
  return (
    <footer className={classes.footer}>
      <Link to={path} className={classes.link}>
        <div>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 120"
          >
            <path
              fill="#EA5542"
              d="M59.4,119.4c-4.1,0-8.2-1-11.8-2.9L13.9,99.1C5.4,94.7,0,85.9,0,76.3V43.7
	c0-9.6,5.4-18.5,13.9-22.9L47.6,3.4c7.4-3.8,16.2-3.8,23.6,0l33.7,17.4c8.6,4.4,13.9,13.2,13.9,22.9v32.6c0,9.6-5.4,18.5-13.9,22.9
	l-33.7,17.4C67.6,118.4,63.5,119.4,59.4,119.4 M27.2,75.4L59.4,92l32.2-16.6V44.6L59.4,28L27.2,44.6V75.4z M60.1,27.6L60.1,27.6
	L60.1,27.6"
            />
            <path
              fill="#FEBC12"
              d="M59.4,114.9c-3.4,0-6.7-0.8-9.7-2.4L16,95.1C9,91.5,4.5,84.2,4.5,76.3V43.7C4.5,35.8,9,28.5,16,24.9L49.7,7.5
	c6.1-3.1,13.3-3.1,19.4,0l33.7,17.4c7.1,3.6,11.5,10.9,11.5,18.8v32.6c0,7.9-4.4,15.2-11.5,18.8l-33.7,17.4
	C66.1,114.1,62.8,114.9,59.4,114.9"
            />
            <path
              fill="#1099D6"
              d="M59.4,110.3c-2.7,0-5.3-0.6-7.6-1.9L18.1,91.1c-5.5-2.8-9-8.6-9-14.8V43.7c0-6.2,3.5-11.9,9-14.8
	l33.7-17.4c4.8-2.5,10.5-2.5,15.3,0l33.7,17.4c5.5,2.8,9,8.6,9,14.8v32.6c0,6.2-3.5,11.9-9,14.8l-33.7,17.4
	C64.7,109.7,62.1,110.3,59.4,110.3 M55.9,19.6L22.3,37c-2.5,1.3-4.1,3.9-4.1,6.7v32.6c0,2.8,1.6,5.4,4.1,6.7L56,100.4
	c2.2,1.1,4.8,1.1,6.9,0L96.6,83c2.5-1.3,4.1-3.9,4.1-6.7V43.7c0-2.8-1.6-5.4-4.1-6.7L62.9,19.6C60.7,18.5,58.1,18.5,55.9,19.6"
            />
            <path
              fill="#1C3A69"
              d="M53.9,15.5c3.5-1.8,7.6-1.8,11.1,0L98.7,33c4,2.1,6.6,6.2,6.6,10.8v32.6c0,4.5-2.5,8.7-6.6,10.8
	L65,104.5c-3.5,1.8-7.6,1.8-11.1,0L20.2,87c-4-2.1-6.6-6.2-6.6-10.8V43.7c0-4.5,2.5-8.7,6.6-10.8L53.9,15.5z"
            />
            <path
              fill="#1099D6"
              d="M36.8,62c0.9-0.5,1.9-0.5,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
	c-0.9,0.5-1.9,0.5-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L36.8,62z"
            />
            <path
              fill="#1C3A69"
              d="M38.2,80.7c-0.7,0-1.3-0.2-1.9-0.5l-6-3.1C28.9,76.4,28,75,28,73.5v-5.7c0-1.6,0.9-3,2.2-3.7
	l6-3.1c1.2-0.6,2.6-0.6,3.8,0l6,3.1c1.4,0.7,2.2,2.1,2.2,3.7v5.7c0,1.6-0.9,3-2.2,3.7l-6,3.1C39.5,80.6,38.9,80.7,38.2,80.7
	 M38.2,62.8c-0.3,0-0.6,0.1-0.9,0.2l0,0l-6,3.1c-0.6,0.3-1,1-1,1.7v5.7c0,0.7,0.4,1.4,1,1.7l6,3.1c0.5,0.3,1.2,0.3,1.7,0l6-3.1
	c0.6-0.3,1-1,1-1.7v-5.7c0-0.7-0.4-1.4-1-1.7L39,63C38.8,62.8,38.5,62.8,38.2,62.8"
            />
            <path
              fill="#FEBC12"
              d="M36.8,51.3c0.9-0.4,1.9-0.4,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
	c-0.9,0.5-1.9,0.5-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L36.8,51.3z"
            />
            <path
              fill="#EA5542"
              d="M36.8,40.7c0.9-0.4,1.9-0.4,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
	c-0.9,0.4-1.9,0.4-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L36.8,40.7z"
            />
            <path
              fill="#EA5542"
              d="M82,62c-0.9-0.5-1.9-0.5-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7l6,3.1
	c0.9,0.5,1.9,0.5,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L82,62z"
            />
            <path
              fill="#FEBC12"
              d="M82,51.3c-0.9-0.4-1.9-0.4-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7l6,3.1
	c0.9,0.5,1.9,0.5,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L82,51.3z"
            />
            <path
              fill="#1099D6"
              d="M82,40.7c-0.9-0.4-1.9-0.4-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7l6,3.1
	c0.9,0.4,1.9,0.4,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L82,40.7z"
            />
            <path
              fill="#1099D6"
              d="M47.4,46.1c0.9-0.5,1.9-0.5,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
	c-0.9,0.5-1.9,0.5-2.8,0l-6.1-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L47.4,46.1z"
            />
            <path
              fill="#EA5542"
              d="M71.4,46.1c-0.9-0.5-1.9-0.5-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7
	l6,3.1c0.9,0.5,1.9,0.5,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L71.4,46.1z"
            />
            <path
              fill="#FEBC12"
              d="M58,51.7c0.9-0.4,1.9-0.4,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
	c-0.9,0.4-1.9,0.4-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L58,51.7z"
            />
          </svg>
        </div>
        <div className={classes.footerText}>
          <div className={classes.title}>Axinom Mosaic</div>
          <div className={classes.copyright}>
            Copyright &copy; {process.env.BUILD_YEAR} Axinom - All Reserved
          </div>
        </div>
      </Link>
    </footer>
  );
};
