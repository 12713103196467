import { Breadcrumb } from '@axinom/mosaic-shared-components';
import React, { ReactNode, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AnimationDirection } from './Slide.model';
import './Slide.module.scss';

export interface SlideProps {
  children: ReactNode;
  /** The breadcrumbs to determine the direction of the transition. */
  breadcrumbs: Breadcrumb[];
}

export const getAnimationName = (
  newBreadcrumbs: Breadcrumb[],
  previousBreadcrumbs: Breadcrumb[],
): AnimationDirection => {
  if (!previousBreadcrumbs && !newBreadcrumbs) {
    // Null check
    return AnimationDirection.None;
  }

  for (let i = 0; i < previousBreadcrumbs.length; i++) {
    const breadcrumb = newBreadcrumbs[i];
    const previousBreadcrumb = previousBreadcrumbs[i];

    if (
      breadcrumb &&
      previousBreadcrumb &&
      breadcrumb.url !== previousBreadcrumb.url
    ) {
      // Paths have diverged. Ex: Navigated from one station to a different station
      return AnimationDirection.Forward;
    }

    if (!breadcrumb) {
      // Navigated backward
      return AnimationDirection.Backward;
    }
  }

  if (newBreadcrumbs.length > previousBreadcrumbs.length) {
    // Navigated forward
    return AnimationDirection.Forward;
  }

  // No navigation occurred
  return AnimationDirection.None;
};

/**
 * Adds slide transitions for route changes.
 *
 * The component needs an array of BreadCrumb elements to decide the direction of the transition that should be executed.
 *
 * Wrap the piral <PiralRoutes> component with this component and pass it the breadcrumbs array.
 *
 * @example
 *  <Slide breadcrumbs={breadcrumbs}>
 *    <PiralRoutes location={location} NotFound={NotFound} />
 *  </Slide>
 */
export const Slide: React.FC<SlideProps> = ({ breadcrumbs, children }) => {
  const location = useLocation();

  const previousBreadcrumbsRef = useRef([...breadcrumbs]);

  return (
    <TransitionGroup
      childFactory={(child) => {
        const animatedDirection = getAnimationName(
          breadcrumbs,
          previousBreadcrumbsRef.current,
        );
        return React.cloneElement(child, {
          classNames: animatedDirection,
        });
      }}
    >
      <CSSTransition
        timeout={500}
        key={location.key}
        onEntered={() => {
          previousBreadcrumbsRef.current = [...breadcrumbs];
        }}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};
