import React from 'react';
import axinomFullLogo from '../../resources/images/ax_logo_blue.svg';
import { useIdentityService } from '../IdentityServiceProvider/IdentityServiceProvider';
import { BrandingOptions } from '../common/BrandingOptions';
import classes from './AccountInactiveError.module.scss';

const DEFAULT_BG_COLOR = `#01477D`;

interface AccountInactiveErrorProps {
  brandingOptions?: BrandingOptions;
}

export const AccountInactiveError: React.FC<AccountInactiveErrorProps> = ({
  brandingOptions,
}) => {
  const { logoutUser } = useIdentityService();

  const background = brandingOptions?.background ?? DEFAULT_BG_COLOR;
  const backgroundStyles = {
    background: background?.includes('/') ? `url(${background})` : background,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
  };

  return (
    <div className={classes.container} style={backgroundStyles}>
      <div className={classes.whiteBox}>
        <div className={classes.logo}>
          <img
            alt="Customer logo"
            src={brandingOptions?.companyLogo ?? axinomFullLogo}
          />
        </div>
        <div className={classes.header}>Account not activated</div>
        <div className={classes.authTitle}>
          <p>
            The user was successfully authenticated via the Identity Provider,
            but is not authorized to access the Management System.
          </p>
          <p>
            Please contact your Management System Administrator to get this
            account activated, or try to sign in with a different account.
          </p>
        </div>
        <div className={classes.logoutButton} onClick={logoutUser}>
          <div className={classes.text}>Logout</div>
        </div>
      </div>
    </div>
  );
};
