import React from 'react';

export enum IconName {
  Chevron,
  ExpandMenu,
  Home,
  ShowMoreDots,
}

export interface IconsProps {
  icon: IconName;
  className?: string;
}

/**
 * Breadcrumb arrow
 * @example
 * <Chevron />
 */
const Chevron: React.FC<Omit<IconsProps, 'icon'>> = ({ className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 31"
    enableBackground="new 0 0 20 31"
    className={className}
  >
    <path
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="3"
      strokeLinecap="square"
      d="M3,2.9l14.2,11.9L3,27.9"
    />
  </svg>
);

/**
 * ExpandMenu icon
 * @example
 * <ExpandMenu />
 */
const ExpandMenu: React.FC<Omit<IconsProps, 'icon'>> = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M4,9.8h32 M4,20h32 M4,30.2h32"
    />
  </svg>
);

/**
 * Home icon
 * @example
 * <Home />
 */
const Home: React.FC<Omit<IconsProps, 'icon'>> = ({ className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={className}
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M39.2,19.4L19.7,2.4l-6.8,6.3V3.8H8.2v8.9l-7.4,6.8
	 M34.8,22.8L19.8,10L5,22.8v14.8h9.9v-9.1h9.8v9.1h10.1L34.8,22.8z"
    />
  </svg>
);

/**
 * Breadcrumb show more dots
 * @example
 * <ShowMoreIcon />
 */
const ShowMoreDots: React.FC<Omit<IconsProps, 'icon'>> = ({ className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 4"
    className={className}
  >
    <g>
      <path
        fill="none"
        d="M2.2,3.5c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-0.9s0.6-0.4,0.9-0.4S2.8,1,3.1,1.3
		c0.3,0.3,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,1C2.8,3.4,2.5,3.5,2.2,3.5z"
      />
      <path
        fill="none"
        d="M10.5,3.5c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-0.9s0.6-0.4,0.9-0.4s0.7,0.1,0.9,0.4
		c0.3,0.3,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,1C11.2,3.4,10.9,3.5,10.5,3.5z"
      />
      <path
        fill="none"
        d="M18.8,3.5c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-0.9s0.6-0.4,0.9-0.4s0.7,0.1,0.9,0.4
		c0.3,0.3,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,1C19.5,3.4,19.2,3.5,18.8,3.5z"
      />
    </g>
  </svg>
);

export const Icons: React.FC<IconsProps> = ({ icon, className }) => {
  const icons: { [key in IconName]: JSX.Element } = {
    [IconName.Chevron]: <Chevron className={className} />,
    [IconName.ExpandMenu]: <ExpandMenu className={className} />,
    [IconName.Home]: <Home className={className} />,
    [IconName.ShowMoreDots]: <ShowMoreDots className={className} />,
  };
  return icons[icon];
};
