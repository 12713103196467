import clsx from 'clsx';
import React from 'react';
import classes from './FormGroupTitle.scss';

export interface FormGroupTitleProps {
  className?: string;
  /** Group title to display */
  title: string;
}

export const FormGroupTitle: React.FC<FormGroupTitleProps> = ({
  className = '',
  title,
}) => (
  <div
    className={clsx(classes.container, 'FormGroupTitle-container', className)}
  >
    {title}
  </div>
);
