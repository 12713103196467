import clsx from 'clsx';
import React from 'react';
import classes from './InfoPanel.scss';

export interface InfoPanelProps {
  /** Width of the container */
  width?: string;

  /** Optional class */
  className?: string;
}

export const InfoPanel: React.FC<InfoPanelProps> = ({
  children,
  className,
  width = '480px',
}) => {
  return (
    <div
      className={clsx(className, 'info-panel-container', classes.container)}
      style={{ width }}
      data-test-id="info-panel"
    >
      <div className={classes.content}>{children}</div>
      <div className={classes.filler} />
    </div>
  );
};
