import { buildName, PiralPlugin } from 'piral-core';
import { RegisterNavigationItem } from '../NavigationExtension';
import * as actions from './actions';
import { HomeApi, HomeTileData, SettingsTileData } from './types';
import { withState } from './withState';

/**
 * Creates new Pilet API extensions for registering tiles for the landing page.
 */
export function createHomeApi(): PiralPlugin<HomeApi> {
  return (context) => {
    context.defineActions(actions);

    context.dispatch((state) => ({
      ...state,
      registry: {
        ...state.registry,
        home: {
          tiles: {},
        },
      },
    }));

    return (api, target) => {
      const pilet = target.name ?? 'unknown';
      let next = 0;

      return {
        registerTile(
          data: HomeTileData | SettingsTileData,
          registerNavItem = true,
        ) {
          const name = data.name ? data.name : String(next++);

          const id = buildName(pilet, name);
          context.registerTile(id, data);

          let unregisterNavItem: () => void;
          if (registerNavItem) {
            const isSettings = data.kind === 'settings';
            const navItem: RegisterNavigationItem = {
              categoryName: isSettings ? 'Settings' : 'Workflows',
              icon: data.icon,
              label: data.label,
              name: data.name,
              path: data.path,
            };

            unregisterNavItem = api.registerNavigationItem(navItem);
          }

          return () => {
            api.unregisterTile(name);
            if (unregisterNavItem) {
              unregisterNavItem();
            }
          };
        },
        unregisterTile(name) {
          const id = buildName(pilet, name);
          context.unregisterTile(id);
        },
        createHomeConnector() {
          return (component) => withState(component);
        },
        setHomeTileSorter(sorter) {
          context.setHomeTileSorter(sorter);
        },
      };
    };
  };
}
