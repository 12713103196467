import { Split, SplitProps } from '@geoffcox/react-splitter';
import React from 'react';

interface ConditionalSplitProps extends React.PropsWithChildren<SplitProps> {
  shouldSplit?: boolean;
}

export const ConditionalSplit: React.FC<ConditionalSplitProps> = ({
  shouldSplit = true,
  children,
  ...rest
}) => {
  return (
    <Split
      initialPrimarySize={shouldSplit ? '50%' : '100%'}
      minPrimarySize={shouldSplit ? '0' : '100%'}
      splitterSize={shouldSplit ? '7px' : '0'}
      {...rest}
    >
      {children}
    </Split>
  );
};
