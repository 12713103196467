import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { noop } from '../../../helpers/utils';
import { ButtonContext } from '../Button.model';
import {
  TextButtonProps,
  TextContextButtonProps,
  TextNavigationButtonProps,
} from './TextButton.model';
import classes from './TextButton.scss';

/**
 * Button which can render a text string.
 * @example
 *   // Rendered as button
 *   <TextButton type="button" text="Click me!" />
 *
 *   // Rendered as link
 *   <TextButton path="/home" text="Click me!" />
 */
export const TextButton: React.FC<TextButtonProps> = ({
  className,
  dataTestId = undefined,
  disabled = false,
  height,
  path,
  text = '',
  width,
  openInNewTab,
  ...rest
}) => {
  const commonProps = { className, dataTestId, height, text, width, disabled };

  return path ? (
    <TextNavigationButton
      {...commonProps}
      path={path}
      openInNewTab={openInNewTab}
    />
  ) : (
    <TextContextButton {...commonProps} {...rest} />
  );
};

const TextContextButton: React.FC<TextContextButtonProps> = ({
  type = 'button',
  height,
  width,
  buttonContext = ButtonContext.Context,
  disabled = false,
  text = '',
  className,
  dataTestId = undefined,
  onButtonClicked = noop,
}) => {
  return (
    <button
      className={clsx(
        classes.container,
        {
          [classes.context]: buttonContext === ButtonContext.Context,
          [classes.active]: buttonContext === ButtonContext.Active,
        },
        'text-button-container',
        className,
      )}
      type={type}
      style={{
        height,
        width,
      }}
      onClick={onButtonClicked}
      disabled={disabled}
      data-test-id={dataTestId ?? 'text-button'}
    >
      {text}
    </button>
  );
};

const TextNavigationButton: React.FC<TextNavigationButtonProps> = ({
  height,
  width,
  disabled = false,
  text = '',
  className,
  dataTestId = undefined,
  path,
  openInNewTab = false,
}) => {
  return (
    <Link
      to={path}
      className={clsx(
        classes.container,
        classes.navigation,
        {
          [classes.disabled]: disabled,
        },
        'text-button-container',
        className,
      )}
      style={{
        height,
        width,
      }}
      data-test-id={dataTestId ?? 'text-button'}
      target={openInNewTab ? '_blank' : undefined}
    >
      {text}
    </Link>
  );
};
