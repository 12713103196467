import { SaveIndicatorType } from '@axinom/mosaic-shared-components';
import { GlobalStateContext } from 'piral-core';
import { ReactNode } from 'react';
import { IndicatorId } from './types';

export function addIndicator(
  ctx: GlobalStateContext,
  content: ReactNode,
): IndicatorId {
  const id = ctx.readState(
    (state) => state.registry.indicator.lastIndicatorId + 1,
  );

  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      indicator: {
        ...state.registry.indicator,
        activeIndicators: [
          { id, content },
          ...state.registry.indicator.activeIndicators,
        ],
        lastIndicatorId: id,
      },
    },
  }));

  return id;
}

export function removeIndicator(
  ctx: GlobalStateContext,
  id: IndicatorId,
): void {
  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      indicator: {
        ...state.registry.indicator,
        activeIndicators: state.registry.indicator.activeIndicators.filter(
          (m) => m.id !== id,
        ),
      },
    },
  }));
}

export function updateIndicator(
  ctx: GlobalStateContext,
  id: IndicatorId,
  content: ReactNode,
): void {
  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      indicator: {
        ...state.registry.indicator,
        activeIndicators: state.registry.indicator.activeIndicators.map((m) =>
          m.id === id ? { ...m, content } : m,
        ),
      },
    },
  }));
}

export function setSaveIndicator(
  ctx: GlobalStateContext,
  type: SaveIndicatorType,
): void {
  const previousType = ctx.readState(
    (s) => s.registry.indicator.saveIndicatorState,
  );

  // To reduce the console spam, we only warn if the type changes
  if (type !== previousType) {
    // eslint-disable-next-line no-console
    console.warn(
      'Save Indicator will be deprecated soon. Please update mosaic-ui to the latest version.',
    );
  }
  //--------------------------------------------

  if (
    type === SaveIndicatorType.Saving &&
    previousType !== SaveIndicatorType.Saving
  ) {
    // We went from a non-saving state to saving state => emit busy
    ctx.emit('busy', true);
  } else if (
    type !== SaveIndicatorType.Saving &&
    previousType === SaveIndicatorType.Saving
  ) {
    // We went from a saving state to a non-saving state => emit not busy
    ctx.emit('busy', false);
  }
  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      indicator: {
        ...state.registry.indicator,
        saveIndicatorState: type,
      },
    },
  }));
}
