import { FilterValidatorFunction } from '../Filters.model';

interface NumberRangeFilterValidatorOptions {
  fromIndex: number;
  toIndex: number;
}

/**
 * Generates two functions for validating Number Ranges. Inputs for this function expects the number filters to be defined under a single key in FilterValues.
 *
 * @param options Sets the options for the number range filter validators. Default { fromNumberIndex: 0, toNumberIndex: 1 }.
 * @example
 * const [createFromValidator, createToValidator] = createNumberRangeValidators<T>();
 * const filters = FilterType<T>[] = {
 *  {
 *    label: 'Duration (From)',
 *    property: 'duration',
 *    type: FilterTypes.Numeric,
 *    onValidate: createFromValidator('duration'),
 *  },
 *  {
 *    label: 'Duration (To)',
 *    property: 'duration',
 *    type: FilterTypes.Numeric,
 *    onValidate: createToValidator('duration'),
 *  }
 * }
 */
export const createNumberRangeFilterValidators = <T>(
  options: NumberRangeFilterValidatorOptions = {
    fromIndex: 0,
    toIndex: 1,
  },
): [
  (key: keyof T) => FilterValidatorFunction<T>,
  (key: keyof T) => FilterValidatorFunction<T>,
] => {
  const createFromValidator =
    (key: keyof T): FilterValidatorFunction<T> =>
    (value, allValues) => {
      if (allValues && allValues[key]) {
        if (
          Number(value as string) >
          Number((allValues[key] as string[])[options.toIndex])
        ) {
          return "'From' value cannot be greater than 'To' value";
        }
      }
    };

  const createToValidator =
    (key: keyof T): FilterValidatorFunction<T> =>
    (value, allValues) => {
      if (allValues && allValues[key]) {
        if (
          Number(value as string) <
          Number((allValues[key] as string[])[options.fromIndex])
        ) {
          return "'To' value cannot be less than 'From' value";
        }
      }
    };

  return [createFromValidator, createToValidator];
};
