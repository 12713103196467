import clsx from 'clsx';
import { Form } from 'formik';
import React from 'react';
import { Data } from '../../../types';
import { MessageBar } from '../../MessageBar';
import { FormGrid } from '../FormGrid';
import { FormStationProps } from '../FormStation';
import { StationErrorStateType } from '../FormStation.models';
import classes from './FormStationContentWrapper.scss';

interface FormStationContentWrapperProps
  extends Pick<
    FormStationProps<Data>,
    'stationMessage' | 'edgeToEdgeContent' | 'infoPanel' | 'initialData'
  > {
  stationError?: StationErrorStateType;
  setStationError: React.Dispatch<
    React.SetStateAction<StationErrorStateType | undefined>
  >;
}

export const FormStationContentWrapper: React.FC<
  FormStationContentWrapperProps
> = ({
  stationMessage,
  edgeToEdgeContent,
  infoPanel,
  initialData,
  stationError,
  setStationError,
  children,
}) => (
  <>
    {stationError && (
      <div className={classes.errorMessage}>
        <MessageBar
          type="error"
          title={String(stationError.title)}
          onClose={() => setStationError(undefined)}
        >
          {stationError?.body}
        </MessageBar>
      </div>
    )}
    {initialData.loading ? (
      // TODO: Loading skeleton of the page
      <></>
    ) : initialData.error ||
      initialData.data === null ||
      initialData.entityNotFound ? (
      // Error on loading - we can't show the form
      <div className={classes.loadingError}></div>
    ) : (
      <div className={classes.children}>
        <div className={classes.main}>
          <div
            className={clsx(classes.formWrapper, {
              [classes.hasMessage]: stationMessage,
            })}
          >
            {stationMessage && (
              <MessageBar {...stationMessage}>{stationMessage.body}</MessageBar>
            )}
            <Form>
              <FormGrid edgeToEdgeContent={edgeToEdgeContent}>
                {children}
                {/* Adding a invisible text input here to prevent the browser from submitting on "Enter" when there is only a single text input field in the form
                  See: https://www.w3.org/MarkUp/html-spec/html-spec_8.html#SEC8.2 */}
                <input type="text" style={{ display: 'none' }} />
              </FormGrid>
            </Form>
          </div>
          {infoPanel}
        </div>
      </div>
    )}
  </>
);
