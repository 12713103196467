import React, { useEffect, useState } from 'react';
import MosaicBG from '../../resources/images/bg.svg';
import mosaicLogo from '../../resources/images/mosaic_logo.svg';
import { useIdentityService } from '../IdentityServiceProvider/IdentityServiceProvider';
import { BrandingOptions } from '../common/BrandingOptions';
import classes from './ForgotPassword.module.scss';

const DEFAULT_BG_IMAGE = MosaicBG;

export interface ForgotPasswordProps {
  setIsForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  brandingOptions?: BrandingOptions;
}
/**
 * Renders the ForgotPassword component
 */
export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  setIsForgotPassword,
  brandingOptions,
}) => {
  const { initiatePasswordReset, getWellKnownUrls } = useIdentityService();

  const [email, setEmail] = useState<string>('');
  const [axAuthManagementGraphQlEndpoint, setAxAuthManagementGraphQlEndpoint] =
    React.useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isPasswordResetInitiated, setIsPasswordResetInitiated] =
    useState<boolean>(false);

  const background = brandingOptions?.background ?? DEFAULT_BG_IMAGE;

  const backgroundStyles = {
    background: background?.includes('/') ? `url(${background})` : background,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    (async () => {
      const wellKnownUrls = await getWellKnownUrls();
      setAxAuthManagementGraphQlEndpoint(
        wellKnownUrls.axAuthManagementGraphQlEndpoint,
      );
    })();
  }, []);

  const callInitiatePasswordReset = async (): Promise<void> => {
    setErrorMessage(undefined);
    if (!isValidEmail(email)) {
      setIsEmailError(true);
      return;
    } else {
      setIsEmailError(false);
    }

    if (axAuthManagementGraphQlEndpoint !== undefined) {
      const initiatePasswordResetResponse = await initiatePasswordReset(
        email,
        axAuthManagementGraphQlEndpoint,
      );
      if (initiatePasswordResetResponse.isSuccess) {
        setIsPasswordResetInitiated(true);
      } else {
        setErrorMessage(
          initiatePasswordResetResponse.errorMessage ??
            'Unexpected Error occurred while attempting to initiate password reset. Please try again later.',
        );
      }
    } else {
      setErrorMessage('AxAuthManagementGraphQlEndpoint is not available');
    }
  };

  return (
    <div
      className={classes.container}
      style={backgroundStyles}
      data-test-id="forgot-password-page"
    >
      <div className={classes.whiteBox}>
        <div className={classes.logo}>
          <img
            alt="Customer logo"
            src={brandingOptions?.companyLogo ?? mosaicLogo}
          />
        </div>
        <div className={classes.content}>
          {!isPasswordResetInitiated && (
            <>
              <p className={classes.hint}>
                Please type your email address to change the password.
              </p>
              {errorMessage && (
                <div
                  className={classes.errorMessage}
                  data-test-id="forgot-password-error"
                >
                  {errorMessage}
                </div>
              )}

              <div
                className={
                  isEmailError ? classes.inputGroupError : classes.inputGroup
                }
              >
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  placeholder="email"
                  data-test-id="forgot-password-email"
                />
              </div>
              {isEmailError && (
                <div
                  className={classes.errorMessage}
                  data-test-id="forgot-password-email-error"
                >
                  Email must be a valid email.
                </div>
              )}
              <div
                className={classes.button}
                onClick={() => callInitiatePasswordReset()}
                data-test-id="forgot-password-reset-button"
              >
                Reset Password
              </div>
              <div className={classes.backToSignIn}>
                <a
                  onClick={() => setIsForgotPassword(false)}
                  data-test-id="forgot-password-back-to-sign-in-link"
                >
                  Back to Sign In
                </a>
              </div>
            </>
          )}
          {isPasswordResetInitiated && (
            <>
              <p
                className={classes.hint}
                data-test-id="forgot-password-success-message"
              >
                We have sent a password reset link to your email. Please click
                that link to proceed with password reset.
              </p>

              <div
                className={classes.button}
                onClick={() => setIsForgotPassword(false)}
                data-test-id="forgot-password-back-to-sign-in-button"
              >
                Back to Sign In
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
