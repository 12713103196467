import { DateTime, Settings } from 'luxon';
import { TransformerFunction } from './TransformerFunction';

Settings.defaultLocale = navigator.language;

/**
 * Converts an existing DateTime value to a Human Readable format.
 * @param dateString DateTime value to be converted
 *
 * @returns Localized time string. If the DateTime value cannot be converted, the original string is returned.
 */
export const formatDateTime: TransformerFunction<string, string> = (
  dateString,
): string => {
  const parsedDate = DateTime.fromISO(String(dateString));

  return parsedDate.isValid
    ? DateTime.fromISO(String(dateString)).toLocaleString(
        DateTime.DATETIME_SHORT,
      )
    : dateString;
};

/**
 * Converts an existing Date value to a Human Readable format.
 * @param dateString Date value to be converted
 *
 * @returns Localized time string. If the Date value cannot be converted, the original string is returned.
 */
export const formatDate: TransformerFunction<string, string> = (
  dateString,
): string => {
  const parsedDate = DateTime.fromISO(String(dateString));

  return parsedDate.isValid
    ? DateTime.fromISO(String(dateString)).toLocaleString(DateTime.DATE_SHORT)
    : dateString;
};
