import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export interface NavigationPanelMeta {
  collapsed: boolean;
  expand?: () => void;
  collapse?: () => void;
  showStaticPanel: boolean;
  showOverlayPanel: boolean;
}

export function useNavigationPanel(): NavigationPanelMeta {
  const history = useHistory();

  const showStaticNav = isRootNavPath(useLocation().pathname);
  const [showOverlayPanel, setShowOverlayPanel] = useState(false);
  const [collapsed, setCollapsed] = useState(!showStaticNav);
  const [showStaticPanel, setShowStaticPanel] = useState(showStaticNav);

  useEffect(() => {
    return history.listen((location) => {
      const showStaticNav = isRootNavPath(location.pathname);
      setCollapsed(!showStaticNav);
      setShowStaticPanel(showStaticNav);
      setShowOverlayPanel(false);
    });
  }, [history]);

  const toggleOverlayPanel = (showPanel: boolean): void => {
    setCollapsed(!showPanel);
    setShowOverlayPanel(showPanel);
  };

  const expand = (): void => {
    toggleOverlayPanel(true);
  };

  const collapse = (): void => {
    toggleOverlayPanel(false);
  };

  return {
    collapse,
    expand,
    collapsed,
    showStaticPanel,
    showOverlayPanel,
  };
}

const isRootNavPath = (path: string): boolean => path === '/';
