import clsx from 'clsx';
import React from 'react';
import { IconName, Icons } from '../../Icons';
import classes from './Menu.module.scss';

export interface MenuProps {
  clickHandler?: () => void;
  visible?: boolean;
}

/**
 * Displays hamburger menu button
 */
export const Menu: React.FC<MenuProps> = ({
  visible = false,
  clickHandler,
}) => {
  return (
    <div
      className={clsx(classes.menuContainer, { [classes.hidden]: !visible })}
    >
      <button
        onClick={clickHandler}
        className={classes.menu}
        data-test-id="hamburger-menu-button"
        disabled={!visible}
      >
        <Icons icon={IconName.ExpandMenu} />
      </button>
    </div>
  );
};
