import React from 'react';
import { useFormikError } from '../useFormikError';
import { TextArea, TextAreaProps } from './TextArea';
/**
 * This component should be used to render a TextArea inside a Formik form.
 *
 * @example
 * <Field name="title" label="Title" as={TextAreaField} />
 */
export const TextAreaField: React.FC<Omit<TextAreaProps, 'error'>> = (
  props,
) => {
  const error = useFormikError(props.name);
  return <TextArea {...props} error={error} />;
};
