import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  Datetime: any;
  JSON: any;
  UUID: any;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CheckEndUserPasswordResetOtpInput = {
  resetOtp: Scalars['String'];
};

export type CheckEndUserPasswordResetOtpPayload = {
  __typename?: 'CheckEndUserPasswordResetOtpPayload';
  isOtpValid: Scalars['Boolean'];
};

export type CheckEndUserSignUpOtpInput = {
  signUpOtp: Scalars['String'];
};

export type CheckEndUserSignUpOtpPayload = {
  __typename?: 'CheckEndUserSignUpOtpPayload';
  isOtpValid: Scalars['Boolean'];
};

export type ClearResetPasswordWebhookSecretInput = {
  userStoreId: Scalars['String'];
};

export type ClearResetPasswordWebhookSecretPayload = {
  __typename?: 'ClearResetPasswordWebhookSecretPayload';
  deletedSecret: Scalars['String'];
};

export type ClearUserSignUpWebhookSecretInput = {
  userStoreId: Scalars['String'];
};

export type ClearUserSignUpWebhookSecretPayload = {
  __typename?: 'ClearUserSignUpWebhookSecretPayload';
  deletedSecret: Scalars['String'];
};

export type CompleteEndUserPasswordResetInput = {
  newPassword: Scalars['String'];
  resetOtp: Scalars['String'];
};

export type CompleteEndUserPasswordResetPayload = {
  __typename?: 'CompleteEndUserPasswordResetPayload';
  idpUserId: Scalars['UUID'];
};

export type CompleteEndUserSignUpInput = {
  /**
   * The password for the user must be provided either at this point or
   * in InitiateEndUserSignUp mutation.
   * If the end-user app will enforce the user to provide it in the
   * InitiateEndUserSignUp mutation the password must not be provided here.
   * If not, giving a password at this point is mandatory.
   * If it's given at InitiateEndUserSignUp step, then the used cannot override it here.
   */
  password?: InputMaybe<Scalars['String']>;
  signUpOtp: Scalars['String'];
};

export type CompleteEndUserSignUpPayload = {
  __typename?: 'CompleteEndUserSignUpPayload';
  idpUserId: Scalars['UUID'];
};

export type CompleteManagementUserPasswordResetInput = {
  newPassword: Scalars['String'];
  resetOtp: Scalars['String'];
};

export type CompleteManagementUserPasswordResetPayload = {
  __typename?: 'CompleteManagementUserPasswordResetPayload';
  idpUserId: Scalars['UUID'];
};

export type CompleteManagementUserSignUpInput = {
  signUpOtp: Scalars['String'];
};

export type CompleteManagementUserSignUpPayload = {
  __typename?: 'CompleteManagementUserSignUpPayload';
  idpUserId: Scalars['UUID'];
};

/** All input for the create `IdpUser` mutation. */
export type CreateIdpUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `IdpUser` to be created by this mutation. */
  idpUser: IdpUserInput;
};

/** The output of our create `IdpUser` mutation. */
export type CreateIdpUserPayload = {
  __typename?: 'CreateIdpUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdpUser` that was created by this mutation. */
  idpUser?: Maybe<IdpUser>;
  /** An edge for our `IdpUser`. May be used by Relay 1. */
  idpUserEdge?: Maybe<IdpUsersEdge>;
  /** Reads a single `IdpUserStore` that is related to this `IdpUser`. */
  idpUserStore?: Maybe<IdpUserStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `IdpUser` mutation. */
export type CreateIdpUserPayloadIdpUserEdgeArgs = {
  orderBy?: InputMaybe<Array<IdpUsersOrderBy>>;
};

/** All input for the create `IdpUserStore` mutation. */
export type CreateIdpUserStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `IdpUserStore` to be created by this mutation. */
  idpUserStore: IdpUserStoreInput;
};

/** The output of our create `IdpUserStore` mutation. */
export type CreateIdpUserStorePayload = {
  __typename?: 'CreateIdpUserStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdpUserStore` that was created by this mutation. */
  idpUserStore?: Maybe<IdpUserStore>;
  /** An edge for our `IdpUserStore`. May be used by Relay 1. */
  idpUserStoreEdge?: Maybe<IdpUserStoresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `IdpUserStore` mutation. */
export type CreateIdpUserStorePayloadIdpUserStoreEdgeArgs = {
  orderBy?: InputMaybe<Array<IdpUserStoresOrderBy>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

export type DeleteIdpUserByEmailInput = {
  email: Scalars['String'];
  userStoreId: Scalars['String'];
};

export type DeleteIdpUserByEmailPayload = {
  __typename?: 'DeleteIdpUserByEmailPayload';
  idpUser?: Maybe<IdpUser>;
};

/** All input for the `deleteIdpUser` mutation. */
export type DeleteIdpUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `IdpUser` mutation. */
export type DeleteIdpUserPayload = {
  __typename?: 'DeleteIdpUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdpUser` that was deleted by this mutation. */
  idpUser?: Maybe<IdpUser>;
  /** An edge for our `IdpUser`. May be used by Relay 1. */
  idpUserEdge?: Maybe<IdpUsersEdge>;
  /** Reads a single `IdpUserStore` that is related to this `IdpUser`. */
  idpUserStore?: Maybe<IdpUserStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `IdpUser` mutation. */
export type DeleteIdpUserPayloadIdpUserEdgeArgs = {
  orderBy?: InputMaybe<Array<IdpUsersOrderBy>>;
};

/** All input for the `deleteIdpUserStore` mutation. */
export type DeleteIdpUserStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `IdpUserStore` mutation. */
export type DeleteIdpUserStorePayload = {
  __typename?: 'DeleteIdpUserStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdpUserStore` that was deleted by this mutation. */
  idpUserStore?: Maybe<IdpUserStore>;
  /** An edge for our `IdpUserStore`. May be used by Relay 1. */
  idpUserStoreEdge?: Maybe<IdpUserStoresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `IdpUserStore` mutation. */
export type DeleteIdpUserStorePayloadIdpUserStoreEdgeArgs = {
  orderBy?: InputMaybe<Array<IdpUserStoresOrderBy>>;
};

export type DeleteOAuthClientInput = {
  clientId: Scalars['String'];
  userStoreId: Scalars['UUID'];
};

export type DeleteOAuthClientPayload = {
  __typename?: 'DeleteOAuthClientPayload';
  success: Scalars['Boolean'];
};

export type EnvironmentClientMapping = {
  __typename?: 'EnvironmentClientMapping';
  clientId: Scalars['String'];
  environmentId: Scalars['UUID'];
  /** Reads a single `IdpUserStore` that is related to this `EnvironmentClientMapping`. */
  idpUserStore?: Maybe<IdpUserStore>;
  idpUserStoreId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};

/**
 * A condition to be used against `EnvironmentClientMapping` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type EnvironmentClientMappingCondition = {
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `idpUserStoreId` field. */
  idpUserStoreId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `EnvironmentClientMapping` object types. All fields are combined with a logical ‘and.’ */
export type EnvironmentClientMappingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EnvironmentClientMappingFilter>>;
  /** Filter by the object’s `clientId` field. */
  clientId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `idpUserStore` relation. */
  idpUserStore?: InputMaybe<IdpUserStoreFilter>;
  /** Filter by the object’s `idpUserStoreId` field. */
  idpUserStoreId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EnvironmentClientMappingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EnvironmentClientMappingFilter>>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `EnvironmentClientMapping` values. */
export type EnvironmentClientMappingsConnection = {
  __typename?: 'EnvironmentClientMappingsConnection';
  /** A list of edges which contains the `EnvironmentClientMapping` and cursor to aid in pagination. */
  edges: Array<EnvironmentClientMappingsEdge>;
  /** A list of `EnvironmentClientMapping` objects. */
  nodes: Array<EnvironmentClientMapping>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EnvironmentClientMapping` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EnvironmentClientMapping` edge in the connection. */
export type EnvironmentClientMappingsEdge = {
  __typename?: 'EnvironmentClientMappingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EnvironmentClientMapping` at the end of the edge. */
  node: EnvironmentClientMapping;
};

/** Methods to use when ordering `EnvironmentClientMapping`. */
export enum EnvironmentClientMappingsOrderBy {
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  IdpUserStoreIdAsc = 'IDP_USER_STORE_ID_ASC',
  IdpUserStoreIdDesc = 'IDP_USER_STORE_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC'
}

export type GenerateOAuthClientSecretInput = {
  clientId: Scalars['String'];
};

export type GenerateOAuthClientSecretPayload = {
  __typename?: 'GenerateOAuthClientSecretPayload';
  newClientSecret: Scalars['String'];
};

export type GenerateResetPasswordWebhookSecretInput = {
  userStoreId: Scalars['String'];
};

export type GenerateResetPasswordWebhookSecretPayload = {
  __typename?: 'GenerateResetPasswordWebhookSecretPayload';
  secret: Scalars['String'];
};

export type GenerateUserSignUpWebhookSecretInput = {
  userStoreId: Scalars['String'];
};

export type GenerateUserSignUpWebhookSecretPayload = {
  __typename?: 'GenerateUserSignUpWebhookSecretPayload';
  secret: Scalars['String'];
};

export type IdpUser = {
  __typename?: 'IdpUser';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['String'];
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  environmentId: Scalars['UUID'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** Reads a single `IdpUserStore` that is related to this `IdpUser`. */
  idpUserStore?: Maybe<IdpUserStore>;
  idpUserStoreId: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
  passwordHash: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  status: UserStatus;
  tenantId: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
  updatedBy: Scalars['String'];
};

/** A condition to be used against `IdpUser` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type IdpUserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `emailVerified` field. */
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `idpUserStoreId` field. */
  idpUserStoreId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `passwordHash` field. */
  passwordHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `profilePictureUrl` field. */
  profilePictureUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<UserStatus>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `IdpUser` object types. All fields are combined with a logical ‘and.’ */
export type IdpUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<IdpUserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailVerified` field. */
  emailVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `idpUserStore` relation. */
  idpUserStore?: InputMaybe<IdpUserStoreFilter>;
  /** Filter by the object’s `idpUserStoreId` field. */
  idpUserStoreId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<IdpUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<IdpUserFilter>>;
  /** Filter by the object’s `passwordHash` field. */
  passwordHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `profilePictureUrl` field. */
  profilePictureUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<UserStatusFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `IdpUser` */
export type IdpUserInput = {
  email: Scalars['String'];
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  idpUserStoreId: Scalars['UUID'];
  lastName?: InputMaybe<Scalars['String']>;
  passwordHash: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePictureUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
};

/** Represents an update to a `IdpUser`. Fields that are set will be updated. */
export type IdpUserPatch = {
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  idpUserStoreId?: InputMaybe<Scalars['UUID']>;
  lastName?: InputMaybe<Scalars['String']>;
  profilePictureUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
};

/** A connection to a list of `IdpUser` values. */
export type IdpUsersConnection = {
  __typename?: 'IdpUsersConnection';
  /** A list of edges which contains the `IdpUser` and cursor to aid in pagination. */
  edges: Array<IdpUsersEdge>;
  /** A list of `IdpUser` objects. */
  nodes: Array<IdpUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdpUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `IdpUser` edge in the connection. */
export type IdpUsersEdge = {
  __typename?: 'IdpUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdpUser` at the end of the edge. */
  node: IdpUser;
};

/** Methods to use when ordering `IdpUser`. */
export enum IdpUsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmailVerifiedAsc = 'EMAIL_VERIFIED_ASC',
  EmailVerifiedDesc = 'EMAIL_VERIFIED_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdpUserStoreIdAsc = 'IDP_USER_STORE_ID_ASC',
  IdpUserStoreIdDesc = 'IDP_USER_STORE_ID_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  Natural = 'NATURAL',
  PasswordHashAsc = 'PASSWORD_HASH_ASC',
  PasswordHashDesc = 'PASSWORD_HASH_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfilePictureUrlAsc = 'PROFILE_PICTURE_URL_ASC',
  ProfilePictureUrlDesc = 'PROFILE_PICTURE_URL_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type IdpUserStore = {
  __typename?: 'IdpUserStore';
  category: UserStoreCategory;
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['String'];
  enforceStrictPasswordPolicy: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `EnvironmentClientMapping`. */
  environmentClientMappings: EnvironmentClientMappingsConnection;
  environmentId: Scalars['UUID'];
  forgotPasswordWebhook?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** Reads and enables pagination through a set of `IdpUser`. */
  idpUsers: IdpUsersConnection;
  name: Scalars['String'];
  resetPasswordWebhookSecretIsSet: Scalars['Boolean'];
  tenantId: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
  updatedBy: Scalars['String'];
  userSignUpWebhook?: Maybe<Scalars['String']>;
  userSignUpWebhookSecretIsSet: Scalars['Boolean'];
};


export type IdpUserStoreEnvironmentClientMappingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EnvironmentClientMappingCondition>;
  filter?: InputMaybe<EnvironmentClientMappingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnvironmentClientMappingsOrderBy>>;
};


export type IdpUserStoreIdpUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IdpUserCondition>;
  filter?: InputMaybe<IdpUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdpUsersOrderBy>>;
};

/**
 * A condition to be used against `IdpUserStore` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IdpUserStoreCondition = {
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<UserStoreCategory>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `enforceStrictPasswordPolicy` field. */
  enforceStrictPasswordPolicy?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `forgotPasswordWebhook` field. */
  forgotPasswordWebhook?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `resetPasswordWebhookSecretIsSet` field. */
  resetPasswordWebhookSecretIsSet?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userSignUpWebhook` field. */
  userSignUpWebhook?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userSignUpWebhookSecretIsSet` field. */
  userSignUpWebhookSecretIsSet?: InputMaybe<Scalars['Boolean']>;
};

/** A filter to be used against `IdpUserStore` object types. All fields are combined with a logical ‘and.’ */
export type IdpUserStoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<IdpUserStoreFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<UserStoreCategoryFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enforceStrictPasswordPolicy` field. */
  enforceStrictPasswordPolicy?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `environmentClientMappings` relation. */
  environmentClientMappings?: InputMaybe<IdpUserStoreToManyEnvironmentClientMappingFilter>;
  /** Some related `environmentClientMappings` exist. */
  environmentClientMappingsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `forgotPasswordWebhook` field. */
  forgotPasswordWebhook?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `idpUsers` relation. */
  idpUsers?: InputMaybe<IdpUserStoreToManyIdpUserFilter>;
  /** Some related `idpUsers` exist. */
  idpUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<IdpUserStoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<IdpUserStoreFilter>>;
  /** Filter by the object’s `resetPasswordWebhookSecretIsSet` field. */
  resetPasswordWebhookSecretIsSet?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userSignUpWebhook` field. */
  userSignUpWebhook?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userSignUpWebhookSecretIsSet` field. */
  userSignUpWebhookSecretIsSet?: InputMaybe<BooleanFilter>;
};

/** An input for mutations affecting `IdpUserStore` */
export type IdpUserStoreInput = {
  category?: InputMaybe<UserStoreCategory>;
  enforceStrictPasswordPolicy?: InputMaybe<Scalars['Boolean']>;
  forgotPasswordWebhook?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  resetPasswordWebhookSecretIsSet?: InputMaybe<Scalars['Boolean']>;
  userSignUpWebhook?: InputMaybe<Scalars['String']>;
  userSignUpWebhookSecretIsSet?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `IdpUserStore`. Fields that are set will be updated. */
export type IdpUserStorePatch = {
  category?: InputMaybe<UserStoreCategory>;
  enforceStrictPasswordPolicy?: InputMaybe<Scalars['Boolean']>;
  forgotPasswordWebhook?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resetPasswordWebhookSecretIsSet?: InputMaybe<Scalars['Boolean']>;
  userSignUpWebhook?: InputMaybe<Scalars['String']>;
  userSignUpWebhookSecretIsSet?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `IdpUserStore` values. */
export type IdpUserStoresConnection = {
  __typename?: 'IdpUserStoresConnection';
  /** A list of edges which contains the `IdpUserStore` and cursor to aid in pagination. */
  edges: Array<IdpUserStoresEdge>;
  /** A list of `IdpUserStore` objects. */
  nodes: Array<IdpUserStore>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdpUserStore` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `IdpUserStore` edge in the connection. */
export type IdpUserStoresEdge = {
  __typename?: 'IdpUserStoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdpUserStore` at the end of the edge. */
  node: IdpUserStore;
};

/** Methods to use when ordering `IdpUserStore`. */
export enum IdpUserStoresOrderBy {
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EnforceStrictPasswordPolicyAsc = 'ENFORCE_STRICT_PASSWORD_POLICY_ASC',
  EnforceStrictPasswordPolicyDesc = 'ENFORCE_STRICT_PASSWORD_POLICY_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ForgotPasswordWebhookAsc = 'FORGOT_PASSWORD_WEBHOOK_ASC',
  ForgotPasswordWebhookDesc = 'FORGOT_PASSWORD_WEBHOOK_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ResetPasswordWebhookSecretIsSetAsc = 'RESET_PASSWORD_WEBHOOK_SECRET_IS_SET_ASC',
  ResetPasswordWebhookSecretIsSetDesc = 'RESET_PASSWORD_WEBHOOK_SECRET_IS_SET_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  UserSignUpWebhookAsc = 'USER_SIGN_UP_WEBHOOK_ASC',
  UserSignUpWebhookDesc = 'USER_SIGN_UP_WEBHOOK_DESC',
  UserSignUpWebhookSecretIsSetAsc = 'USER_SIGN_UP_WEBHOOK_SECRET_IS_SET_ASC',
  UserSignUpWebhookSecretIsSetDesc = 'USER_SIGN_UP_WEBHOOK_SECRET_IS_SET_DESC'
}

/** A filter to be used against many `EnvironmentClientMapping` object types. All fields are combined with a logical ‘and.’ */
export type IdpUserStoreToManyEnvironmentClientMappingFilter = {
  /** Every related `EnvironmentClientMapping` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EnvironmentClientMappingFilter>;
  /** No related `EnvironmentClientMapping` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EnvironmentClientMappingFilter>;
  /** Some related `EnvironmentClientMapping` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EnvironmentClientMappingFilter>;
};

/** A filter to be used against many `IdpUser` object types. All fields are combined with a logical ‘and.’ */
export type IdpUserStoreToManyIdpUserFilter = {
  /** Every related `IdpUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<IdpUserFilter>;
  /** No related `IdpUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<IdpUserFilter>;
  /** Some related `IdpUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<IdpUserFilter>;
};

export type InitiateEndUserPasswordResetInput = {
  customData?: InputMaybe<Scalars['JSON']>;
  email: Scalars['String'];
  oAuthClientId: Scalars['String'];
};

export type InitiateEndUserPasswordResetPayload = {
  __typename?: 'InitiateEndUserPasswordResetPayload';
  idpUserId: Scalars['UUID'];
};

export type InitiateEndUserSignUpInput = {
  customData?: InputMaybe<Scalars['JSON']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  oAuthClientId: Scalars['String'];
  /**
   * The password for the user must be provided either at this point or
   * in CompleteEndUserSignUp mutation.
   * If the end-user app will enforce the user to provide it in the
   * CompleteEndUserSignUp mutation the password does not have to be provided here.
   * If not, giving a password at this point is mandatory.
   */
  password?: InputMaybe<Scalars['String']>;
};

export type InitiateEndUserSignUpPayload = {
  __typename?: 'InitiateEndUserSignUpPayload';
  idpUserId: Scalars['UUID'];
};

export type InitiateManagementUserPasswordResetInput = {
  email: Scalars['String'];
  oAuthClientId: Scalars['String'];
};

export type InitiateManagementUserPasswordResetPayload = {
  __typename?: 'InitiateManagementUserPasswordResetPayload';
  idpUserId: Scalars['UUID'];
};

export type InitiateManagementUserSignUpInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  oAuthClientId: Scalars['String'];
  password: Scalars['String'];
};

export type InitiateManagementUserSignUpPayload = {
  __typename?: 'InitiateManagementUserSignUpPayload';
  idpUserId: Scalars['UUID'];
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Check End-User Password Reset Otp */
  checkEndUserPasswordResetOtp: CheckEndUserPasswordResetOtpPayload;
  /** Check End-User Sign Up Otp */
  checkEndUserSignUpOtp: CheckEndUserSignUpOtpPayload;
  clearResetPasswordWebhookSecret: ClearResetPasswordWebhookSecretPayload;
  clearUserSignUpWebhookSecret: ClearUserSignUpWebhookSecretPayload;
  /** Complete End-User Reset Password Flow */
  completeEndUserPasswordReset: CompleteEndUserPasswordResetPayload;
  /** Start Sign Up as an End-User */
  completeEndUserSignUp: CompleteEndUserSignUpPayload;
  /** Complete Management-User Reset Password Flow */
  completeManagementUserPasswordReset: CompleteManagementUserPasswordResetPayload;
  /** Complete Management-User Sign Up Flow */
  completeManagementUserSignUp: CompleteManagementUserSignUpPayload;
  /** Creates a single `IdpUser`. */
  createIdpUser?: Maybe<CreateIdpUserPayload>;
  /** Creates a single `IdpUserStore`. */
  createIdpUserStore?: Maybe<CreateIdpUserStorePayload>;
  /** Deletes a single `IdpUser` using a unique key. */
  deleteIdpUser?: Maybe<DeleteIdpUserPayload>;
  /** Delete User By Email */
  deleteIdpUserByEmail: DeleteIdpUserByEmailPayload;
  /** Deletes a single `IdpUserStore` using a unique key. */
  deleteIdpUserStore?: Maybe<DeleteIdpUserStorePayload>;
  /** Delete  OAuth Client */
  deleteOAuthClient: DeleteOAuthClientPayload;
  /** Generate the client secret of an OAuth client */
  generateOAuthClientSecret: GenerateOAuthClientSecretPayload;
  generateResetPasswordWebhookSecret: GenerateResetPasswordWebhookSecretPayload;
  generateUserSignUpWebhookSecret: GenerateUserSignUpWebhookSecretPayload;
  /** Start End-User Reset Password Flow */
  initiateEndUserPasswordReset: InitiateEndUserPasswordResetPayload;
  /** Initiate Sign Up as an End-User */
  initiateEndUserSignUp: InitiateEndUserSignUpPayload;
  /** Start Management User Reset Password Flow */
  initiateManagementUserPasswordReset: InitiateManagementUserPasswordResetPayload;
  /** Initiate Sign Up as an Management User */
  initiateManagementUserSignUp: InitiateManagementUserSignUpPayload;
  /** Register OAuth Client */
  registerOAuthClient: RegisterOAuthClientPayload;
  /** Reset the password of an IDP user */
  resetIdpUserPassword: ResetIdpUserPasswordPayload;
  /** Updates a single `IdpUser` using a unique key and a patch. */
  updateIdpUser?: Maybe<UpdateIdpUserPayload>;
  /** Updates a single `IdpUserStore` using a unique key and a patch. */
  updateIdpUserStore?: Maybe<UpdateIdpUserStorePayload>;
  /** Update OAuth Client */
  updateOAuthClient: OAuthClientPayload;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckEndUserPasswordResetOtpArgs = {
  input?: InputMaybe<CheckEndUserPasswordResetOtpInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckEndUserSignUpOtpArgs = {
  input?: InputMaybe<CheckEndUserSignUpOtpInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearResetPasswordWebhookSecretArgs = {
  input: ClearResetPasswordWebhookSecretInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearUserSignUpWebhookSecretArgs = {
  input: ClearUserSignUpWebhookSecretInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompleteEndUserPasswordResetArgs = {
  input?: InputMaybe<CompleteEndUserPasswordResetInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompleteEndUserSignUpArgs = {
  input?: InputMaybe<CompleteEndUserSignUpInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompleteManagementUserPasswordResetArgs = {
  input?: InputMaybe<CompleteManagementUserPasswordResetInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompleteManagementUserSignUpArgs = {
  input?: InputMaybe<CompleteManagementUserSignUpInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdpUserArgs = {
  input: CreateIdpUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdpUserStoreArgs = {
  input: CreateIdpUserStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdpUserArgs = {
  input: DeleteIdpUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdpUserByEmailArgs = {
  input?: InputMaybe<DeleteIdpUserByEmailInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdpUserStoreArgs = {
  input: DeleteIdpUserStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOAuthClientArgs = {
  input: DeleteOAuthClientInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateOAuthClientSecretArgs = {
  input?: InputMaybe<GenerateOAuthClientSecretInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateResetPasswordWebhookSecretArgs = {
  input: GenerateResetPasswordWebhookSecretInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateUserSignUpWebhookSecretArgs = {
  input: GenerateUserSignUpWebhookSecretInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInitiateEndUserPasswordResetArgs = {
  input?: InputMaybe<InitiateEndUserPasswordResetInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInitiateEndUserSignUpArgs = {
  input?: InputMaybe<InitiateEndUserSignUpInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInitiateManagementUserPasswordResetArgs = {
  input?: InputMaybe<InitiateManagementUserPasswordResetInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInitiateManagementUserSignUpArgs = {
  input?: InputMaybe<InitiateManagementUserSignUpInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterOAuthClientArgs = {
  input: RegisterOAuthClientInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetIdpUserPasswordArgs = {
  input?: InputMaybe<ResetIdpUserPasswordInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdpUserArgs = {
  input: UpdateIdpUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdpUserStoreArgs = {
  input: UpdateIdpUserStoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOAuthClientArgs = {
  input: UpdateOAuthClientInput;
};

export type OAuthClient = {
  __typename?: 'OAuthClient';
  allowUserSignUp?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['String']>;
  idpUserStoreId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `OAuthClient` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OAuthClientCondition = {
  /** Checks for equality with the object’s `allowUserSignUp` field. */
  allowUserSignUp?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `idpUserStoreId` field. */
  idpUserStoreId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `OAuthClient` object types. All fields are combined with a logical ‘and.’ */
export type OAuthClientFilter = {
  /** Filter by the object’s `allowUserSignUp` field. */
  allowUserSignUp?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OAuthClientFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `idpUserStoreId` field. */
  idpUserStoreId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OAuthClientFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OAuthClientFilter>>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<StringFilter>;
};

export type OAuthClientPayload = {
  __typename?: 'OAuthClientPayload';
  allowUserSignUp: Scalars['Boolean'];
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientSecret: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['String']>;
  redirectUris?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['String']>;
  userStoreName: Scalars['String'];
};

/** A connection to a list of `OAuthClient` values. */
export type OAuthClientsConnection = {
  __typename?: 'OAuthClientsConnection';
  /** A list of edges which contains the `OAuthClient` and cursor to aid in pagination. */
  edges: Array<OAuthClientsEdge>;
  /** A list of `OAuthClient` objects. */
  nodes: Array<OAuthClient>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OAuthClient` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OAuthClient` edge in the connection. */
export type OAuthClientsEdge = {
  __typename?: 'OAuthClientsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OAuthClient` at the end of the edge. */
  node: OAuthClient;
};

/** Methods to use when ordering `OAuthClient`. */
export enum OAuthClientsOrderBy {
  AllowUserSignUpAsc = 'ALLOW_USER_SIGN_UP_ASC',
  AllowUserSignUpDesc = 'ALLOW_USER_SIGN_UP_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdpUserStoreIdAsc = 'IDP_USER_STORE_ID_ASC',
  IdpUserStoreIdDesc = 'IDP_USER_STORE_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

/** The root query type which gives access points into the data universe. */
export type Query = {
  __typename?: 'Query';
  environmentClientMapping?: Maybe<EnvironmentClientMapping>;
  environmentClientMappingByClientId?: Maybe<EnvironmentClientMapping>;
  /** Reads and enables pagination through a set of `EnvironmentClientMapping`. */
  environmentClientMappings?: Maybe<EnvironmentClientMappingsConnection>;
  idpUser?: Maybe<IdpUser>;
  /** Reads and enables pagination through a set of `IdpUser`. */
  idpUsers?: Maybe<IdpUsersConnection>;
  idpUserStore?: Maybe<IdpUserStore>;
  idpUserStoreByName?: Maybe<IdpUserStore>;
  /** Reads and enables pagination through a set of `IdpUserStore`. */
  idpUserStores?: Maybe<IdpUserStoresConnection>;
  /** Get OAuth Client */
  oAuthClient: OAuthClientPayload;
  /** Reads and enables pagination through a set of `OAuthClient`. */
  oAuthClients?: Maybe<OAuthClientsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
};


/** The root query type which gives access points into the data universe. */
export type QueryEnvironmentClientMappingArgs = {
  clientId: Scalars['String'];
  environmentId: Scalars['UUID'];
  idpUserStoreId: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEnvironmentClientMappingByClientIdArgs = {
  clientId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEnvironmentClientMappingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EnvironmentClientMappingCondition>;
  filter?: InputMaybe<EnvironmentClientMappingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnvironmentClientMappingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdpUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdpUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IdpUserCondition>;
  filter?: InputMaybe<IdpUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdpUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdpUserStoreArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdpUserStoreByNameArgs = {
  environmentId: Scalars['UUID'];
  name: Scalars['String'];
  tenantId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdpUserStoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IdpUserStoreCondition>;
  filter?: InputMaybe<IdpUserStoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdpUserStoresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOAuthClientArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOAuthClientsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OAuthClientCondition>;
  filter?: InputMaybe<OAuthClientFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OAuthClientsOrderBy>>;
};

export type RegisterOAuthClientInput = {
  clientName: Scalars['String'];
  userStoreId: Scalars['UUID'];
};

export type RegisterOAuthClientPayload = {
  __typename?: 'RegisterOAuthClientPayload';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type ResetIdpUserPasswordInput = {
  id: Scalars['UUID'];
};

export type ResetIdpUserPasswordPayload = {
  __typename?: 'ResetIdpUserPasswordPayload';
  idpUser?: Maybe<IdpUser>;
  newPassword: Scalars['String'];
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** All input for the `updateIdpUser` mutation. */
export type UpdateIdpUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `IdpUser` being updated. */
  patch: IdpUserPatch;
};

/** The output of our update `IdpUser` mutation. */
export type UpdateIdpUserPayload = {
  __typename?: 'UpdateIdpUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdpUser` that was updated by this mutation. */
  idpUser?: Maybe<IdpUser>;
  /** An edge for our `IdpUser`. May be used by Relay 1. */
  idpUserEdge?: Maybe<IdpUsersEdge>;
  /** Reads a single `IdpUserStore` that is related to this `IdpUser`. */
  idpUserStore?: Maybe<IdpUserStore>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `IdpUser` mutation. */
export type UpdateIdpUserPayloadIdpUserEdgeArgs = {
  orderBy?: InputMaybe<Array<IdpUsersOrderBy>>;
};

/** All input for the `updateIdpUserStore` mutation. */
export type UpdateIdpUserStoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `IdpUserStore` being updated. */
  patch: IdpUserStorePatch;
};

/** The output of our update `IdpUserStore` mutation. */
export type UpdateIdpUserStorePayload = {
  __typename?: 'UpdateIdpUserStorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdpUserStore` that was updated by this mutation. */
  idpUserStore?: Maybe<IdpUserStore>;
  /** An edge for our `IdpUserStore`. May be used by Relay 1. */
  idpUserStoreEdge?: Maybe<IdpUserStoresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `IdpUserStore` mutation. */
export type UpdateIdpUserStorePayloadIdpUserStoreEdgeArgs = {
  orderBy?: InputMaybe<Array<IdpUserStoresOrderBy>>;
};

export type UpdateOAuthClientInput = {
  allowUserSignUp?: InputMaybe<Scalars['Boolean']>;
  clientId: Scalars['String'];
  clientName?: InputMaybe<Scalars['String']>;
  redirectUris?: InputMaybe<Array<Scalars['String']>>;
};

export enum UserStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Blocked */
  Blocked = 'BLOCKED'
}

/** A filter to be used against UserStatus fields. All fields are combined with a logical ‘and.’ */
export type UserStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UserStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UserStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UserStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UserStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UserStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UserStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UserStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UserStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UserStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UserStatus>>;
};

export enum UserStoreCategory {
  /** End User */
  EndUser = 'END_USER',
  /** Management */
  Management = 'MANAGEMENT'
}

/** A filter to be used against UserStoreCategory fields. All fields are combined with a logical ‘and.’ */
export type UserStoreCategoryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UserStoreCategory>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UserStoreCategory>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UserStoreCategory>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UserStoreCategory>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UserStoreCategory>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UserStoreCategory>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UserStoreCategory>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UserStoreCategory>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UserStoreCategory>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UserStoreCategory>>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

export type InitiateManagementUserSignUpMutationVariables = Exact<{
  input: InitiateManagementUserSignUpInput;
}>;


export type InitiateManagementUserSignUpMutation = { __typename?: 'Mutation', initiateManagementUserSignUp: { __typename?: 'InitiateManagementUserSignUpPayload', idpUserId: any } };

export type InitiateManagementUserPasswordResetMutationVariables = Exact<{
  input: InitiateManagementUserPasswordResetInput;
}>;


export type InitiateManagementUserPasswordResetMutation = { __typename?: 'Mutation', initiateManagementUserPasswordReset: { __typename?: 'InitiateManagementUserPasswordResetPayload', idpUserId: any } };

export type CompleteManagementUserPasswordResetMutationVariables = Exact<{
  input: CompleteManagementUserPasswordResetInput;
}>;


export type CompleteManagementUserPasswordResetMutation = { __typename?: 'Mutation', completeManagementUserPasswordReset: { __typename?: 'CompleteManagementUserPasswordResetPayload', idpUserId: any } };

export type CompleteManagementUserSignUpMutationVariables = Exact<{
  input: CompleteManagementUserSignUpInput;
}>;


export type CompleteManagementUserSignUpMutation = { __typename?: 'Mutation', completeManagementUserSignUp: { __typename?: 'CompleteManagementUserSignUpPayload', idpUserId: any } };


export const InitiateManagementUserSignUpDocument = gql`
    mutation InitiateManagementUserSignUp($input: InitiateManagementUserSignUpInput!) {
  initiateManagementUserSignUp(input: $input) {
    idpUserId
  }
}
    `;
export const InitiateManagementUserPasswordResetDocument = gql`
    mutation InitiateManagementUserPasswordReset($input: InitiateManagementUserPasswordResetInput!) {
  initiateManagementUserPasswordReset(input: $input) {
    idpUserId
  }
}
    `;
export const CompleteManagementUserPasswordResetDocument = gql`
    mutation CompleteManagementUserPasswordReset($input: CompleteManagementUserPasswordResetInput!) {
  completeManagementUserPasswordReset(input: $input) {
    idpUserId
  }
}
    `;
export const CompleteManagementUserSignUpDocument = gql`
    mutation CompleteManagementUserSignUp($input: CompleteManagementUserSignUpInput!) {
  completeManagementUserSignUp(input: $input) {
    idpUserId
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();
const InitiateManagementUserSignUpDocumentString = print(InitiateManagementUserSignUpDocument);
const InitiateManagementUserPasswordResetDocumentString = print(InitiateManagementUserPasswordResetDocument);
const CompleteManagementUserPasswordResetDocumentString = print(CompleteManagementUserPasswordResetDocument);
const CompleteManagementUserSignUpDocumentString = print(CompleteManagementUserSignUpDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    InitiateManagementUserSignUp(variables: InitiateManagementUserSignUpMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: InitiateManagementUserSignUpMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<InitiateManagementUserSignUpMutation>(InitiateManagementUserSignUpDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InitiateManagementUserSignUp', 'mutation');
    },
    InitiateManagementUserPasswordReset(variables: InitiateManagementUserPasswordResetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: InitiateManagementUserPasswordResetMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<InitiateManagementUserPasswordResetMutation>(InitiateManagementUserPasswordResetDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InitiateManagementUserPasswordReset', 'mutation');
    },
    CompleteManagementUserPasswordReset(variables: CompleteManagementUserPasswordResetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CompleteManagementUserPasswordResetMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CompleteManagementUserPasswordResetMutation>(CompleteManagementUserPasswordResetDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CompleteManagementUserPasswordReset', 'mutation');
    },
    CompleteManagementUserSignUp(variables: CompleteManagementUserSignUpMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CompleteManagementUserSignUpMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CompleteManagementUserSignUpMutation>(CompleteManagementUserSignUpDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CompleteManagementUserSignUp', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;