import clsx from 'clsx';
import React, {
  FormEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { noop } from '../../../helpers/utils';
import { Button, ButtonContext } from '../../Buttons';
import { IconName } from '../../Icons';
import { BaseFormControl, BaseSelectEvents } from '../Form.models';
import { FormElementContainer } from '../FormElementContainer';
import classes from './Tags.scss';

export interface TagsProps<T = string>
  extends BaseFormControl,
    BaseSelectEvents {
  /** If set, sets the form control value */
  value?: string[];
  /** Array of options that can be selected from */
  tagsOptions?: T[];
  /** Optional Drop down label (default: '')*/
  dropDownLabel?: string;
  /** Whether or not the control should start focused (default: false) */
  autoFocus?: boolean;
  /** If the tags component uses objects as tagsOptions, this prop is used as the key to identify display value */
  displayKey?: keyof T;
  /** If the tags component uses objects as tagsOptions, this prop is used as the key to identify value */
  valueKey?: keyof T;
}

export const Tags = <T,>({
  id,
  name,
  value = [],
  dropDownLabel = '',
  tagsOptions = [],
  disabled = false,
  error,
  autoFocus = false,
  onChange = noop,
  onBlur,
  onFocus,
  displayKey,
  valueKey,
  className = '',
  ...rest
}: PropsWithChildren<TagsProps<T>>): JSX.Element => {
  const [currentTags, setCurrentTags] = useState<string[]>(value); // Current tags the user has selected

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const ref = useRef<FormEvent<HTMLSelectElement>>();

  useEffect(() => {
    setCurrentTags(value);
  }, [value]);

  const errorMsg: string | undefined = error;

  useEffect(() => {
    // Only emit if there is a current event
    if (ref.current) {
      onChange({
        ...ref.current,

        currentTarget: { value: currentTags as unknown as string },
      } as React.FormEvent<HTMLSelectElement>);

      // Resets event data
      ref.current = undefined;
    }
  }, [currentTags, onChange]);

  /**
   * Adds a tag to currently selected list
   * @param e Select FormEvent
   */
  function addTag(e: FormEvent<HTMLSelectElement>): void {
    setShouldAnimate(true);

    const newTag = e.currentTarget.value;

    // Set event data
    ref.current = e;

    setCurrentTags((prevState) => [...prevState, newTag]);
  }

  /**
   * Removes tags from currently selected tags
   * @param idx index of tag to be filtered
   * @param e Event
   */
  function removeTag(idx: number, e: unknown): void {
    // Set event data
    ref.current = e as FormEvent<HTMLSelectElement>;

    setCurrentTags((prevState) =>
      prevState.filter((_: string, i: number) => i !== idx),
    );
  }

  function getDisplayValue(tag: string): string {
    if (displayKey && valueKey) {
      const option = tagsOptions.find(
        (option) => String(option[valueKey]) === tag,
      );

      return option ? String(option[displayKey]) : tag;
    }

    return tag;
  }

  return (
    <FormElementContainer
      {...rest}
      className={clsx(classes.container, 'tags-container', className)}
      error={errorMsg}
      dataTestFieldType="Tags"
    >
      <div className={clsx(classes.tagsWrapper)}>
        <select
          className={clsx({ [classes.hasError]: errorMsg !== undefined })}
          id={id}
          name={name}
          disabled={disabled}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => {
            e.persist();
            e.target?.blur();
            addTag(e);
          }}
          hidden={currentTags.length === tagsOptions.length}
        >
          {[
            <option key={'eae2713d-1a32-4bdb-8f87-c7e1f7e2a3b2'} value={''}>
              {dropDownLabel}
            </option>,
          ].concat(
            tagsOptions
              .filter((currentTag: T) => {
                if (typeof currentTag === 'string') {
                  return !currentTags.includes(currentTag);
                } else if (valueKey) {
                  return !currentTags.includes(String(currentTag[valueKey]));
                }
              })
              .map((option) => {
                if (typeof option === 'string') {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                } else if (valueKey && displayKey) {
                  return (
                    <option
                      key={String(option[valueKey])}
                      value={String(option[valueKey])}
                    >
                      {String(option[displayKey])}
                    </option>
                  );
                }
                return <></>;
              }),
          )}
        </select>
        <TransitionGroup component={null}>
          {currentTags.map((tag, idx) => (
            <CSSTransition
              key={tag}
              timeout={{ enter: 1000, exit: 10 }}
              classNames={{
                enter: clsx(shouldAnimate && classes.tagEnter),
                enterActive: clsx(shouldAnimate && classes.tagEnterActive),
                exit: classes.tagExit,
                exitActive: classes.tagExitActive,
              }}
              onEntered={() => {
                setShouldAnimate(false);
              }}
            >
              <span
                key={tag}
                className={clsx({
                  [classes.selectedItem]: true,
                  [classes.disabled]: disabled,
                })}
                data-test-id="tag"
              >
                <span>{getDisplayValue(tag)}</span>
                <Button
                  type="button"
                  icon={IconName.X}
                  onButtonClicked={(e) => {
                    e.persist();
                    removeTag(idx, e);
                  }}
                  disabled={disabled}
                  buttonContext={ButtonContext.Icon}
                  dataTestId="tags-delete"
                ></Button>
              </span>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </FormElementContainer>
  );
};
