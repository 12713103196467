import React, { useEffect, useState } from 'react';
import MosaicBG from '../../resources/images/bg.svg';
import mosaicLogo from '../../resources/images/mosaic_logo.svg';
import { useIdentityService } from '../IdentityServiceProvider/IdentityServiceProvider';
import { BrandingOptions } from '../common/BrandingOptions';
import classes from './ConfirmSignUp.module.scss';

const DEFAULT_BG_IMAGE = MosaicBG;

export interface ForgotPasswordProps {
  brandingOptions?: BrandingOptions;
}
/**
 * Renders the ConfirmSignUp component
 */
export const ConfirmSignUp: React.FC<ForgotPasswordProps> = ({
  brandingOptions,
}) => {
  const { completeUserSignUp, getWellKnownUrls } = useIdentityService();
  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const background = brandingOptions?.background ?? DEFAULT_BG_IMAGE;

  const backgroundStyles = {
    background: background?.includes('/') ? `url(${background})` : background,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
  };

  useEffect(() => {
    (async () => {
      const wellKnownUrls = await getWellKnownUrls();

      const signUpOtp = window.location.pathname.split('/').pop() ?? '';
      if (wellKnownUrls.axAuthManagementGraphQlEndpoint !== undefined) {
        const completeSignUpResponse = await completeUserSignUp(
          signUpOtp,
          wellKnownUrls.axAuthManagementGraphQlEndpoint,
        );
        if (!completeSignUpResponse.isSuccess) {
          setErrorMessage(
            completeSignUpResponse.errorMessage ??
              'Unexpected Error occurred while attempting to complete sign up. Please try again later.',
          );
        } else {
          setIsVerified(true);
        }
      } else {
        setErrorMessage('AxAuthManagementGraphQlEndpoint is not available');
      }
    })();
  }, []);

  return (
    <div
      className={classes.container}
      style={backgroundStyles}
      data-test-id="confirm-sign-up-page"
    >
      <div className={classes.whiteBox}>
        <div className={classes.logo}>
          <img
            alt="Customer logo"
            src={brandingOptions?.companyLogo ?? mosaicLogo}
          />
        </div>
        <div className={classes.content}>
          {errorMessage && (
            <p data-test-id="confirm-signup-error-message">{errorMessage}</p>
          )}
          {isVerified && (
            <p data-test-id="confirm-signup-success-message">
              Your account has been verified.
            </p>
          )}

          <div
            className={classes.button}
            onClick={() => window.location.assign('/')}
            data-test-id="confirm-signup-back-to-sign-in-button"
          >
            Back to Sign In
          </div>
        </div>
      </div>
    </div>
  );
};
