import React, { useState } from 'react';
import { ButtonContext, TextButton } from '../../TextButton';
import classes from './EnvironmentInformationBox.module.scss';

interface EnvironmentInformationBoxProps {
  tenantId?: string;
  environmentId?: string;
}

const localStorageKey = 'MOSAIC_PORTAL_CONFIG';

export const EnvironmentInformationBox: React.FC<
  EnvironmentInformationBoxProps
> = ({ tenantId, environmentId }) => {
  const [show, setShow] = useState(true);

  return (
    <div
      className={classes.box}
      onClick={() => setShow(!show)}
      title={`${show ? 'minimize' : 'show'} environment details`}
    >
      {show ? (
        <>
          This management system is connected to:
          <pre>
            Tenant Id: {tenantId}
            {'\n'}
            Environment Id: {environmentId}
          </pre>
          {localStorage.getItem(localStorageKey) && (
            <div title="">
              <TextButton
                buttonContext={ButtonContext.Context}
                onButtonClicked={(e) => {
                  localStorage.removeItem(localStorageKey);
                  location.reload();
                  e.stopPropagation();
                }}
                text={'remove connection'}
              />
            </div>
          )}
        </>
      ) : (
        'ⓘ'
      )}
    </div>
  );
};
