import { PiralInstance } from 'piral-core';
import React from 'react';
import * as semver from 'semver';
import { shellInfo } from './shellInfo';

export async function checkForOutdatedVersion(
  instance: PiralInstance,
): Promise<void> {
  // Request from npm registry the latest version of the package
  await fetch(`https://registry.npmjs.org/${shellInfo.name}`)
    // check if the latest version is higher then the current version
    .then((response) => response.json())
    .then((data) => {
      if (
        shellInfo.version &&
        semver.gt(data['dist-tags'].latest, shellInfo.version, { loose: true })
      ) {
        instance.root.showNotification({
          title: `Outdated ${shellInfo.name}`,
          body: (
            <>
              <p>
                A new version of <code>{shellInfo.name}</code> (
                {data['dist-tags'].latest}) is available!
              </p>
              <p>
                Run <code>&apos;yarn pilet upgrade&apos;</code> to update your
                emulator to the latest version.
              </p>
              <p>
                This will make sure that your development experience will match
                the production environment.
              </p>
            </>
          ),
          options: {
            type: 'warning',
            autoClose: false,
          },
        });
      }
    })
    .catch(() => console.warn(`Could not check ${shellInfo.name} version`));
}
