import { useField } from 'formik';
import React from 'react';
import { useFormikError } from '../useFormikError';
import { FileUploadControl, FileUploadProps } from './FileUploadControl';

export const FileUploadField: React.FC<
  Omit<FileUploadProps, 'onFileSelected' | 'error'>
> = (props) => {
  const error = useFormikError(props.name);
  const [field, , helpers] = useField(props.name);

  return (
    <FileUploadControl
      {...props}
      value={field.value}
      onFileSelected={helpers.setValue}
      error={error}
    />
  );
};
