import { PiralPlugin } from 'piral-core';
import * as actions from './actions';
import { NavigationApi, RegisterNavigationItem } from './types';
import { withNavigationState } from './withNavigationState';

export function createNavigationApi(): PiralPlugin<NavigationApi> {
  return (context) => {
    context.defineActions(actions);

    context.dispatch((state) => ({
      ...state,
      registry: {
        ...state.registry,
        navigation: {
          items: [],
        },
      },
    }));
    let next = 0;

    return (api, target) => {
      const pilet = target.name ?? 'unknown';
      return {
        registerNavigationItem: (item: RegisterNavigationItem) => {
          const name = item.name ? item.name : String(next++);
          context.registerNavigationItem({ ...item, name, pilet });
          return () => api.unregisterNavigationItem(name);
        },
        unregisterNavigationItem: (name) => {
          context.unregisterNavigationItem(name);
        },
        createNavigationConnector: () => {
          return (component) => withNavigationState(component);
        },
        setNavigationItemsTransformer: (transformer) => {
          context.setItemsTransformer(transformer);
        },
        setNavigationTreeTransformer: (transformer) => {
          context.setTreeTransformer(transformer);
        },
      };
    };
  };
}
