import React, { useEffect, useRef } from 'react';
import { ReactTabsFunctionComponent, Tab, TabProps } from 'react-tabs';
import classes from './CustomTab.scss';

export const CustomTab: ReactTabsFunctionComponent<TabProps> = ({
  children,
  ...otherProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && ref.current.parentElement && otherProps.selected) {
      ref.current.parentElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [otherProps.selected]);

  return (
    <Tab
      {...otherProps}
      className={classes.tab}
      selectedClassName={classes.selected}
      disabledClassName={classes.disabled}
      data-test-id="tab"
    >
      <div className={classes.content} ref={ref}>
        {children}
      </div>
    </Tab>
  );
};

CustomTab.tabsRole = 'Tab';
