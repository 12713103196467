import * as React from 'react';
import classes from './Loading.module.scss';

export const Loading: React.FC = () => (
  <div className={classes.loading}>
    <div className={classes.loadingContent}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 125 125"
      >
        <path
          fill="#00467D"
          d="M56.9,18.1c3.5-1.8,7.7-1.8,11.2,0l34,17.6c4.1,2.1,6.6,6.3,6.6,10.9v32.9c0,4.6-2.5,8.8-6.6,10.9l-34,17.6
                c-3.5,1.8-7.7,1.8-11.2,0l-34-17.6c-4.1-2.1-6.6-6.3-6.6-10.9V46.6c0-4.6,2.5-8.8,6.6-10.9L56.9,18.1z"
        />
        <path
          fill="#28AAE1"
          d="M39.7,65.1c0.9-0.5,1.9-0.5,2.8,0l6.1,3.1c1,0.5,1.7,1.6,1.7,2.8v5.7c0,1.1-0.6,2.2-1.7,2.8l-6.1,3.1
                c-0.9,0.5-1.9,0.5-2.8,0l-6.1-3.1c-1-0.5-1.7-1.6-1.7-2.8V71c0-1.1,0.6-2.2,1.7-2.8L39.7,65.1z"
        />
        <path
          fill="#FFC81A"
          d="M39.7,54.3c0.9-0.5,1.9-0.5,2.8,0l6.1,3.1c1,0.5,1.7,1.6,1.7,2.8v5.7c0,1.1-0.6,2.2-1.7,2.8l-6.1,3.1
                c-0.9,0.5-1.9,0.5-2.8,0l-6.1-3.1c-1-0.5-1.7-1.6-1.7-2.8v-5.7c0-1.1,0.6-2.2,1.7-2.8L39.7,54.3z"
        />
        <path
          fill="#F06E55"
          d="M39.7,43.6c0.9-0.5,1.9-0.5,2.8,0l6.1,3.1c1,0.5,1.7,1.6,1.7,2.8v5.7c0,1.1-0.6,2.2-1.7,2.8l-6.1,3.1
                c-0.9,0.5-1.9,0.5-2.8,0L33.6,58c-1-0.5-1.7-1.6-1.7-2.8v-5.7c0-1.1,0.6-2.2,1.7-2.8L39.7,43.6z"
        />
        <path
          fill="#F06E55"
          d="M85.4,65.1c-0.9-0.5-1.9-0.5-2.8,0l-6.1,3.1c-1,0.5-1.7,1.6-1.7,2.8v5.7c0,1.1,0.6,2.2,1.7,2.8l6.1,3.1
                c0.9,0.5,1.9,0.5,2.8,0l6.1-3.1c1-0.5,1.7-1.6,1.7-2.8V71c0-1.1-0.6-2.2-1.7-2.8L85.4,65.1z"
        />
        <path
          fill="#FFC81A"
          d="M85.4,54.3c-0.9-0.5-1.9-0.5-2.8,0l-6.1,3.1c-1,0.5-1.7,1.6-1.7,2.8v5.7c0,1.1,0.6,2.2,1.7,2.8l6.1,3.1
                c0.9,0.5,1.9,0.5,2.8,0l6.1-3.1c1-0.5,1.7-1.6,1.7-2.8v-5.7c0-1.1-0.6-2.2-1.7-2.8L85.4,54.3z"
        />
        <path
          fill="#28AAE1"
          d="M85.4,43.6c-0.9-0.5-1.9-0.5-2.8,0l-6.1,3.1c-1,0.5-1.7,1.6-1.7,2.8v5.7c0,1.1,0.6,2.2,1.7,2.8l6.1,3.1
                c0.9,0.5,1.9,0.5,2.8,0l6.1-3.1c1-0.5,1.7-1.6,1.7-2.8v-5.7c0-1.1-0.6-2.2-1.7-2.8L85.4,43.6z"
        />
        <path
          fill="#28AAE1"
          d="M50.4,49c0.9-0.5,1.9-0.5,2.8,0l6.1,3.1c1,0.5,1.7,1.6,1.7,2.8v5.7c0,1.1-0.6,2.2-1.7,2.8l-6.1,3.1
                c-0.9,0.5-1.9,0.5-2.8,0l-6.1-3.1c-1-0.5-1.7-1.6-1.7-2.8v-5.7c0-1.1,0.6-2.2,1.7-2.8L50.4,49z"
        />
        <path
          fill="#F06E55"
          d="M74.7,49c-0.9-0.5-1.9-0.5-2.8,0l-6.1,3.1c-1,0.5-1.7,1.6-1.7,2.8v5.7c0,1.1,0.6,2.2,1.7,2.8l6.1,3.1
                c0.9,0.5,1.9,0.5,2.8,0l6.1-3.1c1-0.5,1.7-1.6,1.7-2.8v-5.7c0-1.1-0.6-2.2-1.7-2.8L74.7,49z"
        />
        <path
          fill="#FFC81A"
          d="M61.1,54.7c0.9-0.5,1.9-0.5,2.8,0l6.1,3.1c1,0.5,1.7,1.6,1.7,2.8v5.7c0,1.1-0.6,2.2-1.7,2.8L64,72.2
                c-0.9,0.5-1.9,0.5-2.8,0L55,69.1c-1-0.5-1.7-1.6-1.7-2.8v-5.7c0-1.1,0.6-2.2,1.7-2.8L61.1,54.7z"
        />
        <path
          className={classes.overlay}
          fill="#00467D"
          d="M56.9,18.1c3.5-1.8,7.7-1.8,11.2,0l34,17.6c4.1,2.1,6.6,6.3,6.6,10.9v32.9c0,4.6-2.5,8.8-6.6,10.9l-34,17.6
                c-3.5,1.8-7.7,1.8-11.2,0l-34-17.6c-4.1-2.1-6.6-6.3-6.6-10.9V46.6c0-4.6,2.5-8.8,6.6-10.9L56.9,18.1z"
        />
        <path
          fill="#28AAE1"
          d="M104.3,31.7l-34-17.6c-4.8-2.5-10.6-2.5-15.4,0l-34,17.6c-5.6,2.9-9.1,8.6-9.1,14.9v32.9
                c0,6.3,3.5,12.1,9.1,14.9l34,17.6c2.4,1.2,5,1.8,7.7,1.8s5.3-0.6,7.7-1.8l34-17.6c5.6-2.9,9.1-8.6,9.1-14.9V46.6
                C113.4,40.3,109.9,34.6,104.3,31.7z M108.8,79.5c0,4.6-2.5,8.8-6.6,10.9l-34,17.6c-3.5,1.8-7.7,1.8-11.2,0l-34-17.6
                c-4.1-2.1-6.6-6.3-6.6-10.9V46.7c0-4.6,2.5-8.8,6.6-10.9l34-17.6c3.5-1.8,7.7-1.8,11.2,0l34,17.6c4.1,2.1,6.6,6.3,6.6,10.9V79.5z"
        />
        <path
          fill="#FFC81A"
          d="M106.4,27.6L72.4,10c-6.1-3.2-13.5-3.2-19.6,0l-34,17.6c-7.2,3.7-11.6,10.9-11.6,19v32.9c0,8,4.4,15.3,11.5,19
                l34,17.6c3.1,1.6,6.4,2.4,9.9,2.4c3.4,0,6.7-0.8,9.9-2.4l34-17.6c7.1-3.7,11.5-11,11.5-19V46.7C118,38.6,113.6,31.3,106.4,27.6z
                M113.4,79.5c0,6.3-3.5,12.1-9.1,14.9l-34,17.6c-2.4,1.2-5,1.8-7.7,1.8c-2.7,0-5.3-0.6-7.7-1.8l-34-17.6c-5.6-2.9-9.1-8.6-9.1-14.9
                V46.7c0-6.3,3.5-12.1,9.1-14.9l34-17.6c4.8-2.5,10.6-2.5,15.4,0l34,17.6c5.6,2.9,9.1,8.6,9.1,14.9V79.5z"
        />
        <path
          fill="#F06E55"
          d="M108.5,23.6L74.5,6C67,2.2,58.1,2.2,50.7,6l-34,17.6C7.9,28,2.5,36.9,2.5,46.7v32.9c0,9.8,5.4,18.6,14.1,23.1
                l34,17.6c3.7,1.9,7.8,2.9,11.9,2.9s8.2-1,11.9-2.9l34-17.6c8.6-4.5,14.1-13.3,14.1-23.1V46.7C122.6,36.9,117.1,28,108.5,23.6z
                M118,79.5c0,8-4.4,15.3-11.5,19l-34,17.6c-3.1,1.6-6.4,2.4-9.9,2.4c-3.4,0-6.7-0.8-9.9-2.4l-34-17.6c-7.1-3.7-11.5-11-11.5-19V46.7
                c0-8,4.4-15.3,11.5-19l34-17.6c6.1-3.2,13.5-3.2,19.6,0l34,17.6c7.1,3.7,11.5,11,11.5,19L118,79.5L118,79.5z"
        />
      </svg>

      <span className={classes.loadingMessage}>
        Loading workflows
        <br />
        Please wait
      </span>
    </div>
  </div>
);
