/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocationDescriptor } from 'history';
import React from 'react';

export interface QuickEditContextType<T> {
  selectedItem: T;
  detailsLink?: LocationDescriptor<unknown>;
  isQuickEditMode?: boolean;
  registerSaveCallback?: (callback: () => Promise<void>) => void;
  refresh: () => Promise<void>;
}

// TODO: Find an elegant way to type this context
export const QuickEditContext = React.createContext<QuickEditContextType<any>>(
  undefined as any,
);
