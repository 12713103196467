import React from 'react';
import { ErrorMapper } from '../ErrorMapper.type';

/**
 * An error mapper that maps errors from the Apollo Client to more user friendly errors.
 * @param error The error to map
 * @param mappedError The error that was mapped by a previous error mapper
 * @returns The mapped error or undefined if the error could not be mapped
 */
export const ApolloErrorMapper: ErrorMapper = (error, mappedError) => {
  if (!mappedError) {
    const apolloError = error as {
      networkError?: { message: string };
      name?: string;
    };

    if (apolloError.name === 'ApolloError' && apolloError.networkError) {
      return {
        title: 'A network error occurred',
        body: (
          <>
            <p>Please check your internet connection and try again.</p>
            <>
              <i>Original Error:</i>
              <pre>{JSON.stringify(error, undefined, 2)}</pre>
            </>
          </>
        ),
      };
    }
    return undefined;
  }
};
