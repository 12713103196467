import clsx from 'clsx';
import React, { useState } from 'react';
import { ButtonContext } from '../../../Buttons';
import { TextButton } from '../../../Buttons/TextButton/TextButton';
import { Checkbox } from '../../../FormElements';
import { Option } from '../../Filters.model';
import classes from './MultiOptionFilter.scss';

export interface MultiOptionFilterProps {
  value?: string;

  /** Array of Options to be displayed */
  options: Option[];

  /** Callback triggered when a new filter value is selected */
  onSelect: (text: string[]) => void;

  /** CSS Class name for additional styles */
  className?: string;
}

export const MultiOptionsFilter: React.FC<MultiOptionFilterProps> = ({
  value,
  options,
  onSelect,
}) => {
  const [selectedOptionList, setSelectedOptionList] = useState<string[]>(
    value ? value.split(',') : [],
  );

  return (
    <div className={clsx(classes.multiFilterContainer)}>
      {options?.map((option: Option) => (
        <Checkbox
          className={clsx(
            classes.Checkbox,
            selectedOptionList.includes(option.value.toString()) &&
              classes.selected,
          )}
          key={option.value}
          name={option.label}
          value={selectedOptionList.includes(option.value.toString())}
          label={option.label}
          onChange={(value) => {
            if (value) {
              setSelectedOptionList([
                ...selectedOptionList,
                option.value.toString(),
              ]);
            } else {
              setSelectedOptionList(
                [...selectedOptionList].filter(
                  (i) => i !== option.value.toString(),
                ),
              );
            }
          }}
        />
      ))}
      <div className={clsx(classes.applyButtonContainer)}>
        <TextButton
          className={clsx(classes.applyButton)}
          text="Apply"
          buttonContext={ButtonContext.Active}
          onButtonClicked={() => onSelect(selectedOptionList)}
          disabled={selectedOptionList.length === 0}
        />
      </div>
    </div>
  );
};
