import {
  AddIndicator,
  CustomEventEmitter,
  RemoveIndicator,
  ShowNotification,
} from './types/ui-config';

export enum SaveIndicatorType {
  Saving = 'saving',
  Inactive = 'inactive',
  Dirty = 'dirty',
}

export let showNotification: ShowNotification | (() => void) =
  polyfill('showNotification');

export let addIndicator: AddIndicator | (() => void) = polyfill('addIndicator');

export let removeIndicator: RemoveIndicator | (() => void) =
  polyfill('removeIndicator');

export let on: CustomEventEmitter['on'] | (() => void) = polyfill('on');

export let setTitle: (title?: string) => void = polyfill('setTitle');

export let setSaveIndicator: (type: SaveIndicatorType) => void =
  polyfill('setSaveIndicator');

/**
 * Passes the PiralApi methods to the UI library.
 * @param app {UiConfig} object containing PiralApi methods for use in UI library.
 */
export function initializeUi(app: UiConfig): void {
  ({
    showNotification = polyfill('showNotification'),
    addIndicator = polyfill('addIndicator'),
    removeIndicator = polyfill('removeIndicator'),
    on = polyfill('on'),
    setSaveIndicator = polyfill('setSaveIndicator'),
    setTitle = polyfill('setTitle'),
  } = app);
}

export interface UiConfig {
  showNotification: ShowNotification;
  addIndicator: AddIndicator;
  removeIndicator: RemoveIndicator;
  on: CustomEventEmitter['on'];
  setSaveIndicator: (type: SaveIndicatorType) => void;
  setTitle: (title?: string) => void;
}

function polyfill(methodName: string): () => void {
  return () => {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not find '${methodName}' method. Is the UI initialized in the pilet? To initialize UI library call 'initializeUi(app)' in your pilet.`,
    );
  };
}
