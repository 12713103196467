import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setTitle } from '../../initialize';

export const useTabTitle = (
  title: string | undefined,
  setTabTitle: boolean | undefined,
): void => {
  // useTabTitle should work outside a react router context
  const { location } = useHistory() ?? {};
  const [initialLocation] = useState(location);

  if (setTabTitle && location?.pathname === initialLocation?.pathname) {
    setTitle(title);
  }
};
