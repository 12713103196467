import React from 'react';
import configError from '../../resources/images/configuration_error_icon.svg';
import { Message } from '../Message';
import classes from './ConfigurationInvalidError.module.scss';

export const ConfigurationInvalidError: React.FC = () => (
  <>
    <Message type="error" title={'Configuration not valid'} />
    <img src={configError} className={classes.centerImage} />
    <p className={classes.center}>
      Please check environment configuration and try again.
    </p>
    <p className={classes.center}>
      If the problem persists, please contact
      <a href="mailto: support@axinom.com"> Axinom Support</a>.
    </p>
  </>
);
