import React from 'react';
import { Data } from '../../../../../types/data';
import { ColumnMap, ColumnRenderer } from '../../../../List/List.model';
import classes from './StateRenderer.scss';

/**
 * Renders a state icon for an item.
 *
 * To be used as `Column.render` value.
 *
 * @param map An object of key/value pairs mapping column values to CSS colors.
 * @param titleMapper A mapper function that returns a string value based on
 * original value to set the title. If not specified, value is converted to
 * string.
 * @example
 * <List
 *   {...otherProps}
 *   columns=[{
 *     propertyName: 'state'
 *     render: createStateRenderer({ changed: 'lightblue' })
 * }]/>
 */
export const createStateRenderer = <T extends Data>(
  map: ColumnMap = {},
  titleMapper?: (value: unknown) => string,
): ColumnRenderer<T> => {
  const StateRenderer = (value: unknown): React.ReactNode => (
    <div
      className={classes.container}
      style={{ backgroundColor: map[value as string] }}
      title={titleMapper?.(value) ?? String(value)}
    ></div>
  );
  return StateRenderer;
};
