import { useField } from 'formik';
import React, { PropsWithChildren } from 'react';
import { useFormikError } from '../useFormikError';
import { Tags, TagsProps } from './Tags';
export const TagsField = <T,>(
  props: PropsWithChildren<Omit<TagsProps<T>, 'error' | 'onChange'>>,
): JSX.Element => {
  const { name } = props;
  const error = useFormikError(name);
  const [field, , helpers] = useField(name);

  return (
    <Tags
      {...props}
      value={field.value}
      error={error}
      onChange={(e) => helpers.setValue(e.currentTarget.value)}
    />
  );
};
