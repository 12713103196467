import { Notification, NotificationId } from '@axinom/mosaic-ui';
import { PiralPlugin } from 'piral-core';
import * as actions from './actions';
import { NotificationApi } from './types';

export function createNotificationsApi(): PiralPlugin<NotificationApi> {
  return (context) => {
    context.defineActions(actions);

    return () => ({
      showNotification: (notification: Notification): NotificationId => {
        return context.showNotification(notification);
      },
      updateNotification: (
        id: NotificationId,
        notification: Notification,
      ): void => {
        context.updateNotification(id, notification);
      },
      dismissNotification: (id: NotificationId): void => {
        context.dismissNotification(id);
      },
    });
  };
}
