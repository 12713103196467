/* eslint-disable @typescript-eslint/no-unused-vars */
import { Data } from '../../../../../types/data';
import { formatDateTime } from '../../../../Utils/Transformers/DateTime';

/**
 * Displays a value in Date format
 * @param val date
 * @param data row data
 */
export const DateRenderer = <T extends Data>(val: unknown, data: T): string => {
  if (val) {
    return String(formatDateTime(val as string));
  }

  return '';
};
