import { GlobalStateContext } from 'piral-core';
import {
  NavigationItem,
  NavigationPanelCategory,
  NavigationTransformer,
} from './types';

export function registerNavigationItem(
  ctx: GlobalStateContext,
  value: NavigationItem,
): void {
  ctx.dispatch((state) => {
    const items = [...state.registry.navigation.items, value];
    return {
      ...state,
      registry: {
        ...state.registry,
        navigation: {
          ...state.registry.navigation,
          items,
        },
      },
    };
  });
}

export function unregisterNavigationItem(
  ctx: GlobalStateContext,
  name: string,
): void {
  ctx.dispatch((state) => {
    const items = [...state.registry.navigation.items];
    const removeIndex = items.findIndex((item) => name === item.name);
    items.splice(removeIndex, 1);
    return {
      ...state,
      registry: {
        ...state.registry,
        navigation: {
          ...state.registry.navigation,
          items,
        },
      },
    };
  });
}

export function setItemsTransformer(
  ctx: GlobalStateContext,
  itemsTransformer: NavigationTransformer<NavigationItem>,
): void {
  ctx.dispatch((state) => {
    return {
      ...state,
      registry: {
        ...state.registry,
        navigation: {
          ...state.registry.navigation,
          itemsTransformer,
        },
      },
    };
  });
}

export function setTreeTransformer(
  ctx: GlobalStateContext,
  treeTransformer: NavigationTransformer<NavigationPanelCategory>,
): void {
  ctx.dispatch((state) => {
    return {
      ...state,
      registry: {
        ...state.registry,
        navigation: {
          ...state.registry.navigation,
          treeTransformer,
        },
      },
    };
  });
}
