import { LocationDescriptor } from 'history';
import React, { ForwardedRef } from 'react';
import { Data } from '../../../types/data';
import { IconName } from '../../Icons';
import { ListSelectMode } from '../../List';
import { Explorer, ExplorerProps } from '../Explorer';
import { ExplorerDataProviderConnection } from '../Explorer.model';

export interface NavigationExplorerProps<T extends Data>
  extends Omit<
    ExplorerProps<T>,
    'selectionMode' | 'onBulkActionsToggled' | 'onItemClicked' | 'setTabTitle'
  > {
  /**
   * - If a `LocationDescriptor` is provided, it will be treated as a path to the station to
   * navigate to, and a link will be generated.
   * - If a `function` is provided, it will be called when the create action is
   * clicked, and a button will be generated.
   */
  onCreateAction?: (() => void) | LocationDescriptor;

  /**
   * Function that calculates the URL to navigate to when a row of data is clicked.
   *  - The function should take the item data as input and return the URL string.
   *  - Navigation will not occur if bulk actions are open.
   */
  calculateNavigateUrl?: (item: T) => string;
}

/**
 * Navigation Explorer
 * @example
 * <NavigationExplorer<DataInterface>
 *  title="Title"
 *  columns={[{propertyName: 'id', size: '1fr', label: 'Id'}]}
 *  stationKey="station-key"
 *  dataProvider={dataProvider}
 *  bulkActions={[{ label: 'Action One'}]}
 *  calculateNavigateUrl={(rowData => `/details/${rowData.id}`)}
 * />
 */
export const NavigationExplorer = React.forwardRef(function NavigationExplorer<
  T extends Data,
>(
  {
    onCreateAction,
    calculateNavigateUrl,
    actions = [],
    ...rest
  }: NavigationExplorerProps<T>,
  ref: ForwardedRef<ExplorerDataProviderConnection<T>>,
): JSX.Element {
  return (
    <Explorer<T>
      {...rest}
      ref={ref}
      setTabTitle={true}
      actions={[
        ...actions,
        ...(onCreateAction && typeof onCreateAction !== 'function'
          ? [
              {
                label: 'New',
                icon: IconName.Plus,
                path: onCreateAction,
              },
            ]
          : []),
        ...(onCreateAction && typeof onCreateAction === 'function'
          ? [
              {
                label: 'New',
                icon: IconName.Plus,
                onClick: onCreateAction,
              },
            ]
          : []),
      ]}
      selectionMode={ListSelectMode.None}
      generateItemLink={calculateNavigateUrl}
    />
  );
});
