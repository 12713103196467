import { ExtensionSlot } from 'piral-core';
import React from 'react';
import classes from './Navigation.module.scss';
import { NavigationFooter } from './NavigationFooter';

export interface NavigationProps {
  clickHandler?: () => void;
  collapseDisabled?: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({
  clickHandler,
  collapseDisabled,
}) => {
  return (
    <>
      <div className={classes.navigation}>
        <div className={classes.items}>
          <ExtensionSlot
            name="navigation-panel"
            params={{ collapseDisabled, clickHandler }}
          />
        </div>
        <div className={classes.footer}>
          <NavigationFooter path="/about" />
        </div>
      </div>
    </>
  );
};
