import { Not } from '../../types';
import { IconName } from '../Icons';
import { LinkAction } from '../models';

/** Button's context type. This type defines the button's colors and border. */
export enum ButtonContext {
  /**
   * @deprecated not used anymore and will be removed in future versions.
   * Define `path` property instead of `onButtonClicked`,
   * `onBlur` properties for navigation type buttons.
   * This will render link instead of button.
   */
  Navigation,
  Context,
  Active,
  Icon,
  None,
}

/**
 * Button options for buttons with JS handlers
 * (renders as a button)
 */
export interface CommonJsButtonOptions
  extends CommonButtonOptions,
    ButtonSizeOptions,
    JsButtonOptions,
    Not<LinkAction> {
  /**
   * Buttons type (default: 'Active').
   * Affects buttons color and border.
   * Button context type `Navigation` is deprecated and will
   * be removed in future versions.
   * For navigation type of buttons define `path` instead
   * of `onButtonClicked`, `onBlur` and `type` properties
   * to render link instead of button.
   */
  buttonContext?: ButtonContext;
  /** HTML button type attribute (default: 'button') */
  type?: 'button' | 'submit' | 'reset';
}

/**
 * Button options for buttons with navigation
 * (renders as a link)
 */
export interface CommonNavigationButtonOptions
  extends CommonButtonOptions,
    ButtonSizeOptions,
    LinkAction,
    Not<JsButtonOptions> {}

/**
 * Button text options
 */
export interface TextButtonOptions {
  /** Button text */
  text?: string;
}

/**
 * Button icon options
 */
export interface ButtonIconOptions {
  /** Optional icon */
  icon?: IconName;
  /** Optional Icon Position */
  iconPosition?: 'left' | 'right';
  /**
   * Optional custom icon.
   * When provided, `icon` property is ignored.
   */
  customIcon?: React.ReactNode;
}

/**
 * Button event handler options for JS buttons
 */
interface JsButtonOptions {
  /** Event to raise when the button is clicked */
  onButtonClicked?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
}

/**
 * Button size options
 */
interface ButtonSizeOptions {
  /** Button's height */
  height?: string | number;
  /** Button's width */
  width?: string | number;
}

/**
 * Common properties for all buttons
 */
interface CommonButtonOptions {
  /** Whether the button is disabled. If set to true, disallows interactions. (default: undefined) */
  disabled?: boolean;
  /** Optional class */
  className?: string;
  /** Optional data-test-id */
  dataTestId?: string;
}
