import { TransformerFunction } from './TransformerFunction';

/**
 * Converts a string to Title Case.
 * @param value String value to be converted
 */
export const formatTitleCase: TransformerFunction<string, string> = (value) => {
  if (value !== '') {
    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return value;
};
