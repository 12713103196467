import {
  Loader,
  useExpand,
  useValueOrOnDemand,
  ValueOrOnDemand,
} from '@axinom/mosaic-ui';
import clsx from 'clsx';
import { debounce } from 'debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconName, Icons } from '../../Icons';
import { Breadcrumb } from '../Header.models';
import classes from './Breadcrumbs.module.scss';

interface LabelProps {
  label: ValueOrOnDemand;
  handleLabelResolved: () => void;
}

export interface BreadcrumbProps {
  crumbs?: Breadcrumb[];
  className?: string;
}

/**
 * Breadcrumb label
 * @example
 * <Label label={'Movies'} />
 * @param label ValueOrOnDemand
 */
const Label: React.FC<LabelProps> = ({ label, handleLabelResolved }) => {
  const [labelValue, isLabelLoading] = useValueOrOnDemand(label);

  useEffect(() => {
    if (typeof label === 'function' && !isLabelLoading) {
      // timeout is required to trigger parent component layout recalculateion after label is rendered
      setTimeout(() => {
        handleLabelResolved();
      });
    }
  }, [isLabelLoading, label, handleLabelResolved]);

  return (
    <span className={clsx(classes.crumbLabel)}>
      <Loader showLoader={isLabelLoading}>
        <p title={labelValue} data-test-id="breadcrumb-label">
          {labelValue}
        </p>
      </Loader>
    </span>
  );
};

/**
 * Displays breadcrumbs.
 * If there are more than 3 breadcrumbs, only the first, last and next-to-last are shown inline. The rest are shown in a dropdown.
 * Long breadcrumb labels are truncated and shown when highlighted.
 * @example
 * <Breadcrumbs
 *  crumbs={[{label: 'Movies', url: '/movies', params: []}]}
 * />
 */
export const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  crumbs = [],
  className = '',
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isExpanded, expand, collapse } = useExpand();
  const [truncationIndex, setTruncationIndex] = useState(0);

  const children = containerRef.current?.children;
  const widthHomeIcon =
    (children && children[0].getBoundingClientRect().width) || 0;
  const widthEllipsisIcon =
    (children && children[1].getBoundingClientRect().width) || 0;

  const updateLayout = useCallback((): void => {
    const containerRect = containerRef?.current?.getBoundingClientRect();
    const childElements = containerRef.current?.children;

    if (childElements?.length) {
      let freeSpace =
        (containerRect &&
          containerRect?.width - widthHomeIcon - widthEllipsisIcon) ||
        0;

      for (let i = childElements.length - 1; i > 0; i--) {
        freeSpace = freeSpace - childElements[i].getBoundingClientRect().width;

        if (freeSpace <= 0) {
          //-1 to adjust crumbs and html containerRect children index difference
          setTruncationIndex(i - 1);
          break;
        } else if (i === 1) {
          setTruncationIndex(0);
        }
      }
    }
  }, [widthEllipsisIcon, widthHomeIcon]);

  const handleLabelResolved = (): void => {
    updateLayout();
  };

  useEffect(() => {
    if (!truncationIndex) {
      // Make sure that the '...' section will be collapsed if needed again later.
      collapse();
    }
    updateLayout();

    const debouncedUpdateLayout = debounce(updateLayout, 50);

    window.addEventListener('resize', debouncedUpdateLayout);

    return () => {
      window.removeEventListener('resize', debouncedUpdateLayout);
    };
  }, [
    collapse,
    containerRef,
    crumbs,
    truncationIndex,
    widthEllipsisIcon,
    widthHomeIcon,
    updateLayout,
  ]);

  return (
    <div
      className={clsx(classes.container, 'breadcrumbs-container', className)}
      data-test-id="breadcrumbs"
      ref={containerRef}
    >
      <Link
        className={clsx(classes.crumb)}
        to={{
          pathname: '/',
        }}
      >
        <div className={classes.homeButton}>
          <Icons icon={IconName.Home} />
        </div>
        <Icons icon={IconName.Chevron} className={classes.crumbChevron} />
      </Link>

      <div
        className={clsx(classes.dropDownAnchor, {
          [classes.hidden]: truncationIndex === 0,
        })}
        onMouseEnter={expand}
        onMouseLeave={collapse}
        data-test-id="breadcrumbs-dropdown"
      >
        <span>
          <p className={clsx(classes.ellipsisLabel)}>
            <Icons icon={IconName.ShowMoreDots} className={classes.moreIcon} />
          </p>
          {isExpanded && (
            <div className={clsx(classes.dropDownList)}>
              {crumbs?.slice(0, truncationIndex).map((crumb, idx) => (
                <Link key={idx} to={{ pathname: crumb.url }}>
                  <Label
                    label={crumb.label}
                    handleLabelResolved={handleLabelResolved}
                  />
                  <Icons
                    icon={IconName.Chevron}
                    className={classes.dropDownChevron}
                  />
                </Link>
              ))}
            </div>
          )}
        </span>
        <Icons icon={IconName.Chevron} className={classes.crumbChevron} />
      </div>

      {crumbs?.map(
        (crumb, idx) =>
          ({ idx } && (
            <Link
              key={idx}
              to={{ pathname: crumb.url }}
              className={clsx(classes.crumb, {
                [classes.hidden]: truncationIndex > idx,
              })}
            >
              <Label
                label={crumb.label}
                handleLabelResolved={handleLabelResolved}
              />
              <Icons
                icon={IconName.Chevron}
                className={clsx([classes.crumbChevron])}
              />
            </Link>
          )),
      )}
    </div>
  );
};
