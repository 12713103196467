import clsx from 'clsx';
import React from 'react';
import { noop } from '../../../helpers/utils';
import { Button, ButtonContext } from '../../Buttons';
import { IconName } from '../../Icons';
import classes from './ListCheckBox.scss';

export interface ListCheckBoxProps {
  /** Checkbox's height (default: '50px') */
  height?: string;
  /** Checkbox's width (default: '50px') */
  width?: string;
  /** Whether or not the checkbox is currently checked (default: false) */
  isChecked?: boolean;
  /** Whether or not it needs to prevent the click event on the container element from propagating upwards (default: false)*/
  containerClickEvent?: boolean;
  /** Whether or not the checkbox needs to be disabled (default: false) */
  isDisabled?: boolean;
  /** Callback to emit when the checkbox is toggled */
  onCheckBoxToggled?: (checked: boolean) => void;
  /** CSS Class name for additional styles */
  className?: string;
}

export const ListCheckBox: React.FC<ListCheckBoxProps> = ({
  height = '50px',
  width = '50px',
  isDisabled = false,
  isChecked = false,
  containerClickEvent = false,
  onCheckBoxToggled = noop,
  className = '',
}) => {
  // Prevents the click event on the container element from propagating upwards.
  const containerHandler = (event: React.SyntheticEvent): void => {
    if (containerClickEvent) {
      return;
    } else {
      event.stopPropagation();
    }
  };

  return (
    <div
      className={clsx(classes.container, 'list-checkbox-container', className)}
      onClick={containerHandler}
    >
      <Button
        height={height}
        width={width}
        buttonContext={isChecked ? ButtonContext.Active : ButtonContext.Context}
        icon={isChecked ? IconName.Checkmark : undefined}
        disabled={isDisabled}
        onButtonClicked={() => {
          onCheckBoxToggled(!isChecked);
        }}
        className={clsx({
          [classes.disabled]: isDisabled,
        })}
        dataTestId="list-entry-checkbox"
      />
    </div>
  );
};
