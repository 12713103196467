import { EnsurePermission } from '@axinom/mosaic-id-link';
import { useGlobalState } from 'piral-core';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const EnsurePermissionWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const pages = useGlobalState((state) => state.registry.pages);
  // Finding the actual route definition to handle the scenario with parameterized routes.
  const match = matchPath(location.pathname, {
    path: Object.keys(pages),
    exact: true,
  });
  const requiredPermissions = match?.path
    ? pages[match.path]?.meta?.permissions ?? {}
    : {};
  return (
    <EnsurePermission permissions={requiredPermissions}>
      {children}
    </EnsurePermission>
  );
};
