import clsx from 'clsx';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import { IconName, Icons } from '../Icons';
import classes from './InfoTooltip.scss';

export interface InfoTooltipProps {
  className?: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  children,
  className = '',
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  let hideTooltipTimeout: NodeJS.Timeout;
  const tooltipVisibleTime = 500;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
    placement: 'right',
  });

  return (
    <div className={clsx(classes.root, className)}>
      <div
        ref={setReferenceElement as React.LegacyRef<HTMLDivElement>}
        onMouseEnter={() => {
          clearTimeout(hideTooltipTimeout);
          setShowTooltip(true);
        }}
        onMouseLeave={() =>
          (hideTooltipTimeout = global.setTimeout(() => {
            setShowTooltip(false);
          }, tooltipVisibleTime))
        }
      >
        <Icons icon={IconName.Info} className={clsx(classes.icon)} />
      </div>
      <CSSTransition
        in={showTooltip}
        timeout={200}
        classNames={{
          enter: classes['info-tooltip-enter'],
          enterActive: classes['info-tooltip-enter-active'],
          exit: classes['info-tooltip-exit'],
          exitActive: classes['info-tooltip-exit-active'],
        }}
        unmountOnExit
      >
        <div
          className={clsx(classes.container)}
          ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
          style={styles.popper}
          {...attributes.popper}
          onClick={(e) => e.stopPropagation()}
          onMouseEnter={() => {
            clearTimeout(hideTooltipTimeout);
          }}
          onMouseLeave={() =>
            (hideTooltipTimeout = global.setTimeout(() => {
              setShowTooltip(false);
            }, tooltipVisibleTime))
          }
        >
          {children}
          <div
            ref={setArrowElement as React.LegacyRef<HTMLDivElement>}
            className={clsx(classes.arrow)}
            style={styles.arrow}
          />
        </div>
      </CSSTransition>
    </div>
  );
};
