import React from 'react';
import { on } from '../../initialize';

export const useBusy = (): {
  isBusy: boolean;
} => {
  const [busy, setBusy] = React.useState(false);

  on('busy', (busyState) => {
    setBusy(busyState as boolean);
  });

  return { isBusy: busy };
};
