import clsx from 'clsx';
import React from 'react';
import { IconName } from './Icons.models';
import classes from './Icons.scss';

export interface IconsProps {
  /** Icon type */
  icon?: IconName;
  /** Optional class */
  className?: string;
}

const ArchiveIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M27.6,14.7H39V39H1.1V14.6h11.6 M9.9,22.1l10.3,9.1
	l10.3-9.1 M20.1,31.2V0"
    />
  </svg>
);

const BackwardOne: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M4.1,10.5C7.7,5.4,14.3,2,20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20 M12.1,10.5H4.1V2.5 M20.9,25.5V15
	c0,0-1.1,2.1-3.8,3.1"
    />
  </svg>
);

const BackwardTen: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M4.2,10.5C7.8,5.4,14.4,2,20.1,2c9.9,0,18,8.1,18,18s-8.1,18-18,18s-18-8.1-18-18 M12.1,10.5H4.2V2.6
	 M12.3,18.1c2.7-1,3.8-3.1,3.8-3.1v10.5 M23.6,15.3c1.8,0,3.2,2.3,3.2,5.2s-1.4,5.2-3.2,5.2s-3.2-2.3-3.2-5.2S21.8,15.3,23.6,15.3z"
    />
  </svg>
);

const BlockIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20.4,2.4c9.9,0,18,8.1,18,18s-8.1,18-18,18s-18-8.1-18-18S10.5,2.4,20.4,2.4z M9.8,20.4H31"
    />
  </svg>
);

const BulkIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M33.1,38.6H1.3V6.8h31.8V38.6z M26.8,14.4L15.1,27.8l-7.1-7.5 M38.7,31.2V1.4h-29"
    />
  </svg>
);

const CalendarIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#0E7CB4"
      strokeWidth="2"
      d="M12.6,34.8H1.9V5h33.8v8.6 M26.7,15.1c-6.3,0-11.5,5.2-11.5,11.5S20.3,38,26.7,38s11.4-5.2,11.4-11.6
      S33,15.1,26.7,15.1z M35.7,10.9 M1.9,10.9 M10.9,1v9.4 M26.7,1v9.4 M26.2,19.1v8.2l0,0h6.5"
    />
  </svg>
);

const CheckmarkIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M0.9,17.8L16,32.5l23.1-25"
    />
  </svg>
);

const ChevronDownIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      transform="rotate(90, 20, 20)"
      d="M9.2,39.1L30.8,20L9.2,0.9"
    />
  </svg>
);

const ChevronLeftIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      transform="rotate(180, 20, 20)"
      d="M9.2,39.1L30.8,20L9.2,0.9"
    />
  </svg>
);

const ChevronRightIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M9.2,39.1L30.8,20L9.2,0.9"
    />
  </svg>
);

const ChevronUpIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      transform="rotate(-90, 20, 20)"
      d="M9.2,39.1L30.8,20L9.2,0.9"
    />
  </svg>
);

const CopyIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M29.6,38.8H3.2V8.4h17l9.3,9.3V38.8z M20.2,8.4v9.3h9.3 M33.4,31.6h3.4V10.5l-9.3-9.3h-17v3.2 M27.5,1.2v9.3
	h9.3"
    />
  </svg>
);

const DeleteIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M6.5,7.3l2.9,31.3h21.1l2.9-31.3 M0.5,7.2h39 M15.1,13.4L16,33.1 M24.7,13.4l-0.9,19.7 M14.2,7.2l1.2-5.8h8.3
      l1.1,5.8"
    />
  </svg>
);

const DetailsIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M4,8.7h32 M4,16.4h10.2 M36,31.3h-9.5 M18.3,16.4H36 M22.4,31.3H4 M4,24.1h32"
    />
  </svg>
);

const DragIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <rect x="11.4" y="5.4" />
    <rect x="11.4" y="17.7" />
    <rect x="11.4" y="30" />
    <rect x="23.6" y="5.4" />
    <rect x="23.6" y="17.7" />
    <rect x="23.6" y="30" />
  </svg>
);

const EllipsisIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M6.8,30c0,1.3-1.1,2.4-2.4,2.4S2,31.3,2,30s1.1-2.4,2.4-2.4S6.8,28.7,6.8,30z M20,27.6c-1.3,0-2.4,1.1-2.4,2.4
      s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S21.3,27.6,20,27.6z M35.6,27.6c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4S38,31.3,38,30
      S36.9,27.6,35.6,27.6z"
    />
  </svg>
);

const ErrorIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2z M12.5,12.5l15,15 M27.5,12.5l-15,15"
    />
  </svg>
);

const ExternalIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M39.8,14.8 M25.1,0.2 M38.9,15.7V1.3H24.8 M38.9,1.3
	L16.4,23.8 M20.8,8.5H1.2v30.4h29.7V19.4"
    />
  </svg>
);

const FileIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M1,34.7l4.4-18.8H39l-4.3,18.8L1,34.7L1,5.3h12.1
      l3.5,3.8h18.1v6.9"
    />
  </svg>
);

const FiltersIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M5.7,4.7h28.6l-11.3,12v13.6l-6.5,5.1v-18.4L5.7,4.7Z"
    />
  </svg>
);

const ForwardOne: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M35.9,10.5C32.3,5.4,25.7,2,20,2C10.1,2,2,10.1,2,20s8.1,18,18,18s18-8.1,18-18 M28,10.5h7.9V2.6 M17.1,18.1
	c2.7-1,3.8-3.1,3.8-3.1v10.5"
    />
  </svg>
);

const ForwardTen: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M35.9,10.5C32.3,5.4,25.7,2,20,2C10.1,2,2,10.1,2,20s8.1,18,18,18s18-8.1,18-18 M28,10.5h7.9V2.6 M12.3,18.1
	c2.7-1,3.8-3.1,3.8-3.1v10.5 M23.6,15.3c1.8,0,3.2,2.3,3.2,5.2s-1.4,5.2-3.2,5.2s-3.2-2.3-3.2-5.2S21.8,15.3,23.6,15.3z"
    />
  </svg>
);

const InfoIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2z M20,29.9
      V16.2l0,0V29.9z M20,10.1v2.6l0,0V10.1z"
    />
  </svg>
);

const InProgressIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20 M3.9,12c-0.7,1.3-1.2,2.7-1.5,4.2 M16.5,13v14 M23.5,13v14
      M16.1,2.4c-1.5,0.3-3,0.9-4.3,1.6 M8.9,5.8c-1.2,0.9-2.3,2-3.2,3.2"
    />
  </svg>
);

const MidlineEllipsisIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M8,20c0,1.1-0.9,2-2,2S4,21.1,4,20s0.9-2,2-2S8,18.9,8,20z M20,18
      c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S21.1,18,20,18z M34,18c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S35.1,18,34,18z"
    />
  </svg>
);

const MinusIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M0,20h40"
    />
  </svg>
);

const MuteIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M22,7.5v25L13.5,25h-9V15h9L22,7.5z M26.4,24.6c1.8-0.8,3-2.6,3-4.7c0-1.9-1.1-3.6-2.6-4.5 M28.7,30.6
	c4-1.9,6.8-6,6.8-10.7c0-4.5-2.6-8.5-6.3-10.5"
    />
  </svg>
);

const NavigateRightIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="2"
      d="M22.8,1.1l15.4,15.4L24.1,30.6 M38.2,16.5H4.4v23.1"
    />
  </svg>
);

const PauseIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      fill="none"
      strokeWidth="2"
      d="M4.5,2h10.3v36H4.5V2z M25.2,2h10.3v36H25.2V2z"
    />
  </svg>
);

const PlayIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path fill="none" strokeWidth="2" d="M33,20L7,35.4V4.6L33,20z" />
  </svg>
);

const PlusIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M0,20h40 M20,40V0"
    />
  </svg>
);

const PublishIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M1,27v8.6h38V27 M4.6,10.6l5.6-6.3l6.2,6.3 M10.2,4.4v24 M23.6,10.6l5.6-6.3l6.2,6.3 M29.2,4.4v24"
    />
  </svg>
);

const QuickEditIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M11.8,29.7l-8.8,2.6,2.6-8.8,15.8-15.8,6.2,6.2-15.8,15.8h0ZM27.5,19.7h10.5M22.7,24.3h8.4M18,29h6.2M5.6,23.5l6.2,6.2"
    />
  </svg>
);

const QuickEditStationIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M7.5,4.5h25v31H7.5V4.5ZM17.82,13.14h8.87M12.98,13.14h3.07M12.98,17.71h3.07M12.98,22.29h3.07M12.98,26.86h3.07M17.82,17.71h8.87M17.82,26.86h8.87M17.82,22.29h4.84"
    />
  </svg>
);

const RemoveFilterIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M13.7,16.9L3,4.6h28.6l-11.3,12v13.6l-6.5,5.1V16.9z M32.9,22.9l6.1,6.1L32.9,22.9z M32.9,22.9
	l-6.1-6.1L32.9,22.9l-6.1,6.1L32.9,22.9z M32.9,22.9l6.1-6.1L32.9,22.9z"
    />
  </svg>
);

const ReplaceIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      d="M25.6,6.4l6.2,6.2l-6.2,6.2
	 M31.8,12.6H8.2 M14.4,21.2l-6.2,6.2l6.2,6.2 M8.2,27.4h23.6"
    ></path>
  </svg>
);

const RetryIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M9.5,27.3c-4.1-5.8-2.6-13.8,3.1-17.8c4.5-3.2,10.6-3.1,15,0.2 M11.9,18.8l-2.4,8.5L1,24.8 M27,21.4l4.2-7.8
	l7.8,4.2 M31.2,13.6c3.6,6.1,1.5,13.9-4.6,17.5c-4.1,2.4-9.2,2.3-13.2-0.2"
    />
  </svg>
);

const SaveIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M15.8,30.1c0,0-6.2,0.1-7.6-0.1c-3.3-0.4-5.8-3.3-5.6-6.7c-0.2-6,6.9-6.6,6.9-6.6
s-0.7-6.4,5-7.5c3.2-0.8,6.5,0.8,7.8,3.8c1.5-0.6,3.2-0.6,4.7,0.2c1.3,0.8,2.2,2.1,2.4,3.6c0,0,8-0.5,8.1,6.6c0,7.1-6.7,6.7-6.7,6.7
h-6.5"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20.1,33V19.4 M15.7,23.8l4.3-4.3l4.3,4.3"
    />
  </svg>
);

const SnapshotIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M29.5,21c0,5.2-4.3,9.5-9.5,9.5s-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5S29.5,15.8,29.5,21z M37.9,9.1h-8.1l-2.3-4.2
	H12.6l-2.3,4.2H2.1C1.5,9.1,1,9.6,1,10.3v23.6C1,34.5,1.5,35,2.1,35h35.7c0.6,0,1.1-0.5,1.1-1.1V10.3C39,9.6,38.5,9.1,37.9,9.1z
	 M34.8,13.4h-1.5v1.4h1.5V13.4z M14.1,19.8l5.4,5.4l8.5-9.3"
    />
  </svg>
);

const StopIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path fill="none" strokeWidth="2" d="M5,5h30v30H5V5z" />
  </svg>
);

const SuccessIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2z M29,13.5
      L17.8,26.3L11,19.1"
    />
  </svg>
);

const UnarchiveIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="2"
      d="M27.6,16.2H39V39H1.1V16.1h11.6 M30.6,11.1L20.3,2L10,11.1 M20.3,2v31.2"
    />
  </svg>
);

const UndoIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M2,11.8h36v25.8H3.2 M11.4,2.4L2,11.8l9.4,9.4"
    />
  </svg>
);

const UnmuteIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      stroke="#1478AF"
      strokeWidth="2"
      d="M22,7.5v25L13.4,25h-9V15h9L22,7.5z M26.2,14.9l9.4,9.4 M35.6,14.9l-9.4,9.4"
    />
  </svg>
);

const UnpublishIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M26.1,7.6l-6.2-5.5l-6.2,5.5 M19.9,2v14.8 M9.1,16.2L30.9,38 M30.9,16.2L9.1,38"
    />
  </svg>
);

const UploadIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M2,1.4h36 M20.1,39.4V8.7 M29.8,17.3l-9.7-8.6l-9.7,8.6"
    />
  </svg>
);

const WarningIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2z M20,10.1v13.7l0,0V10.1z M20,29.9v-2.6l0,0
      V29.9z"
    ></path>
  </svg>
);

const XIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={clsx(classes.icons, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      strokeWidth="2"
      d="M2.4,2.4l35.3,35.3 M37.6,2.4L2.4,37.6"
    />
  </svg>
);

/**
 * Default SVG icons. A 'className' prop can also be passed in.
 * @example
 * <Icons icon={action.icon} />
 */
export const Icons: React.FC<IconsProps> = ({ icon, className }) => {
  const icons: { [key in IconName]: JSX.Element } = {
    [IconName.Archive]: <ArchiveIcon className={className} />,
    [IconName.BackwardOne]: <BackwardOne className={className} />,
    [IconName.BackwardTen]: <BackwardTen className={className} />,
    [IconName.Block]: <BlockIcon className={className} />,
    [IconName.Bulk]: <BulkIcon className={className} />,
    [IconName.Calendar]: <CalendarIcon className={className} />,
    [IconName.Checkmark]: <CheckmarkIcon className={className} />,
    [IconName.ChevronDown]: <ChevronDownIcon className={className} />,
    [IconName.ChevronLeft]: <ChevronLeftIcon className={className} />,
    [IconName.ChevronRight]: <ChevronRightIcon className={className} />,
    [IconName.ChevronUp]: <ChevronUpIcon className={className} />,
    [IconName.Copy]: <CopyIcon className={className} />,
    [IconName.Delete]: <DeleteIcon className={className} />,
    [IconName.Details]: <DetailsIcon className={className} />,
    [IconName.Drag]: <DragIcon className={className} />,
    [IconName.Ellipsis]: <EllipsisIcon className={className} />,
    [IconName.Error]: <ErrorIcon className={className} />,
    [IconName.External]: <ExternalIcon className={className} />,
    [IconName.File]: <FileIcon className={className} />,
    [IconName.Filters]: <FiltersIcon className={className} />,
    [IconName.ForwardOne]: <ForwardOne className={className} />,
    [IconName.ForwardTen]: <ForwardTen className={className} />,
    [IconName.Info]: <InfoIcon className={className} />,
    [IconName.InProgress]: <InProgressIcon className={className} />,
    [IconName.MidlineEllipsis]: <MidlineEllipsisIcon className={className} />,
    [IconName.Minus]: <MinusIcon className={className} />,
    [IconName.Mute]: <MuteIcon className={className} />,
    [IconName.NavigateRight]: <NavigateRightIcon className={className} />,
    [IconName.Pause]: <PauseIcon className={className} />,
    [IconName.Play]: <PlayIcon className={className} />,
    [IconName.Plus]: <PlusIcon className={className} />,
    [IconName.Publish]: <PublishIcon className={className} />,
    [IconName.QuickEdit]: <QuickEditIcon className={className} />,
    [IconName.QuickEditStation]: <QuickEditStationIcon className={className} />,
    [IconName.RemoveFilter]: <RemoveFilterIcon className={className} />,
    [IconName.Replace]: <ReplaceIcon className={className} />,
    [IconName.Retry]: <RetryIcon className={className} />,
    [IconName.Save]: <SaveIcon className={className} />,
    [IconName.Snapshot]: <SnapshotIcon className={className} />,
    [IconName.Stop]: <StopIcon className={className} />,
    [IconName.Success]: <SuccessIcon className={className} />,
    [IconName.Unarchive]: <UnarchiveIcon className={className} />,
    [IconName.Undo]: <UndoIcon className={className} />,
    [IconName.Unmute]: <UnmuteIcon className={className} />,
    [IconName.Unpublish]: <UnpublishIcon className={className} />,
    [IconName.Upload]: <UploadIcon className={className} />,
    [IconName.Warning]: <WarningIcon className={className} />,
    [IconName.X]: <XIcon className={className} />,
  };

  const actionIcon: JSX.Element =
    icon !== undefined ? (
      icons[icon]
    ) : (
      <svg
        className={clsx(classes.icons, className)}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
      ></svg>
    );

  return actionIcon;
};
