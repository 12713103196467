export const getTooltipText = (value: unknown): string | undefined => {
  const textValue = String(value);

  if (
    textValue === '[object Object]' ||
    textValue === 'undefined' ||
    textValue === 'null' ||
    textValue === ''
  ) {
    return undefined;
  } else {
    return textValue;
  }
};
