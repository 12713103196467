import { useField } from 'formik';
import React, { PropsWithChildren } from 'react';
import { Data } from '../../../types/data';
import { useFormikError } from '../useFormikError';
import {
  DynamicDataListControl,
  DynamicDataListControlProps,
} from './DynamicDataListControl';

/**
 * Renders a list of data that whose rows can be added, deleted, and repositioned
 * This is a wrapper for the DynamicDataListControl and meant to be used only within a form station
 * @example
 * <Field
 *  name="list"
 *  label="Control Label"
 *  columns={[{propertyName: 'id', size: '1fr', label: 'Id'}]}
 *  data={[{id: '1',desc: 'Description 1',title: 'Item 1'}]}
 *  as={DynamicDataListField}
 *  />
 */
export const DynamicDataListField = <T extends Data>(
  props: PropsWithChildren<
    Omit<DynamicDataListControlProps<T>, 'error' | 'onChange'>
  >,
): JSX.Element => {
  const error = useFormikError(props.name);
  const [field, , helpers] = useField(props.name);

  return (
    <DynamicDataListControl
      {...props}
      value={field.value}
      error={error}
      onChange={helpers.setValue}
    />
  );
};
