// import * as actions from './actions';
import {
  IdentityServiceClient,
  requiredPermissionsExists,
  TokenResponseCode,
  User,
} from '@axinom/mosaic-id-link';
import { PiralPlugin } from 'piral-core';
import { AuthApi } from './types';

/**
 * Creates new Pilet API extensions for enabling authentication support.
 */
export function createAuthApi(
  client: IdentityServiceClient,
): PiralPlugin<AuthApi> {
  const getToken = async (): Promise<User | undefined> => {
    const response = await client.getToken();
    return response.code === TokenResponseCode.SUCCESS
      ? response.user
      : undefined;
  };

  return (_context) => (_api, _target) => ({
    getToken,
    async checkPermission(requiredPermissions) {
      const userPermissions = (await getToken())?.token.permissions;
      if (!userPermissions) {
        // If the user doesn't have any permissions, the check will only be true if it requests no permissions.
        return Object.keys(requiredPermissions).length === 0;
      }
      return requiredPermissionsExists(requiredPermissions, userPermissions);
    },
  });
}
