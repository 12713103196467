import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { noop } from '../../helpers/utils';
import { DatePicker } from './DatePicker/DatePicker';
import classes from './DateTimePicker.scss';
import { TimePicker } from './TimePicker/TimePicker';

export interface DateTimePickerProps {
  /** Current value */
  value?: Date;

  /** Callback triggered when a new value is selected */
  onSelected?: (value: Date) => void;

  /** CSS Class name for additional styles */
  className?: string;

  /** Optional Styles */
  style?: React.CSSProperties;

  /** Whether the time picker should be shown as well. (default: true) */
  showTimePicker?: boolean;

  /** Whether the time picker should allow selecting seconds. (default: false) */
  showSeconds?: boolean;

  /** Whether the time picker should be showing in 12 hour format or 24 hour format. (default: false) */
  hours12?: boolean;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onSelected = noop,
  className,
  style = {},
  showTimePicker = true,
  showSeconds,
  hours12,
}) => {
  const [selectedDate, setSelectedDate] = useState<DateTime>(() => {
    const defaultDate = DateTime.fromObject({ hour: 0, minute: 0, second: 0 });
    return value ? DateTime.fromJSDate(value) : defaultDate;
  });
  return (
    <div
      className={clsx(
        classes.container,
        { [classes['timepicker']]: showTimePicker },
        'date-time-picker-container',
        className,
      )}
      style={style}
    >
      <DatePicker
        value={selectedDate}
        onSelected={(value) => {
          const { year, month, day } = value;
          const updatedDate = selectedDate.set({ year, month, day });

          setSelectedDate(updatedDate);
          onSelected(updatedDate.toJSDate());
        }}
      />
      {showTimePicker && (
        <TimePicker
          value={selectedDate}
          onSelected={(value) => {
            const { hour, minute, second } = value;
            const updatedDate = selectedDate.set({ hour, minute, second });

            setSelectedDate(updatedDate);
            onSelected(updatedDate.toJSDate());
          }}
          showSeconds={showSeconds}
          hours12={hours12}
        />
      )}
    </div>
  );
};
