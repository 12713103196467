import React, { useState } from 'react';
import { MessageBar, MessageBarProps } from '../../MessageBar/MessageBar';

export interface StationMessage {
  type: MessageBarProps['type'];
  title: MessageBarProps['title'];
  body?: React.ReactNode;
  canClose: boolean;
  onRetry?: MessageBarProps['onRetry'];
}

interface UseStationMessageReturnType {
  readonly StationMessage: JSX.Element;
  readonly stationMessage: StationMessage | undefined;
  readonly setStationMessage: React.Dispatch<
    React.SetStateAction<StationMessage | undefined>
  >;
}

export const useStationMessage = (): UseStationMessageReturnType => {
  const [stationMessage, setStationMessage] = useState<StationMessage>();

  const StationMessage = (
    <>
      {stationMessage && (
        <MessageBar
          type={stationMessage.type}
          title={String(stationMessage.title)}
          onClose={
            stationMessage.canClose
              ? () => setStationMessage(undefined)
              : undefined
          }
          onRetry={stationMessage.onRetry}
        >
          {stationMessage.body}
        </MessageBar>
      )}
    </>
  );

  return { stationMessage, setStationMessage, StationMessage } as const;
};
