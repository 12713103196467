import React, { PropsWithChildren } from 'react';
import { Column } from '..';
import { Data } from '../../../types/data';
import { Loader } from '../../Loaders/Loader/Loader';
import classes from './ListRow.scss';

export interface ListRowLoaderProps<T extends Data> {
  /** Spacing of columns */
  columnSizes: string;
  /** Space between columns */
  columnGap: string;
  /** List row height */
  rowHeight?: string;
  /** The column definition */
  columns: Column<T>[];
}

export const ListRowLoader = <T extends Data>({
  columnSizes,
  columnGap,
  rowHeight = '50px',
  columns,
}: PropsWithChildren<ListRowLoaderProps<T>>): JSX.Element => {
  const textLoaderHeight = 10;
  const rows = 5;

  const customRowStyles = {
    gridAutoRows: `minmax(50px, ${rowHeight})`,
    gridTemplateColumns: columnSizes,
    gridColumnGap: columnGap,
  } as React.CSSProperties;

  const rowElements = Array.from({ length: rows }, (v, k) => k).map((i) => (
    <div
      key={i}
      className={classes.columnsRoot}
      style={{
        ...customRowStyles,
        //opacity: (rows - i) / rows,
      }}
    >
      {columns.map((column) => {
        const loaderWidth = getSize(column.size);
        return (
          <div
            key={column.propertyName as string}
            className={classes.cell}
            style={{
              opacity:
                (rows -
                  i -
                  0.5) /* - 0.5 to make the loaders slightly more faint */ /
                rows,
            }}
          >
            <Loader
              showLoader={true}
              height={textLoaderHeight}
              width={loaderWidth}
              viewBox={`0 0 ${
                loaderWidth !== undefined ? loaderWidth : 100
              } ${textLoaderHeight}`}
            />
          </div>
        );
      })}
    </div>
  ));

  return <>{rowElements}</>;
};

/**
 * Extracts the numerical value from a pixel value
 * @param size column size
 */
const getSize = (size?: string): number | undefined => {
  return size !== undefined && size.includes('px')
    ? parseInt(size, 10)
    : undefined;
};
