import clsx from 'clsx';
import React from 'react';
import classes from './BooleanDotRenderer.scss';

/**
 * Renders a red or green color dot to represent a boolean value
 * To be used as `Column.render` value of the column containing the state.
 *
 * @example
 * <List
 *   {...otherProps}
 *   columns=[{
 *     propertyName: 'enabled'
 *     render: BooleanDotRenderer()
 * }]/>
 *
 */
export const BooleanDotRenderer = (val: unknown): JSX.Element => (
  <div
    className={clsx(classes.circle, val ? [classes.true] : [classes.false])}
  ></div>
);
