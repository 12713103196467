import { PiralPlugin } from 'piral-core';
import * as actions from './actions';
import { ProviderApi } from './types';

/**
 * Creates new Pilet API extension for registrations.
 */
export function createProviderExtensionApi(): PiralPlugin<ProviderApi> {
  return (context) => {
    context.defineActions(actions);

    context.dispatch((state) => ({
      ...state,
      registry: {
        ...state.registry,
        providers: {},
      },
    }));

    return () => ({
      addProvider: (type: string, data: object): void => {
        return context.addProvider(type, data);
      },
      getProviders: (type: string) => {
        return context.getProviders(type);
      },
    });
  };
}
