import clsx from 'clsx';
import React from 'react';
import { Auth, AuthProps } from './Auth/Auth';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { ExtensionSlotType } from './ExtensionSlotType';
import { Breadcrumb } from './Header.models';
import classes from './Header.module.scss';
import { Menu, MenuProps } from './Menu/Menu';

export interface HeaderProps {
  crumbs: Breadcrumb[];
  auth: AuthProps;
  className?: string;
  menu?: MenuProps;
  ExtensionSlot?: ExtensionSlotType;
}

/**
 * Displays user
 * @example
 * <Header
 *  crumbs={[{label: 'Movies', url: '/movies', params: []}]}
 *  auth={ userName: 'User Name', logout: () => null }>
 *  <div>CHILD ELEMENTS</div>
 * </Header>
 */
export const Header: React.FC<HeaderProps> = ({
  crumbs,
  auth,
  children,
  className = '',
  menu,
  ExtensionSlot,
}) => {
  return (
    <div className={clsx(classes.container, 'header-container', className)}>
      <Menu {...menu} />
      <Breadcrumbs crumbs={crumbs} />
      {children}
      <Auth {...auth} ExtensionSlot={ExtensionSlot} />
    </div>
  );
};
