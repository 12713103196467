import clsx from 'clsx';
import React from 'react';
import { FullProgressBar, ProgressBarProps } from './ProgressBar.model';
import classes from './ProgressBar.scss';

/**
 * Component for showing a progress bar.
 *
 * @example
 * <ProgressBar kind='simple'
 */
export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  kind,
  status,
  indeterminate,
  className = '',
  ...props
}) => {
  const kindClass = kind === 'full' ? classes.full : classes.simple;
  let statusClass = '';

  switch (status) {
    case 'Completed':
      statusClass = classes.completed;
      break;
    case 'Error':
      statusClass = classes.error;
      break;
    case 'Warning':
      statusClass = classes.warning;
      break;
  }

  return (
    <div
      className={clsx(
        classes.progressbar,
        kindClass,
        'progress-bar-container',
        className,
      )}
    >
      <div
        className={clsx(
          classes.progress,
          statusClass,
          indeterminate && classes.infinite,
        )}
        style={{
          width: indeterminate
            ? '50%'
            : `${
                progress === null || progress === undefined
                  ? 0
                  : progress < 0
                  ? 0
                  : progress > 100
                  ? 100
                  : progress
              }%`,
        }}
      />
      {kind === 'full' && (
        <div className={classes.text}>{(props as FullProgressBar).text}</div>
      )}
    </div>
  );
};
