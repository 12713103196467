import { PiralPlugin } from 'piral-core';
import * as actions from './actions';
import {
  NavigationResolver,
  NavigationResolverApi,
  NavigationResolverRegistryState,
  ResolverData,
} from './types';

export function createNavigationResolverApi(): PiralPlugin<NavigationResolverApi> {
  return (context) => {
    context.defineActions(actions);
    context.dispatch((state) => ({
      ...state,
      registry: {
        ...state.registry,
        navigationResolvers: {},
      },
    }));

    return () => {
      return {
        setRouteResolver: (station: never, resolver: NavigationResolver) => {
          context.setRouteResolver(station, resolver);
        },
        registerRouteResolver: (resolver: ResolverData) => {
          context.registerRouteResolver(resolver);
        },
        resolveRoute: (
          station: never,
          dynamicRouteSegments?: Record<string, string> | string,
        ) => {
          return context.resolveRoute(station, dynamicRouteSegments);
        },
        getAllRouteResolvers:
          (): NavigationResolverRegistryState['navigationResolvers'] => {
            return context.getAllRouteResolvers();
          },
      };
    };
  };
}
