import { SaveIndicatorType } from '@axinom/mosaic-shared-components';
import { PiralPlugin } from 'piral-core';
import * as actions from './actions';
import { IndicatorApi, IndicatorId } from './types';

export function createIndicatorApi(): PiralPlugin<IndicatorApi> {
  return (context) => {
    context.defineActions(actions);

    context.dispatch((state) => ({
      ...state,
      registry: {
        ...state.registry,
        indicator: {
          activeIndicators: [],
          lastIndicatorId: 0,
          saveIndicatorState: SaveIndicatorType.Inactive,
        },
      },
    }));

    return () => ({
      addIndicator: (content: React.ReactNode) => context.addIndicator(content),
      removeIndicator: (id: IndicatorId) => context.removeIndicator(id),
      updateIndicator: (id: IndicatorId, content: React.ReactNode) =>
        context.updateIndicator(id, content),
      setSaveIndicator: (type: SaveIndicatorType) =>
        context.setSaveIndicator(type),
    });
  };
}
