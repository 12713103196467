import { useHasPermissions } from '@axinom/mosaic-id-link';
import { useGlobalState } from 'piral-core';
import React, { useEffect, useState } from 'react';
import { NavigationConnectorProps, NavigationItem } from './types';

export function withNavigationState<TProps>(
  Component: React.ComponentType<TProps & NavigationConnectorProps>,
): React.FC<TProps> {
  const NavigationStateConnector: React.FC<TProps> = (props) => {
    const allItems = useGlobalState((state) => state.registry.navigation.items);
    const pages = useGlobalState((state) => state.registry.pages);
    const itemsTransformer = useGlobalState(
      (state) => state.registry.navigation.itemsTransformer,
    );
    const treeTransformer = useGlobalState(
      (state) => state.registry.navigation.treeTransformer,
    );

    const checkPermission = useHasPermissions();
    const [items, setItems] = useState<NavigationItem[]>([]);

    useEffect(() => {
      const fn = async (): Promise<void> => {
        const filteredItems: NavigationItem[] = [];
        const filteredParentItemNames: string[] = [];
        await Promise.all(
          allItems.map(async (el) => {
            const requiredPermissions = el.path
              ? pages[el.path]?.meta?.permissions
              : undefined;

            if (
              requiredPermissions === undefined ||
              (await checkPermission(requiredPermissions))
            ) {
              el.parentName && filteredParentItemNames.push(el.parentName);
              filteredItems.push(el);
            }
          }),
        );

        // remove empty grouping items (without path and child items)
        let i = filteredItems.length;
        while (i--) {
          const item = filteredItems[i];
          if (!item.path && filteredParentItemNames.indexOf(item.name) === -1) {
            filteredItems.splice(i, 1);
          }
        }

        setItems(filteredItems);
      };
      fn();
    }, [allItems, checkPermission, pages]);

    return (
      <Component
        items={items}
        itemsTransformer={itemsTransformer}
        treeTransformer={treeTransformer}
        {...props}
      />
    );
  };
  return NavigationStateConnector;
}
