import { SaveIndicator } from '@axinom/mosaic-shared-components';
import { useGlobalState } from 'piral-core';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classes from './Indicators.module.scss';

export const Indicators: React.FC = () => {
  return (
    <div className={classes.container}>
      <ActivityIndicatorWrapper />
      <SaveIndicatorWrapper />
    </div>
  );
};

const SaveIndicatorWrapper: React.FC = () => {
  const saveIndicatorState = useGlobalState(
    (s) => s.registry.indicator.saveIndicatorState,
  );

  // TODO: Provide Backward compatibility for the old save indicator.
  const location = useLocation();

  const [showIndicator, setShowIndicator] = React.useState(false);

  useEffect(() => {
    setShowIndicator(false);
  }, [location]);

  const mountingRef = React.useRef(true);

  useEffect(() => {
    if (mountingRef.current === true) {
      mountingRef.current = false;
      return;
    }
    setShowIndicator(true);
  }, [saveIndicatorState]);

  if (!showIndicator) {
    return null;
  }
  //--------------------------------------------

  return (
    <div className={classes.indicators}>
      <div
        className={classes.indicatorWrapper}
        style={{ scrollSnapAlign: 'end' }}
      >
        <SaveIndicator type={saveIndicatorState} />
      </div>
    </div>
  );
};

const ActivityIndicatorWrapper: React.FC = () => {
  const activeIndicators = useGlobalState(
    (s) => s.registry.indicator.activeIndicators,
  );

  return (
    <TransitionGroup className={classes.indicators}>
      {activeIndicators.map(({ id, content }) => (
        <CSSTransition
          key={id}
          timeout={200}
          classNames={{
            enter: classes.indicatorEnterActive,
            exit: classes.indicatorExitActive,
          }}
        >
          <div className={classes.indicatorWrapper}>{content}</div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};
