import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { print as gqlPrint } from 'graphql/language/printer';

/**
 * Takes a GQL DocumentNode and a set of variables
 * and compiles the body for a GQL request, stringifies and returns.
 *
 * @param gqlDocument
 * @param variables
 * @returns
 */
export const stringifyGqlQuery = <TResult, TVariables>(
  gqlDocument: DocumentNode<TResult, TVariables>,
  variables?: Record<string, unknown>,
): string => {
  const gqlQuery = {
    query: gqlPrint(gqlDocument),
    variables,
  };
  return JSON.stringify(gqlQuery);
};
