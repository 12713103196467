import React, { ChangeEvent } from 'react';
import { DynamicListDataEntryRenderer } from '../../../../DynamicDataList/DynamicDataList.model';
import { SingleLineText } from '../../../../FormElements';
import { CreateInputRendererConfig } from '../renderers.model';
import classes from './createInputRenderer.scss';

/**
 * Creates a simple component that can be used as an input field
 * @param Object configuration object of type CreateInputRendererConfig
 * @example createInputRenderer({placeholder: 'Enter Id'})
 */
export const createInputRenderer = ({
  id,
  name = '',
  placeholder,
  type = 'text',
  transform = (value: string) => value,
}: CreateInputRendererConfig = {}): DynamicListDataEntryRenderer => {
  const inputRenderer = (
    currentValue: unknown,
    error: string | undefined,
    onValueChange: (value: unknown) => void,
    disabled = false,
  ): React.ReactNode => {
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
      onValueChange(transform(e.target.value)); // emit onChange with transformed value
    };

    return (
      <SingleLineText
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        placeholder={disabled ? undefined : placeholder}
        onChange={onChangeHandler}
        inlineMode={true}
        error={error}
        className={classes.container}
        value={(currentValue as string) ?? ''}
      />
    );
  };

  return inputRenderer;
};
