import { PortalConfiguration } from '@axinom/mosaic-core';
import * as React from 'react';
import { useCallback } from 'react';
import classes from './PortalConfigInput.module.scss';

export const PortalConfigInput: React.FC<{
  applyLocalPortalConfig: (config: PortalConfiguration) => void;
}> = ({ applyLocalPortalConfig }) => {
  // The value can be set manually by the user during local development. If there's no local access
  // to the id-service, then an id-proxy must be started on the localhost to ensure cookies can be created
  // in the same domain.
  const [idServiceProxy, setIdServiceProxy] = React.useState<string>(
    process.env.ID_SERVICE_AUTH_BASE_URL ?? '',
  );
  const [tenantId, setTenantId] = React.useState<string>('');
  const [environmentId, setEnvironmentId] = React.useState<string | null>(null);

  // This value is not used during local development due to pilet-debug mocking the pilet feed endpoint,
  // and only added here for the type consistency. But it needs to match a valid URL format.
  const microFrontendServiceEndpoint =
    process.env.MICRO_FRONTEND_SERVICE_BASE_URL ?? 'http://localhost:10000';

  // Handler method for saving local development portal config to browser storage
  const handleSaveConfig = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();

      const newLocalPortalConfig: PortalConfiguration = {
        tenantId: tenantId as string,
        environmentId: environmentId as string,
        microFrontendServiceEndpoint,
        idServiceAuthEndpoint: idServiceProxy,
      };

      applyLocalPortalConfig(newLocalPortalConfig);
    },
    [
      tenantId,
      environmentId,
      microFrontendServiceEndpoint,
      idServiceProxy,
      applyLocalPortalConfig,
    ],
  );

  return (
    <div className={classes.container}>
      <div className={classes.configBox}>
        <form
          onSubmit={handleSaveConfig}
          data-test-id="portal-config-input-page"
        >
          <input
            className={classes.input}
            type="text"
            placeholder="Identity Service Proxy URL"
            required
            value={idServiceProxy}
            onChange={(event) => setIdServiceProxy(event.target.value)}
          />

          <input
            className={classes.input}
            type="text"
            placeholder="Tenant ID"
            required
            onChange={(event) => setTenantId(event.target.value)}
            data-test-id="tenant-id"
          />

          <input
            className={classes.input}
            type="text"
            placeholder="Environment ID"
            required
            onChange={(event) => setEnvironmentId(event.target.value)}
            data-test-id="environment-id"
          />

          <input
            className={classes.inputButton}
            type="submit"
            value="Save Config"
            data-test-id="save-config"
          />

          <p className={classes.disclaimer}>
            * This configuration will be persisted in the browser&apos;s
            local-storage. This page will be visible again only upon clearing
            the local-storage for this site.
          </p>
        </form>
      </div>
    </div>
  );
};
