import React from 'react';
import { useHistory } from 'react-router-dom';

export enum SaveIndicatorType {
  Saving = 'saving',
  Inactive = 'inactive',
  Dirty = 'dirty',
}

interface SaveIndicatorProps {
  type: SaveIndicatorType;
  dirtyColor?: string;
}

export const SaveIndicator: React.FC<SaveIndicatorProps> = ({
  type,
  dirtyColor,
}) => {
  const history = useHistory();

  const colorMap = {
    [SaveIndicatorType.Saving]: '#95C842',
    [SaveIndicatorType.Inactive]: '#EEEEEE',
    [SaveIndicatorType.Dirty]: dirtyColor ?? '#1478af',
  };

  return (
    <div
      title={
        type === SaveIndicatorType.Dirty
          ? 'Unsaved changes'
          : type === SaveIndicatorType.Saving
          ? 'Saving...'
          : undefined
      }
      onClick={
        type === SaveIndicatorType.Dirty
          ? () => history.replace(history.location.pathname)
          : undefined
      }
      style={{
        cursor: type === SaveIndicatorType.Dirty ? 'pointer' : 'default',
        display: 'grid',
      }}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 35"
        style={{
          height: '40px',
        }}
      >
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke={colorMap[type]}
          strokeWidth="2"
          d="M15.8,30.1c0,0-6.2,0.1-7.6-0.1c-3.3-0.4-5.8-3.3-5.6-6.7c-0.2-6,6.9-6.6,6.9-6.6
	s-0.7-6.4,5-7.5c3.2-0.8,6.5,0.8,7.8,3.8c1.5-0.6,3.2-0.6,4.7,0.2c1.3,0.8,2.2,2.1,2.4,3.6c0,0,8-0.5,8.1,6.6c0,7.1-6.7,6.7-6.7,6.7
	h-6.5"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke={colorMap[type]}
          strokeWidth="2"
          d="M20.1,33V19.4 M15.7,23.8l4.3-4.3l4.3,4.3"
        >
          {type === SaveIndicatorType.Saving && (
            <animate
              attributeType="XML"
              attributeName="opacity"
              keyTimes="0;0.2;0.5;0.8;1"
              values="0;0.4;0.8;0.4;0"
              dur="1.5s"
              repeatCount="indefinite"
            />
          )}
        </path>
      </svg>
      <span
        style={{
          color: colorMap[type],
          fontSize: '12px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {type === SaveIndicatorType.Saving ? 'saving..' : 'Save'}
      </span>
    </div>
  );
};
