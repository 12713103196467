import clsx from 'clsx';
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { assertNever } from '../../../helpers/utils';
import { Data } from '../../../types/data';
import { Button, ButtonContext } from '../../Buttons';
import { IconName } from '../../Icons';
import { formatDate, formatDateTime } from '../../Utils/Transformers/DateTime';
import { FilterType, FilterTypes, FilterValue } from '../Filters.model';
import { DateTimeFilter } from '../SelectionTypes/DateTimeFilter/DateTimeFilter';
import { FreeTextFilter } from '../SelectionTypes/FreeTextFilter/FreeTextFilter';
import { MultiOptionsFilter } from '../SelectionTypes/MultiOptionFilter/MultiOptionFilter';
import { NumericTextFilter } from '../SelectionTypes/NumericTextFilter/NumericTextFilter';
import { OptionsFilter } from '../SelectionTypes/OptionsFilter/OptionsFilter';
import { SearcheableOptionsFilter } from '../SelectionTypes/SearcheableOptionsFilter/SearcheableOptionsFilter';
import classes from './Filter.scss';

export interface FilterProps<T extends Data> {
  selectOnFocus?: boolean;
  options: FilterType<T>;
  value?: FilterValue;
  index?: number;
  isActive: boolean;
  className?: string;
  selectedValueRenderer?: (value: FilterValue) => string;
  onFilterChange: (prop: keyof T, value: FilterValue, index: number) => void;
  onValidate?: (currentValue: FilterValue) => string | null | undefined;
  onFilterClicked: () => void;
}

export const Filter = <T extends Data>({
  options,
  value,
  index = -1,
  isActive,
  className = '',
  selectedValueRenderer,
  onFilterChange,
  onFilterClicked,
  onValidate,
  selectOnFocus = true,
}: PropsWithChildren<FilterProps<T>>): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [stringValue, setStringValue] = useState<string | undefined>();

  const contentRef = useRef<HTMLDivElement>(null);
  const valueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setContentHeight(
      (contentRef.current?.scrollHeight ?? 0) +
        (valueRef.current?.scrollHeight ?? 0),
    );
  }, [isExpanded, hasError, value]);

  const onFilterValueChange = (
    prop: keyof T,
    value: FilterValue,
    stringValue?: string,
  ): void => {
    onFilterChange(prop, value, index);
    setIsExpanded(false);
    setHasError(false);
    setStringValue(stringValue);
  };

  const onError = (): void => {
    setHasError(true);
  };

  const renderValue = (value: unknown): ReactNode => {
    if (selectedValueRenderer !== undefined) {
      return selectedValueRenderer(value);
    }

    switch (options.type) {
      case FilterTypes.Date:
        return formatDate(String(value));
      case FilterTypes.DateTime:
        return formatDateTime(String(value));
      case FilterTypes.Options:
        return options.options.find((option) => option.value === value)?.label;
      case FilterTypes.MultipleOptions: {
        const selectedVal: string[] = [];
        String(value)
          .split(',')
          .map((item: string) =>
            selectedVal.push(
              options?.options.find(
                (option) => option.value.toString() === item,
              )?.label ?? '',
            ),
          );
        return selectedVal.join(', ');
      }
      case FilterTypes.SearcheableOptions:
        return stringValue;
      default:
        return stringValue ?? String(value);
    }
  };

  const renderFilterContent = (): JSX.Element => {
    switch (options.type) {
      case FilterTypes.FreeText:
        return (
          <FreeTextFilter
            value={value}
            onSelect={(value: FilterValue) =>
              onFilterValueChange(options.property, value)
            }
            onError={onError}
            onValidate={onValidate}
            selectOnFocus={selectOnFocus}
          />
        );

      case FilterTypes.Numeric:
        return (
          <NumericTextFilter
            value={value as number}
            onSelect={(value: FilterValue) =>
              onFilterValueChange(options.property, value)
            }
            onError={onError}
            onValidate={onValidate}
          />
        );

      case FilterTypes.Options:
        return (
          <OptionsFilter
            value={value as string}
            options={options.options}
            onSelect={(value: FilterValue) =>
              onFilterValueChange(options.property, value)
            }
          />
        );

      case FilterTypes.MultipleOptions:
        return (
          <MultiOptionsFilter
            value={value as string}
            options={options.options}
            onSelect={(value: FilterValue) =>
              onFilterValueChange(options.property, value?.toString())
            }
          />
        );
      case FilterTypes.SearcheableOptions:
        return (
          <SearcheableOptionsFilter
            value={value as string}
            optionsProvider={options.optionsProvider}
            searchInputPlaceholder={options.searchInputPlaceholder}
            onSelect={(value: FilterValue, stringValue?: string) =>
              onFilterValueChange(options.property, value, stringValue)
            }
            maxItems={options.maxItems}
          />
        );

      case FilterTypes.Date:
        return (
          <DateTimeFilter
            value={value as string}
            onSelect={(value: FilterValue) =>
              onFilterValueChange(options.property, value)
            }
            modifyTime={false}
            onError={onError}
            onValidate={onValidate}
          />
        );

      case FilterTypes.DateTime:
        return (
          <DateTimeFilter
            value={value as string}
            onSelect={(value: FilterValue) =>
              onFilterValueChange(options.property, value)
            }
            modifyTime={true}
            onError={onError}
            onValidate={onValidate}
          />
        );

      case FilterTypes.Custom:
        return (
          <options.component
            value={value}
            active={isActive}
            onSelect={(value: FilterValue, stringValue?: string) => {
              onFilterValueChange(options.property, value, stringValue);
            }}
            onError={onError}
            onValidate={onValidate}
          />
        );

      default:
        //Exhaustiveness check
        throw assertNever(options);
    }
  };

  return (
    <div
      className={clsx(classes.container, 'filter-container', className)}
      data-test-id={`filter:${options.property as string}`}
      data-test-type={FilterTypes[options.type]}
      onClick={() => onFilterClicked()}
    >
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
          setHasError(false);
        }}
        className={clsx(
          classes.title,
          { [classes.expanded]: isExpanded && isActive },
          hasError && classes.hasError,
          !!value && classes.active,
        )}
        data-test-id="filter-button-toggle"
      >
        <span data-test-id="filter-label">{options.label}</span>
        <Button
          icon={IconName.ChevronDown}
          className={clsx(classes.button)}
          buttonContext={ButtonContext.None}
        />
      </div>
      <div
        className={clsx(classes.content)}
        style={{
          maxHeight: contentHeight,
        }}
      >
        {value !== undefined && !isExpanded && (
          <div
            className={clsx(classes.selectedValue)}
            ref={valueRef}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <span data-test-id="filter-value">{renderValue(value)}</span>
            <Button
              icon={IconName.RemoveFilter}
              className={clsx(classes.button)}
              dataTestId="filter-button-clear"
              buttonContext={ButtonContext.None}
              onButtonClicked={(e) => {
                e.stopPropagation();
                onFilterValueChange(options.property, undefined);
              }}
            />
          </div>
        )}
        {isExpanded && (
          <div
            ref={contentRef}
            data-test-id="filter-content"
            className={clsx({ [classes.active]: isActive })}
          >
            {renderFilterContent()}
          </div>
        )}
      </div>
    </div>
  );
};
