import { useState } from 'react';
import { Data } from '../../types';
import { ListItem, SortData } from './List.model';

export const isTrigger = function <T extends Data>(
  index: number,
  listItems: ListItem<T>[],
  loadingTriggerOffset: number,
): boolean {
  return listItems.length - loadingTriggerOffset === index + 1 ? true : false;
};

export const useSort = <T>(
  defaultSortOrder: SortData<T> | undefined,
  onSortChanged: (sort: SortData<T>) => void,
): {
  readonly sort: SortData<T> | undefined;
  readonly sortChangedHandler: (sort: SortData<T>) => void;
} => {
  const [sort, setSort] = useState<SortData<T> | undefined>(defaultSortOrder);

  const sortChangedHandler = (sort: SortData<T>): void => {
    setSort(sort);
    onSortChanged && onSortChanged(sort);
  };

  return { sort, sortChangedHandler } as const;
};

export function getActionButtonVisibility<T>(
  data: T,
  showActionButton: boolean | ((item: T) => boolean),
): boolean {
  if (typeof showActionButton === 'boolean') {
    return showActionButton;
  }

  return showActionButton(data);
}
