import { MessageBar } from '@axinom/mosaic-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './NotFoundStation.module.scss';

export const NotFoundStation: React.FC = () => {
  return (
    <div className={classes.container}>
      <MessageBar type="error" title="Page Not Found" />
      <div className={classes.wrapper}>
        <h1>404</h1>
        <p className={classes.body}>
          Sorry, we cannot find the page that you are looking for 😧
          <br />
          {'Back to '}
          <Link to="/" className={classes.link}>
            Home Page
          </Link>
        </p>
      </div>
    </div>
  );
};
