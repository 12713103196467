import clsx from 'clsx';
import React from 'react';
import { Option } from '../../Filters.model';
import classes from './OptionsFilter.scss';

export interface OptionFilterProps {
  value?: string;

  /** Array of Options to be displayed */
  options: Option[];

  /** Callback triggered when a new filter value is selected */
  onSelect: (text: string) => void;

  /** CSS Class name for additional styles */
  className?: string;
}

export const OptionsFilter: React.FC<OptionFilterProps> = ({
  value,
  options,
  onSelect,
  className = '',
}) => {
  return (
    <div
      className={clsx(classes.container, 'options-filter-container', className)}
    >
      {options?.map((option: Option) => (
        <div
          className={clsx(classes.option)}
          onClick={() => onSelect(option.value)}
          key={option.label}
          data-test-value={option.value as string}
        >
          <span
            className={clsx(value === option.value && classes.selected)}
            data-test-id="label"
          >
            {option.label}
          </span>
        </div>
      ))}
    </div>
  );
};
