import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { TileProps } from './Tile.model';
import classes from './Tile.scss';

// TODO: Support for background colors and hover effect
// TODO: Refactor all tile type components.

/**
 * Renders a tile used in Hub component
 * @example
 * <TileSmall
 *  path={'/liveevents'}
 *  label={'Live Events'}
 *  icon={'/images/default_image.svg'}
 * />
 */
export const Tile: React.FC<TileProps> = ({
  path,
  label,
  subLabel,
  icon,
  disabled,
}) => {
  return (
    <Link
      className={clsx(classes.tile, disabled && classes.disabled)}
      to={{
        pathname: disabled ? undefined : path,
      }}
    >
      <div className={classes.icon}>
        {React.isValidElement(icon)
          ? icon
          : typeof icon === 'string' && (
              <img src={icon} alt={`${label} icon`} />
            )}
      </div>
      <div className={classes.label}>
        <span data-test-id="tile-label">{label}</span>
        <span>{subLabel}</span>
      </div>
    </Link>
  );
};
