import { useEffect, useRef, useState } from 'react';

export const useElementWidthObserver = <T extends HTMLElement>(): {
  width: number;
  ref: React.RefObject<T>;
} => {
  const [width, setWidth] = useState(0);
  const ref = useRef<T>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    const current = ref.current;

    if (current) {
      observer.observe(current);
    }

    return () => {
      current && observer.unobserve(current);
    };
  }, []);

  return {
    width,
    ref,
  };
};
