import { PiletApi, PiralPlugin } from 'piral-core';
import { GetDataCallback, RegistrationInfoApi } from './types';

/**
 * Creates new Pilet API extensions for querying Pilet registration info
 */
export function createRegistrationInfoApi(): PiralPlugin<RegistrationInfoApi> {
  return (context) => (api, _target) => ({
    getRegisteredPages() {
      return context.readState((state) => state.registry.pages);
    },
    getRegisteredExtensions() {
      return context.readState((state) => state.registry.extensions);
    },
    getRegisteredPilets() {
      return context.readState((state) => state.modules);
    },
    async getDataHelper<TData, TReturn>(
      dataKey: string,
      callback: GetDataCallback<TData, TReturn>,
    ): Promise<TReturn> {
      const data = await retreiveData<TData>(api, dataKey);
      return callback(data);
    },
  });
}

const retreiveData = <T>(api, dataKey: string): Promise<T> => {
  const data = api.getData(dataKey);
  return data ? Promise.resolve(data) : waitForPiralData(api, dataKey);
};

const waitForPiralData = <T>(
  app: PiletApi,
  dataStoreKey: string,
): Promise<T> => {
  return new Promise((resolve) => {
    const onStoreDataUpdate = ({ name, value }): void => {
      if (name === dataStoreKey) {
        resolve(value);
        app.off('store-data', onStoreDataUpdate);
      }
    };
    app.on('store-data', onStoreDataUpdate);
  });
};
