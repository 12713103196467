/* eslint-disable no-console */
import { PortalConfiguration } from '@axinom/mosaic-core';

export const fetchDevConfig = async (): Promise<
  PortalConfiguration | undefined
> => {
  try {
    return await (await fetch('/$config')).json();
  } catch (e) {
    return undefined;
  }
};
