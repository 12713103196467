import { useField } from 'formik';
import React from 'react';
import { useFormikError } from '../useFormikError';
import { DateTimeText, DateTimeTextProps } from './DateTimeText';
/**
 * This component should be used to render a DateTimeTextField inside a Formik form.
 *
 * @example
 * <Field name="title" label="Title" as={DateTimeTextField} />
 */
export const DateTimeTextField: React.FC<
  Omit<DateTimeTextProps, 'error' | 'onChange'>
> = (props) => {
  const error = useFormikError(props.name);
  const [, meta, helpers] = useField(props.name);

  const handleChange = (value: string | null, valid?: boolean): void => {
    if (!valid) {
      helpers.setError('Invalid Date');
      helpers.setValue(value, false);
    } else {
      helpers.setValue(value);
    }

    if (value === meta.initialValue) {
      helpers.setTouched(false);
    }
  };

  return <DateTimeText {...props} error={error} onChange={handleChange} />;
};
