import React from 'react';
import { ReactTabsFunctionComponent, TabList, TabListProps } from 'react-tabs';
import classes from './CustomTabList.scss';
import { ScrollContainer } from './ScrollContainer';

export const CustomTabList: ReactTabsFunctionComponent<TabListProps> = ({
  children,
  ...otherProps
}) => (
  <TabList {...otherProps} className={classes.tablist}>
    <ScrollContainer>{children}</ScrollContainer>
  </TabList>
);

CustomTabList.tabsRole = 'TabList';
