import { PiralPlugin } from 'piral-core';
import { TitleApi } from './types';

const SUFFIX = 'Axinom Mosaic';
let currentTitle: string | undefined = undefined;

export function createTitleApi(): PiralPlugin<TitleApi> {
  return (context) => {
    context.navigation.listen(() => {
      window.document.title = SUFFIX;
    });

    return () => {
      return {
        setTitle: (content?: string) => {
          if (content !== currentTitle) {
            if (content && content.trim().length > 0) {
              window.document.title = content + ' • ' + SUFFIX;
            } else {
              window.document.title = SUFFIX;
            }

            currentTitle = content;
          }
        },
      };
    };
  };
}
