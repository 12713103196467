import {
  IconName,
  Icons,
  NotificationBody,
  NotificationType,
} from '@axinom/mosaic-ui';
import clsx from 'clsx';
import React from 'react';
import classes from './ToastNotification.module.scss';

enum IconNameMapping {
  info = IconName.Info,
  success = IconName.Success,
  warning = IconName.Warning,
  error = IconName.Error,
}

export const ToastNotification: React.FC<{
  title: string;
  closeToast?: () => void;
  body?: NotificationBody;
  type?: NotificationType;
}> = ({ title, body, type = 'info', closeToast }) => {
  return (
    <div className={classes.container} data-test-id="toast-notification">
      <div className={clsx(classes.header, classes[type])}>
        <div className={classes.typeIcon}>
          <Icons
            className={classes.icon}
            icon={IconNameMapping[type] as unknown as IconName}
          />
        </div>
        <h1 className={classes.title} data-test-id="toast-title">
          {title}
        </h1>

        <button
          className={classes.close}
          type="button"
          onClick={closeToast}
          data-test-id="toast-close"
        >
          <Icons icon={IconName.X} />
        </button>
      </div>
      {body && (
        <div className={classes.body} data-test-id="toast-body">
          {body}
        </div>
      )}
    </div>
  );
};
