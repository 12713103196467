import { Data } from '../../../../types';

export interface DynamicListReducerInitArgs<T extends Data> {
  initialValue: T[];
  positionPropertyName: keyof T | undefined;
  maxItemLimit: number | undefined;
  allowNewData: boolean;
}

export enum DynamicListReducerActionType {
  Add = 'add',
  Remove = 'remove',
  Update = 'update',
  Reset = 'reset',
}

export type DynamicListReducerAction<T extends Data> =
  | DynamicListReducerAddAction<T>
  | DynamicListReducerRemoveAction<T>
  | DynamicListReducerUpdateAction<T>
  | DynamicListReducerResetAction<T>;

interface DynamicListReducerAddAction<T extends Data> {
  type: DynamicListReducerActionType.Add;
  item: T;
}

interface DynamicListReducerRemoveAction<T extends Data> {
  type: DynamicListReducerActionType.Remove;
  item: T;
}

interface DynamicListReducerUpdateAction<T extends Data> {
  type: DynamicListReducerActionType.Update;
  currentItem: T;
  newItem: T;
}

interface DynamicListReducerResetAction<T extends Data> {
  type: DynamicListReducerActionType.Reset;
  items: T[];
  allowNewData: boolean;
  maxItemLimit: number | undefined;
  positionPropertyName: keyof T | undefined;
}

export interface DynamicListReducerState<T extends Data> {
  items: T[];
  nextPosition: number;
  canAddItems: boolean;
  positionPropertyName: keyof T | undefined;
  maxItemLimit: number | undefined;
  allowNewData: boolean;
}
