import React from 'react';
import configWarning from '../../resources/images/configuration_warning_icon.svg';
import { Message } from '../Message';
import classes from './EnvironmentInactiveError.module.scss';

export const EnvironmentInactiveError: React.FC = () => (
  <>
    <Message type="warning" title={'The environment is not enabled for use'} />
    <img src={configWarning} className={classes.centerImage} />
    <p className={classes.center}>
      Please check environment configuration and try again.
    </p>
  </>
);
