import { User } from '@axinom/mosaic-core';
import { Button, ButtonContext, IconName, useExpand } from '@axinom/mosaic-ui';
import clsx from 'clsx';
import React from 'react';
import { ExtensionSlotType } from '../ExtensionSlotType';
import classes from './Auth.module.scss';

export interface AuthProps {
  user?: User;
  logout?: () => unknown;
  className?: string;
  ExtensionSlot?: ExtensionSlotType;
}

/**
 * Displays user
 */
export const Auth: React.FC<AuthProps> = ({
  user,
  logout,
  className,
  ExtensionSlot,
}) => {
  const { isExpanded, expand, collapse } = useExpand();

  return (
    <div
      className={clsx(classes.container, 'auth-container', className)}
      onMouseDown={expand}
      onMouseLeave={collapse}
      data-test-id="header-auth"
    >
      <div
        className={clsx(classes.profileContainer)}
        title={`${user?.name} (${user?.email ?? ''})`}
      >
        <span className={clsx(classes.userName)}>{user?.name}</span>
        <Button
          icon={IconName.ChevronDown}
          className={classes.button}
          buttonContext={ButtonContext.None}
        />
        {isExpanded && (
          <div className={clsx(classes.menu)}>
            <button className={clsx(classes.logoutBtn)} onClick={logout}>
              Logout
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  fill="none"
                  strokeWidth="2"
                  d="M26,37.9H2V2.1h24 M28.6,12.2l9.4,7.8l-9.4,8.6 M38,20.1H14.5"
                ></path>
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className={clsx(classes.logoContainer)}>
        <div className={clsx(classes.logo)}>
          {ExtensionSlot ? (
            <ExtensionSlot
              name="logo"
              empty={() => mosaicLogo}
              render={(children) => children[0] as React.ReactElement}
            />
          ) : (
            mosaicLogo
          )}
        </div>
      </div>
    </div>
  );
};

const mosaicLogo = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 120">
    <path
      fill="#EA5542"
      d="M59.4,119.4c-4.1,0-8.2-1-11.8-2.9L13.9,99.1C5.4,94.7,0,85.9,0,76.3V43.7
c0-9.6,5.4-18.5,13.9-22.9L47.6,3.4c7.4-3.8,16.2-3.8,23.6,0l33.7,17.4c8.6,4.4,13.9,13.2,13.9,22.9v32.6c0,9.6-5.4,18.5-13.9,22.9
l-33.7,17.4C67.6,118.4,63.5,119.4,59.4,119.4 M27.2,75.4L59.4,92l32.2-16.6V44.6L59.4,28L27.2,44.6V75.4z M60.1,27.6L60.1,27.6
L60.1,27.6"
    />
    <path
      fill="#FEBC12"
      d="M59.4,114.9c-3.4,0-6.7-0.8-9.7-2.4L16,95.1C9,91.5,4.5,84.2,4.5,76.3V43.7C4.5,35.8,9,28.5,16,24.9L49.7,7.5
c6.1-3.1,13.3-3.1,19.4,0l33.7,17.4c7.1,3.6,11.5,10.9,11.5,18.8v32.6c0,7.9-4.4,15.2-11.5,18.8l-33.7,17.4
C66.1,114.1,62.8,114.9,59.4,114.9"
    />
    <path
      fill="#1099D6"
      d="M59.4,110.3c-2.7,0-5.3-0.6-7.6-1.9L18.1,91.1c-5.5-2.8-9-8.6-9-14.8V43.7c0-6.2,3.5-11.9,9-14.8
l33.7-17.4c4.8-2.5,10.5-2.5,15.3,0l33.7,17.4c5.5,2.8,9,8.6,9,14.8v32.6c0,6.2-3.5,11.9-9,14.8l-33.7,17.4
C64.7,109.7,62.1,110.3,59.4,110.3 M55.9,19.6L22.3,37c-2.5,1.3-4.1,3.9-4.1,6.7v32.6c0,2.8,1.6,5.4,4.1,6.7L56,100.4
c2.2,1.1,4.8,1.1,6.9,0L96.6,83c2.5-1.3,4.1-3.9,4.1-6.7V43.7c0-2.8-1.6-5.4-4.1-6.7L62.9,19.6C60.7,18.5,58.1,18.5,55.9,19.6"
    />
    <path
      fill="#1C3A69"
      d="M53.9,15.5c3.5-1.8,7.6-1.8,11.1,0L98.7,33c4,2.1,6.6,6.2,6.6,10.8v32.6c0,4.5-2.5,8.7-6.6,10.8
L65,104.5c-3.5,1.8-7.6,1.8-11.1,0L20.2,87c-4-2.1-6.6-6.2-6.6-10.8V43.7c0-4.5,2.5-8.7,6.6-10.8L53.9,15.5z"
    />
    <path
      fill="#1099D6"
      d="M36.8,62c0.9-0.5,1.9-0.5,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
c-0.9,0.5-1.9,0.5-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L36.8,62z"
    />
    <path
      fill="#1C3A69"
      d="M38.2,80.7c-0.7,0-1.3-0.2-1.9-0.5l-6-3.1C28.9,76.4,28,75,28,73.5v-5.7c0-1.6,0.9-3,2.2-3.7
l6-3.1c1.2-0.6,2.6-0.6,3.8,0l6,3.1c1.4,0.7,2.2,2.1,2.2,3.7v5.7c0,1.6-0.9,3-2.2,3.7l-6,3.1C39.5,80.6,38.9,80.7,38.2,80.7
M38.2,62.8c-0.3,0-0.6,0.1-0.9,0.2l0,0l-6,3.1c-0.6,0.3-1,1-1,1.7v5.7c0,0.7,0.4,1.4,1,1.7l6,3.1c0.5,0.3,1.2,0.3,1.7,0l6-3.1
c0.6-0.3,1-1,1-1.7v-5.7c0-0.7-0.4-1.4-1-1.7L39,63C38.8,62.8,38.5,62.8,38.2,62.8"
    />
    <path
      fill="#FEBC12"
      d="M36.8,51.3c0.9-0.4,1.9-0.4,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
c-0.9,0.5-1.9,0.5-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L36.8,51.3z"
    />
    <path
      fill="#EA5542"
      d="M36.8,40.7c0.9-0.4,1.9-0.4,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
c-0.9,0.4-1.9,0.4-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L36.8,40.7z"
    />
    <path
      fill="#EA5542"
      d="M82,62c-0.9-0.5-1.9-0.5-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7l6,3.1
c0.9,0.5,1.9,0.5,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L82,62z"
    />
    <path
      fill="#FEBC12"
      d="M82,51.3c-0.9-0.4-1.9-0.4-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7l6,3.1
c0.9,0.5,1.9,0.5,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L82,51.3z"
    />
    <path
      fill="#1099D6"
      d="M82,40.7c-0.9-0.4-1.9-0.4-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7l6,3.1
c0.9,0.4,1.9,0.4,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L82,40.7z"
    />
    <path
      fill="#1099D6"
      d="M47.4,46.1c0.9-0.5,1.9-0.5,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
c-0.9,0.5-1.9,0.5-2.8,0l-6.1-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L47.4,46.1z"
    />
    <path
      fill="#EA5542"
      d="M71.4,46.1c-0.9-0.5-1.9-0.5-2.8,0l-6,3.1c-1,0.5-1.6,1.6-1.6,2.7v5.7c0,1.1,0.6,2.2,1.6,2.7
l6,3.1c0.9,0.5,1.9,0.5,2.8,0l6-3.1c1-0.5,1.6-1.6,1.6-2.7v-5.7c0-1.1-0.6-2.2-1.6-2.7L71.4,46.1z"
    />
    <path
      fill="#FEBC12"
      d="M58,51.7c0.9-0.4,1.9-0.4,2.8,0l6,3.1c1,0.5,1.6,1.6,1.6,2.7v5.7c0,1.1-0.6,2.2-1.6,2.7l-6,3.1
c-0.9,0.4-1.9,0.4-2.8,0l-6-3.1c-1-0.5-1.6-1.6-1.6-2.7v-5.7c0-1.1,0.6-2.2,1.6-2.7L58,51.7z"
    />
    <path
      fill="#1C3A69"
      d="M133.2,48.2c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.2,0.2-0.4l0.8-1.6h4l0.8,1.6c0.1,0.2,0.1,0.3,0.2,0.4
c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.1,0.8-0.2c0.5-0.2,0.8-0.6,0.8-0.9c0-0.2-0.1-0.5-0.2-0.7l-3.9-8.1
c-0.2-0.5-0.6-0.8-1.4-0.8c-0.5,0-1,0.3-1.2,0.7l-3.9,8.2c-0.1,0.2-0.2,0.4-0.2,0.7s0.2,0.6,0.8,0.9c0.3,0.1,0.5,0.2,0.8,0.2
C132.9,48.3,133.1,48.3,133.2,48.2z M136.7,41.9l0.9,2h-1.9L136.7,41.9z"
    />
    <path
      fill="#1C3A69"
      d="M143.6,47c0,0.3,0.2,0.6,0.6,0.9c0.2,0.3,0.5,0.4,0.9,0.5c0.3,0,0.6-0.2,0.9-0.6l2.8-3.2l2.8,3.2
c0.2,0.3,0.5,0.5,0.9,0.6c0.3,0,0.7-0.2,0.9-0.5c0.3-0.2,0.5-0.5,0.6-0.9c-0.1-0.3-0.2-0.7-0.5-0.9l-2.9-3.2l3-3.2
c0.3-0.2,0.5-0.5,0.5-0.9c-0.1-0.4-0.3-0.7-0.6-0.9c-0.2-0.3-0.5-0.4-0.9-0.5c-0.3,0-0.5,0.2-0.9,0.6l-2.8,3.2l-2.8-3.2
c-0.2-0.3-0.5-0.5-0.9-0.6c-0.4,0-0.8,0.2-1,0.5c-0.3,0.2-0.5,0.5-0.6,0.9c0,0.3,0.2,0.7,0.5,0.9l2.9,3.2l-2.9,3.2
C143.8,46.3,143.7,46.6,143.6,47z"
    />
    <path
      fill="#1C3A69"
      d="M157.3,48.3c0.6,0,1-0.1,1.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3,0-0.5v-8.1c0-0.3,0-0.6-0.1-0.8
c-0.1-0.3-0.5-0.5-1.2-0.5c-0.6,0-1,0.1-1.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0,0.5V47c0,0.2,0,0.5,0.1,0.7
C156.2,48.1,156.6,48.3,157.3,48.3z"
    />
    <path
      fill="#1C3A69"
      d="M163.8,48c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3,0-0.5v-4.8c0.7,0.9,2.1,2.8,4.3,5.7c0.1,0.1,0.3,0.2,0.4,0.3
c0.3,0.1,0.5,0.1,0.8,0.1c0.6,0,1-0.1,1.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3,0-0.5V39c0-0.2,0-0.5-0.1-0.7
c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.5-0.1-0.7-0.1s-0.5,0-0.7,0.1c-0.2,0-0.3,0.1-0.4,0.3
c-0.1,0.2-0.2,0.5,0.1,0.9v4.9c-0.7-0.9-2.1-2.7-4.1-5.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
c-0.5-0.1-0.9-0.1-1.4,0c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0.2-0.1,0.5-0.1,0.8v8.2c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0.1,0.4
c0.1,0.3,0.5,0.4,1.1,0.4C163.3,48.4,163.7,48.3,163.8,48z"
    />
    <path
      fill="#1C3A69"
      d="M178.1,48.4c1.4,0.1,2.7-0.5,3.7-1.6c1-1,1.5-2.4,1.5-3.9c0.1-3-2.3-5.4-5.3-5.4c-0.7,0-1.4,0.2-2,0.3
c-0.6,0.2-1.2,0.6-1.7,1.1s-0.9,1.1-1.2,1.8c-0.3,0.8-0.4,1.5-0.4,2.3c0,1.4,0.6,2.8,1.6,3.8S176.7,48.4,178.1,48.4z M176.1,40.9
c0.5-0.5,1.2-0.8,1.9-0.8c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2.1c0.1,0.8-0.2,1.5-0.8,2.1l-0.1,0.1c-1.1,1-2.7,0.9-3.7-0.1
c-0.5-0.5-0.8-1.3-0.8-2.1S175.6,41.5,176.1,40.9z"
    />
    <path
      fill="#1C3A69"
      d="M191.5,45.3c0.2-0.1,0.3-0.2,0.4-0.3l0.3-0.3c0.1-0.1,0.4-0.5,0.9-1.2c0.5-0.6,0.9-1.1,1.1-1.4V47
c0,0.3,0,0.6,0.1,0.8c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7-0.1
c0.4-0.1,0.6-0.5,0.6-1.2V39c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.3-0.7-0.7-1.3-0.7s-1,0.1-1.2,0.4
c-0.5,0.7-1.6,2.1-3.3,4.3l-3.3-4.3l-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.6,0-1,0.1-1.1,0.4
c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0,0.5v8.1c0,0.2,0,0.3,0,0.5s0,0.3,0.1,0.4c0.1,0.3,0.5,0.4,1.1,0.4c0.6,0,1-0.1,1.1-0.4
c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3,0-0.5v-4.9c0,0.1,0.3,0.5,0.9,1.3c0.6,0.8,1,1.4,1.2,1.6l0.1,0.2c0.1,0.1,0.3,0.2,0.4,0.3
c0.2,0.1,0.4,0.2,0.6,0.2S191.3,45.4,191.5,45.3z"
    />
    <path
      fill="#1C3A69"
      d="M158.4,58.1c-0.3-0.6-1.2-0.9-2.5-1c-1.4,0-2.3,0.3-2.7,0.9c-1.3,1.6-3.8,4.9-7.6,9.9L138,58l-0.2-0.2
c-0.2-0.3-0.5-0.4-0.8-0.5c-0.6-0.1-1.1-0.2-1.6-0.2c-1.4,0-2.3,0.3-2.6,0.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.4-0.1,0.8-0.1,1.2
v18.5c0,0.4,0.1,0.8,0.1,1.2c0,0.3,0.1,0.6,0.3,0.9c0.3,0.6,1.1,0.9,2.5,0.9s2.3-0.3,2.6-0.9c0.1-0.2,0.2-0.5,0.3-0.8
c0.1-0.4,0.1-0.8,0.1-1.2V67.5c0.1,0.1,0.8,1.1,2.1,2.9c1.3,1.8,2.2,2.9,2.6,3.5l0.3,0.4c0.3,0.3,0.6,0.5,0.9,0.7
c0.9,0.5,1.9,0.5,2.8,0c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.2,0.3-0.4,0.6-0.8c0.3-0.4,0.9-1.3,2-2.8c1-1.5,1.8-2.6,2.4-3.3v11.1
c0,0.5,0.1,1.1,0.2,1.7c0.1,0.3,0.3,0.5,0.5,0.7c0.3,0.2,0.6,0.3,0.9,0.4c0.5,0.2,1,0.2,1.5,0.2s1.1-0.1,1.6-0.2
c0.9-0.3,1.3-1.2,1.3-2.7V60.2c0-0.4-0.1-0.8-0.1-1.2C158.7,58.7,158.6,58.4,158.4,58.1z"
    />
    <path
      fill="#1C3A69"
      d="M175,56.9c-1.5,0-3,0.3-4.4,0.8c-1.4,0.6-2.7,1.5-3.8,2.6c-1.2,1.2-2.1,2.6-2.7,4.1c-0.7,1.7-1,3.4-1,5.2
c-0.1,3.2,1.2,6.4,3.5,8.7c2.2,2.4,5.3,3.8,8.6,3.7c3.2,0.1,6.3-1.3,8.5-3.7c2.3-2.4,3.6-5.7,3.5-9C187.3,62.5,181.8,57,175,56.9z
M179.5,74.3c-0.1,0.1-0.1,0.1-0.2,0.2c-2.4,2.3-6.2,2.2-8.4-0.2c-1.3-1.2-2-3-1.9-4.8c-0.1-1.8,0.6-3.6,1.9-4.8
c1.1-1.2,2.7-1.9,4.3-1.9c1.6,0,3.2,0.6,4.3,1.8c1.3,1.3,2,3.1,1.9,4.9C181.5,71.3,180.8,73,179.5,74.3z"
    />
    <path
      fill="#1C3A69"
      d="M205.2,68.2c-0.9-0.4-1.9-0.7-2.8-1c-0.9-0.2-1.9-0.4-2.8-0.6c-0.7-0.1-1.5-0.4-2.1-0.8
c-0.5-0.3-0.8-0.9-0.8-1.5c0-0.5,0.2-1.1,0.7-1.4s1.2-0.5,1.8-0.5c0.6-0.1,1.3,0.1,1.8,0.4c0.2,0.1,0.3,0.2,0.5,0.4
c0.1,0.1,0.3,0.2,0.4,0.3c0.5,0.4,1.1,0.6,1.7,0.7c0.8,0,1.5-0.6,2.3-1.7c0.4-0.5,0.7-1.1,0.8-1.8c-0.1-0.6-0.5-1.2-1-1.6l-0.8-0.6
c-0.7-0.5-1.5-0.9-2.3-1.1c-1.1-0.3-2.4-0.5-3.6-0.5c-2.2-0.1-4.4,0.7-6,2.2c-1.4,1.3-2.2,3.2-2.2,5.1c-0.1,1.2,0.2,2.5,0.8,3.6
c0.5,0.9,1.2,1.7,2.1,2.2c1.7,1,3.6,1.6,5.5,2c0.7,0.1,1.4,0.3,2.1,0.7c0.5,0.3,0.9,0.7,0.9,1.3c0,1.7-1,2.5-3.1,2.5
c-0.6,0-1.1-0.2-1.6-0.5s-0.9-0.7-1.3-1.1c-1.1-1.1-2-1.7-2.6-1.7c-0.7,0-1.3,0.3-1.8,0.8c-0.9,1-1.4,1.7-1.4,2.3
c0.1,0.7,0.4,1.4,0.9,1.9l0.1,0.5c1.2,1.4,2.9,2.5,4.7,3c1,0.3,2,0.4,3,0.4c2.1,0,4.1-0.7,5.8-1.8c1-0.7,1.8-1.6,2.3-2.6
c0.6-1.2,0.9-2.5,0.9-3.8c0.1-1.2-0.2-2.5-0.8-3.6C206.8,69.4,206.1,68.7,205.2,68.2z"
    />
    <path
      fill="#1C3A69"
      d="M225.8,58.9c-0.4-1-1.5-1.7-2.5-1.6c-0.6,0-1.1,0.2-1.6,0.5s-0.9,0.7-1.1,1.2l-9,18.6
c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.8,0.6,1.5,1.9,2.1c0.6,0.3,1.2,0.5,1.8,0.6c0.4,0,0.8-0.1,1.1-0.3c0.2-0.2,0.4-0.4,0.6-0.6
c0.1-0.2,0.3-0.6,0.5-1l1.7-3.6h9.2l1.7,3.6c0.2,0.4,0.4,0.8,0.5,1c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.2,0.7,0.3,1.1,0.3
c0.6-0.1,1.3-0.3,1.8-0.6c1.3-0.7,1.9-1.4,1.9-2.2c-0.1-0.6-0.3-1.2-0.6-1.7L225.8,58.9z M220.9,71.5l2.2-4.6l2.2,4.6H220.9z"
    />
    <path
      fill="#1C3A69"
      d="M241.8,57.1c-1.4,0-2.3,0.3-2.6,0.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.4-0.1,0.8-0.1,1.2l0.1,18.5
c0,0.6,0,1.1,0.2,1.7c0.2,0.9,1.1,1.3,2.7,1.3c1.4,0,2.3-0.3,2.6-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.4,0.1-0.8,0.1-1.2V60
c0-0.6-0.1-1.1-0.2-1.7C244.3,57.5,243.4,57.1,241.8,57.1z"
    />
    <path
      fill="#1C3A69"
      d="M267,74.1c-0.6,0.1-1.1,0.4-1.6,0.7l-0.6,0.4c-0.4,0.3-0.9,0.5-1.3,0.9c-0.7,0.2-1.3,0.3-2,0.3
c-1.5-0.1-3-0.6-4.2-1.5c-0.7-0.6-1.3-1.3-1.7-2.1c-0.9-1.9-0.9-4.1,0-6c0.4-0.8,1-1.6,1.7-2.1c1.3-0.9,2.8-1.5,4.3-1.5
c1.3-0.1,2.6,0.3,3.7,1.1c0.5,0.4,1.1,0.6,1.7,0.7c0.7,0,1.4-0.6,2.3-1.8c0.4-0.6,0.7-1.2,0.7-1.9c-0.1-0.7-0.4-1.2-0.9-1.6
c-0.2-0.2-0.5-0.4-0.9-0.6l-0.8-0.5c-0.8-0.4-1.6-0.8-2.5-1c-1-0.3-2.1-0.5-3.2-0.5c-2.3,0-4.6,0.6-6.6,1.8
c-1.2,0.6-2.2,1.4-3.1,2.3c-0.9,1-1.7,2.2-2.2,3.5c-0.6,1.5-0.9,3.1-0.9,4.7c0,1.6,0.3,3.3,0.9,4.8c0.5,1.3,1.2,2.5,2.2,3.6
c0.9,0.9,2,1.7,3.1,2.4c1,0.6,2.1,1.1,3.3,1.4c1,0.3,2.1,0.4,3.2,0.4c1.1,0,2.2-0.2,3.3-0.5c0.7-0.2,1.5-0.5,2.2-0.9l1.2-0.7
c0.4-0.2,0.8-0.5,1.1-0.8c0.4-0.3,0.5-0.8,0.5-1.2c0-0.7-0.3-1.4-0.7-1.9C268.4,74.7,267.7,74.1,267,74.1z"
    />
  </svg>
);
