import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Creates a ref, that will always update to the latest value you pass to it.
 *
 * @param value The value the ref should take
 * @returns A ref object to the value
 */
export function useUpdatingRef<T>(value: T): MutableRefObject<T> {
  const valueRef = useRef<T>(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
}
