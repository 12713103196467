import { PortalConfiguration } from '@axinom/mosaic-core';
import { PiralPlugin } from 'piral-core';
import { EnvironmentApi } from './types';

let config: PortalConfiguration;

/**
 * Creates new Pilet API extension retrieving the portal configuration.
 */
export const createEnvironmentApi = (
  portalConfig: PortalConfiguration,
): PiralPlugin<EnvironmentApi> => {
  config = portalConfig;

  return () => ({
    getEnvironment() {
      return config;
    },
  });
};
