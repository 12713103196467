import { useField } from 'formik';
import React from 'react';
import { useFormikError } from '../useFormikError';
import { ToggleButton, ToggleButtonProps } from './ToggleButton';
export const ToggleButtonField: React.FC<Omit<ToggleButtonProps, 'error'>> = (
  props,
) => {
  const { name } = props;
  const error = useFormikError(name);
  const [field, , helpers] = useField(name);

  return (
    <ToggleButton
      {...props}
      value={field.value}
      error={error}
      onButtonClicked={(_e, v) => {
        helpers.setValue(v);
        if (props.onButtonClicked) {
          props.onButtonClicked(_e, v);
        }
      }}
    />
  );
};
