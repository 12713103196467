/**
 * A simple UUID generator. This is not a cryptographically secure generator.
 * @returns UUID
 */
export const uuid = (): string => {
  const url = URL.createObjectURL(new Blob());
  const [id] = url.toString().split('/').reverse();
  URL.revokeObjectURL(url);
  return id;
};
