import { ApolloErrorMapper } from './ApolloClient/ApolloErrorMapper';
import { ErrorMapper } from './ErrorMapper.type';

let ErrorMappers: ErrorMapper[] = [ApolloErrorMapper];

/**
 * Sets the error mappers to use for mapping errors.
 * By default the `ApolloErrorMapper` is registered.
 * @param mappers an array of error mappers that should be used
 */
export const setErrorMappers = (mappers: ErrorMapper[]): void => {
  ErrorMappers = mappers;
};

/**
 * Uses the currently registered error mappers to map the given error.
 * To register error mappers use the `setErrorMappers` function.
 * @param error the error to map
 * @returns the mapped error or the original error if no mapper was able to map the error
 */
export const mapError: ErrorMapper = (error) => {
  const originalError = error;
  let mappedError = undefined;
  for (const mapper of ErrorMappers) {
    const mappingResult = mapper(originalError, mappedError);
    if (mappingResult !== undefined && mappingResult !== originalError) {
      mappedError = mappingResult;
    }
  }
  return mappedError ?? originalError;
};
