import clsx from 'clsx';
import React from 'react';
import classes from './TextButton.scss';

/** Button's context type. This type defines the button's colors and border. */
export enum ButtonContext {
  Navigation,
  Context,
  Active,
  Icon,
  None,
}

/** Base options for all button components */
export interface BaseButtonOptions {
  /** HTML button type attribute (default: 'button') */
  type?: 'button' | 'submit' | 'reset';
  /** Button type (default: 'Navigation') */
  buttonContext?: ButtonContext;
  /** Whether the button is disabled. If set to true, disallows interactions. (default: undefined) */
  disabled?: boolean;
  /** Optional class */
  className?: string;
  /** Optional data-test-id */
  dataTestId?: string;
  /** Event to raise when the button is clicked */
  onButtonClicked?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
}

export interface TextButtonProps extends BaseButtonOptions {
  /** Button's height (default: '30px') */
  height?: string | number;
  /** Button's width (default: undefined) */
  width?: string | number;
  /** Button text */
  text?: string;
}

/**
 * Button which can render a text string.
 * @example
 *   <TextButton type="button" text="Click me!" />
 */
export const TextButton: React.FC<TextButtonProps> = ({
  type = 'button',
  height,
  width,
  buttonContext = ButtonContext.Context,
  disabled = false,
  text = '',
  className,
  dataTestId = undefined,
  onButtonClicked,
}: TextButtonProps) => {
  const customStyles = {
    height: height,
    width: width,
  } as React.CSSProperties;

  return (
    <button
      className={clsx(
        classes.container,
        { [classes.navigation]: buttonContext === ButtonContext.Navigation },
        {
          [classes.context]: buttonContext === ButtonContext.Context,
        },
        {
          [classes.active]: buttonContext === ButtonContext.Active,
        },
        'text-button-container',
        className,
      )}
      type={type}
      style={customStyles}
      onClick={onButtonClicked}
      disabled={disabled}
      data-test-id={dataTestId ?? 'text-button'}
    >
      {text}
    </button>
  );
};
