import clsx from 'clsx';
import React, { useState } from 'react';
import { AccordionItem } from '../../Accordion';
import { Paragraph } from '../Paragraph/Paragraph';
import classes from './Section.scss';

export interface SectionProps {
  /** Section Title. If set, will allow the section to be expandable/collapsible */
  title?: string;

  /** Optional class */
  className?: string;

  /** Optional styles */
  style?: React.CSSProperties;

  /** Whether the section should be expanded or collapsed intially (default: true)*/
  expandedByDefault?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  className,
  title,
  style,
  children,
  expandedByDefault = true,
}) => {
  const [expanded, setExpanded] = useState<boolean>(expandedByDefault);
  const validChildren: React.ReactNode[] = [];

  // Special case for removing paragraph components if they are empty
  React.Children.forEach(children, (child) => {
    if (
      !React.isValidElement(child) ||
      child.type !== Paragraph ||
      (child.props.children !== null && child.props.children !== undefined)
    ) {
      validChildren.push(child);
    }
  });

  // Do not render the section if there are no children
  if (validChildren.length === 0) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        classes.container,
        { [classes.hasTitle]: title, [classes.expanded]: expanded },
        'info-panel-section-container',
        className,
      )}
      style={style}
      data-test-id="section"
    >
      {title ? (
        <AccordionItem
          header={
            title ? (
              <div className={classes.title} data-test-id="section-title">
                {title}
              </div>
            ) : (
              <div className={classes.title} data-test-id="section-title"></div>
            )
          }
          allowLeftSpacing={false}
          isExpanded={expanded}
          toggleExpanded={() => setExpanded((prev) => !prev)}
          className={classes.collapsibleSection}
        >
          <div className={classes.main} data-test-id="section-content">
            {validChildren}
          </div>
        </AccordionItem>
      ) : (
        <div className={classes.main} data-test-id="section-content">
          {validChildren}
        </div>
      )}
    </div>
  );
};
