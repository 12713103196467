import { createContext } from 'react';
import { noop } from '../../../helpers/utils';

export interface PageHeaderActionsGroupContextType {
  registerCollapse: (readonly: () => Promise<void>, uuid: string) => void;
  collapseAll: (uuid: string) => Promise<void[]>;
}

export const PageHeaderActionsGroupContext =
  createContext<PageHeaderActionsGroupContextType>({
    registerCollapse: noop,
    collapseAll: async () => {
      return [];
    },
  });
