import { Not } from '../../../types';
import { IconName } from '../../Icons';
import { ConfirmAction, DefaultHandler, LinkAction } from '../../models';

export enum PageHeaderActionType {
  Active,
  Context,
  Hightlight,
}

export type PageHeaderActionProps =
  | PageHeaderJsActionProps
  | PageHeaderNavigationActionProps;

export interface PageHeaderJsActionProps
  extends BaseActionOptions,
    HandledAction,
    ConfirmAction,
    Not<LinkAction> {
  /**
   * Whether the action is an 'Active' or 'Context' type.
   * Changes the background color to the corresponding action type. (default: 'Context')
   */
  actionType?: PageHeaderActionType;
}

export interface PageHeaderNavigationActionProps
  extends BaseActionOptions,
    LinkAction,
    Not<ConfirmAction>,
    Not<HandledAction> {}

interface HandledAction {
  /**
   * Callback to emit when a user clicks on the component
   */
  onClick: DefaultHandler;
}

interface BaseActionOptions {
  /** The label of the action. */
  label: string;
  /** Optional built in icon. This prop also accepts an img src. */
  icon?: IconName | string;
  /** Optional image alt attribute. */
  imgAlt?: string;
  /** Whether the action is disabled. If set to true, disallows interactions. (default: undefined) */
  disabled?: boolean;
  /** Optional class */
  className?: string;
}
