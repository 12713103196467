import { PortalConfiguration } from '@axinom/mosaic-core';

declare global {
  interface Window {
    MOSAIC_PORTAL_CONFIG?: PortalConfiguration;
  }
}

// This portal config object will be injected by the portal-service.
export const getInjectedPortalConfig = (): PortalConfiguration | undefined =>
  window.MOSAIC_PORTAL_CONFIG;
