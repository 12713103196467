/* eslint-disable no-console */
import { PortalConfiguration } from '@axinom/mosaic-core';
import {
  createIdentityServiceClient,
  EnsureAuthentication,
  IdentityServiceClient,
  IdentityServiceProvider,
} from '@axinom/mosaic-id-link';
import { createInstance, Piral, PiralInstance, SetComponent } from 'piral-core';
import React, { useEffect, useMemo, useState } from 'react';
import { render } from 'react-dom';
import {
  fetchDevConfig,
  getInjectedPortalConfig,
  localPortalConfig,
  saveLocalPortalConfig,
} from './config';
import { PortalConfigInput } from './config/PortalConfigInput';
import {
  createAuthApi,
  createEnvironmentApi,
  createHomeApi,
  createIndicatorApi,
  createNavigationApi,
  createNavigationResolverApi,
  createNotificationsApi,
  createProviderExtensionApi,
  createRegistrationInfoApi,
} from './extensions';
import { createTitleApi } from './extensions/TitleExtension/create';
import { checkForOutdatedVersion } from './helpers/checkForOutdatedVersion';
import { registerShellMetadata } from './helpers/registerShellMetadata';
import useBodyClass from './hooks/useBodyClass';
import './index.scss';
import { Layout } from './Layout/Layout';
import { Loading } from './Layout/Loading';
import { EnsurePermissionWrapper } from './Permissions/EnsurePermissionWrapper';
import './polyfills';

const App: React.FC = () => {
  const [portalConfig, setPortalConfig] =
    useState<PortalConfiguration | null>();
  const [showConfig, setShowConfig] = useState<boolean>(false);

  useEffect(() => {
    const getConfig = async (): Promise<void> => {
      const injectedConfiguration = getInjectedPortalConfig();
      if (injectedConfiguration !== undefined) {
        setPortalConfig(injectedConfiguration);
      } else {
        const devConfig = await fetchDevConfig();

        if (devConfig !== undefined) {
          console.log('Using dev config');
          setPortalConfig(devConfig);
        } else if (localPortalConfig !== undefined) {
          console.log('Using local storage config');
          setPortalConfig(localPortalConfig);
        } else {
          setShowConfig(true);
        }
      }
    };

    getConfig();
  }, []);

  const identityClient = useMemo<IdentityServiceClient | undefined>(() => {
    if (portalConfig) {
      return createIdentityServiceClient({
        idServiceAuthBaseUrl: portalConfig.idServiceAuthEndpoint,
        tenantId: portalConfig.tenantId,
        environmentId: portalConfig.environmentId,
      });
    }
  }, [portalConfig]);

  const piralInstance = useMemo<PiralInstance | undefined>(() => {
    if (portalConfig && identityClient) {
      const feedUrl = new URL(
        `/v1/pilets/${portalConfig.tenantId}/${portalConfig.environmentId}`,
        portalConfig.microFrontendServiceEndpoint,
      ).href;

      return createInstance({
        async requestPilets() {
          const response = await fetch(feedUrl);
          const feed = await response.json();
          return feed.items;
        },
        plugins: [
          createAuthApi(identityClient),
          createEnvironmentApi(portalConfig),
          createNavigationApi(),
          createNotificationsApi(),
          createHomeApi(),
          createRegistrationInfoApi(),
          createProviderExtensionApi(),
          createNavigationResolverApi(),
          createIndicatorApi(),
          createTitleApi(),
        ],
        state: {
          registry: {
            wrappers: {
              page: EnsurePermissionWrapper,
            },
          },
        },
      });
    }
  }, [identityClient, portalConfig]);

  // Adding the environmentId as a body class to allow for environment specific styling
  useBodyClass(
    portalConfig?.environmentId
      ? `env-${portalConfig.environmentId}`
      : 'env-unknown',
  );

  if (identityClient && piralInstance) {
    if (
      window.location.hostname === 'localhost' &&
      process.env.DISABLE_VERSION_CHECK !== 'true'
    ) {
      checkForOutdatedVersion(piralInstance);
    }

    registerShellMetadata(piralInstance);

    return (
      <IdentityServiceProvider client={identityClient}>
        <EnsureAuthentication>
          <Piral instance={piralInstance}>
            <SetComponent name="LoadingIndicator" component={Loading} />
            <SetComponent name="Layout" component={Layout} />
          </Piral>
        </EnsureAuthentication>
      </IdentityServiceProvider>
    );
  } else if (showConfig) {
    // Portal-Configuration was not found on window object nor the localStorage
    // We'll now ask for manual user input
    return (
      <PortalConfigInput
        applyLocalPortalConfig={(config) => {
          saveLocalPortalConfig(config);
          setPortalConfig(config);
          setShowConfig(false);
        }}
      />
    );
  } else {
    // While loading configuration
    return <></>;
  }
};

render(<App />, document.querySelector('#app'));
