import { GlobalStateContext } from 'piral-core';
import {
  NavigationResolver,
  NavigationResolverRegistryState,
  ResolverData,
} from './types';

export function registerRouteResolver(
  { dispatch }: GlobalStateContext,
  { station, resolver }: ResolverData,
): void {
  registerResolver(dispatch, station, resolver);
}

export function setRouteResolver(
  { dispatch }: GlobalStateContext,
  station: never,
  resolver: NavigationResolver,
): void {
  registerResolver(dispatch, station, resolver);
}

const registerResolver = (
  dispatch: GlobalStateContext['dispatch'],
  station: never | string,
  resolver: NavigationResolver,
): void => {
  dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      navigationResolvers: {
        ...state.registry.navigationResolvers,
        [station]: resolver,
      },
    },
  }));
};

export function resolveRoute(
  { readState }: GlobalStateContext,
  station: string,
  dynamicRouteSegments?: Record<string, string> | string,
): string | undefined {
  return readState((state) => state.registry.navigationResolvers?.[station])?.(
    dynamicRouteSegments,
  );
}

export function getAllRouteResolvers({
  readState,
}: GlobalStateContext): NavigationResolverRegistryState['navigationResolvers'] {
  return readState((state) => state.registry.navigationResolvers);
}
