import { DateTime } from 'luxon';
import React from 'react';
import Calendar from 'react-calendar';
import { noop } from '../../../helpers/utils';
import { IconName, Icons } from '../../Icons';
import classes from './DatePicker.scss';

export interface DatePickerProps {
  /** Current Value */
  value: DateTime;

  /** Callback when a new value is selected */
  onSelected?: (value: DateTime) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onSelected = noop,
}) => {
  return (
    <Calendar
      onChange={(value) => {
        onSelected(DateTime.fromJSDate(value as Date));
      }}
      value={value.toJSDate()}
      className={classes.calendar}
      locale={navigator.language}
      nextLabel={<Icons icon={IconName.ChevronRight} />}
      prevLabel={<Icons icon={IconName.ChevronLeft} />}
      formatShortWeekday={(locale, date) =>
        Intl.DateTimeFormat(locale, { weekday: 'short' })
          .format(date)
          .slice(0, 2)
      }
    />
  );
};
