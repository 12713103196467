import clsx from 'clsx';
import React from 'react';
import { useBusy } from '../../hooks/useBusy/useBusy';
import { Action } from './Action/Action';
import { ActionData, ContextActionData } from './Actions.models';
import classes from './Actions.scss';

export interface ActionsProps {
  /** Array of Actions to be rendered */
  actions?: ActionData[];
  /** Width of the container */
  width?: string;
  /** CSS Class name for additional styles */
  className?: string;
  onActionClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: ContextActionData,
  ) => void;
}

/**
 * Renders an array of actions
 * @example
 * <Actions
 *  actions={[{ label: 'Action Label', onActionSelected={actionSelectedHandler} }]}
 * />
 */
export const Actions: React.FC<ActionsProps> = ({
  actions = [],
  width = '300px',
  className = '',
  onActionClick,
}) => {
  const customStyles = {
    width: width,
  } as React.CSSProperties;

  const { isBusy } = useBusy();

  return (
    <div
      className={clsx(classes.container, 'actions-container', className)}
      style={customStyles}
      data-test-id="actions"
    >
      {actions.map((action, index) => (
        <Action
          key={index}
          action={{ isDisabled: isBusy, ...action }}
          onActionClick={onActionClick}
        />
      ))}
    </div>
  );
};
