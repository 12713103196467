import { useFormikContext } from 'formik';
import React from 'react';
import classes from './FormikDebug.scss';

export const FormikDebug = (): JSX.Element => {
  const ctx = useFormikContext();
  return (
    <div className={classes.container}>
      <label>Formik Context</label>
      <div className={classes.value}>
        <pre>{JSON.stringify(ctx, null, 2)}</pre>
      </div>
    </div>
  );
};
