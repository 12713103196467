import clsx from 'clsx';
import React from 'react';
import { ActionData } from '../../Actions';
import { InlineMenu } from '../../InlineMenu/InlineMenu';
import { ImageLoader } from '../../Loaders';
import classes from './InfoImage.scss';

export interface InfoImageProps {
  /** Image url */
  imgSrc: string;
  /** Optional class */
  className?: string;
  /** Optional styles */
  style?: React.CSSProperties;
  /** Fallback image url to load in case of load failure. If not set, a fallback background color will be used */
  fallbackSrc?: string;
  /** Actions that should be available on the image's `InlineMenu` */
  inlineMenuActions?: ActionData[];
}

export const InfoImage: React.FC<InfoImageProps> = ({
  className,
  imgSrc,
  style,
  fallbackSrc,
  inlineMenuActions,
}) => {
  return (
    <div
      className={clsx(
        classes.container,
        'info-panel-image-container',
        className,
      )}
      style={style}
      data-test-id="info-image"
    >
      <ImageLoader
        imageClassName={classes.image}
        imgSrc={imgSrc}
        height={420}
        width={419} /*1px border*/
        fallbackSrc={fallbackSrc}
      >
        {inlineMenuActions && (
          <InlineMenu
            buttonClassName={classes.inlineMenu}
            actions={inlineMenuActions}
            showArrow={false}
            placement="bottom-end"
            addBackgroundOpacity={true}
          />
        )}
      </ImageLoader>
    </div>
  );
};
