import React, { useEffect, useState } from 'react';
import { useExpand } from '../../../hooks';
import { Data } from '../../../types';
import { FilterType, FilterValues } from '../../Filters';
import { Filters } from '../../Filters/Filters';
import { getState, storeState } from '../../Utils/State/GlobalState';
import { ExplorerStateOptions } from '../Explorer.model';

interface UseFiltersProps<T extends Data> {
  stationKey: string;
  globalStateOptions: ExplorerStateOptions;
  filterOptions?: FilterType<T>[];
  defaultFilterValues?: FilterValues<T>;
  onFiltersChange: (filters: FilterValues<T>) => void;
}

interface UseFiltersReturnType<T extends Data> {
  readonly Filters: JSX.Element;
  readonly activeFilters: FilterValues<T>;
  readonly filtersVisible: boolean;
  readonly toggleFiltersVisible: () => void;
  readonly collapseFilters: () => void;
}

export const useFilters = <T extends Data>({
  stationKey,
  globalStateOptions,
  filterOptions,
  defaultFilterValues,
  onFiltersChange,
}: UseFiltersProps<T>): UseFiltersReturnType<T> => {
  const { isExpanded, toggleExpanded, collapse } = useExpand(true);
  const [activeFilters, setActiveFilters] = useState<FilterValues<T>>(
    getState<FilterValues<T>>(stationKey, 'filters') ??
      defaultFilterValues ??
      {},
  );

  useEffect(() => {
    if (
      globalStateOptions.filters &&
      activeFilters !== defaultFilterValues &&
      Object.keys(activeFilters).length > 0
    ) {
      storeState(stationKey, 'filters', activeFilters);
    } else {
      storeState(stationKey, 'filters', undefined);
    }
  }, [
    activeFilters,
    defaultFilterValues,
    globalStateOptions.filters,
    stationKey,
  ]);

  const FilterComponent = (
    <>
      {isExpanded && (
        <Filters<T>
          options={filterOptions}
          defaultValues={activeFilters}
          onFiltersChange={(args) => {
            onFiltersChange(args);
            setActiveFilters(args);
          }}
        />
      )}
    </>
  );

  return {
    Filters: FilterComponent,
    activeFilters,
    filtersVisible: isExpanded,
    toggleFiltersVisible: toggleExpanded,
    collapseFilters: collapse,
  };
};
