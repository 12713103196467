import { useField } from 'formik';
import React from 'react';
import { useFormikError } from '../useFormikError';
import { Radio, RadioProps } from './Radio';

export const RadioField: React.FC<
  Omit<RadioProps, 'error' | 'onChange'> & {
    /** Takes the input value and transforms it */
    transform?: (value: unknown) => unknown;
  }
> = (props) => {
  const { transform = (value: unknown) => value } = props;
  const error = useFormikError(props.name);
  const [field, , helpers] = useField(props.name);

  return (
    <Radio
      {...props}
      value={field.value}
      error={error}
      onChange={(e) => helpers.setValue(transform(e.currentTarget.value))}
    />
  );
};
