import { PortalConfiguration } from '@axinom/mosaic-core';

const localStorageKey = 'MOSAIC_PORTAL_CONFIG';

export let localPortalConfig: PortalConfiguration | undefined;
try {
  // Local portal config object found in the user's browser storage
  localPortalConfig = JSON.parse(
    (window.localStorage.getItem(localStorageKey) as string) ?? undefined,
  );
} catch {
  localPortalConfig = undefined;
}

export const saveLocalPortalConfig: (config: PortalConfiguration) => void = (
  config,
) => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(config));
};
