import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { InfoTooltip } from '../../InfoTooltip';
import { BaseFormElement } from '../Form.models';
import classes from './FormElementContainer.scss';

export type FormElementContainerProps = BaseFormElement & {
  /** Optional error message to display */
  error?: string | undefined;

  /** Minimum height of the element (default: 50px) */
  minHeight?: string;

  /** Identifier for E2E testing to identify the form element type */
  dataTestFieldType?: string;

  /** Stick to the top option */
  stickyLabel?: boolean;
};

/**
 * A Container Component which renders the label and content which is used as the base for all Form Elements.
 * @example <FormElementContainer label={label}>
 * {fieldContent}
 * </FormElementContainer>
 */
export const FormElementContainer: React.FC<FormElementContainerProps> = ({
  id,
  label = '',
  className = '',
  children,
  tooltipContent,
  error,
  minHeight = '50px',
  dataTestFieldType,
  inlineMode = false,
  stickyLabel = true,
}) => {
  const heightStyles: CSSProperties = {};

  if (minHeight !== '') {
    heightStyles.height = 'auto';
    heightStyles.minHeight = minHeight;
  }

  return (
    <div
      className={clsx(classes.container, className, {
        [classes.inline]: inlineMode,
      })}
      id={id}
      data-test-id="form-field"
      data-test-type={dataTestFieldType}
    >
      {!inlineMode && (
        <div
          className={clsx(
            classes.label,
            {
              [classes.sticky]: stickyLabel,
            },
            'form-element-label',
          )}
          style={heightStyles}
        >
          <label data-test-id="form-field-label">
            {label}
            {tooltipContent && (
              <InfoTooltip className={clsx(classes.tooltip)}>
                {tooltipContent}
              </InfoTooltip>
            )}
          </label>
        </div>
      )}
      <div
        className={clsx(classes.content, 'form-element-content')}
        data-test-id="form-field-content"
        style={heightStyles}
      >
        {children}
      </div>
      {error && (
        <small
          className={clsx({ [classes.inlineMode]: inlineMode }, classes.error)}
          data-test-id="form-field-error"
        >
          {error}
        </small>
      )}
    </div>
  );
};
