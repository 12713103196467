export type ProgressBarProps = SimpleProgressBar | FullProgressBar;
export enum ProgressBarStatus {
  Processing = 'Processing',
  Completed = 'Completed',
  Warning = 'Warning',
  Error = 'Error',
}

interface ProgressBar {
  /**
   * Toggles two different visualizations/sizes.
   */
  kind: 'simple' | 'full';
  /**
   * The progress that should be displayed. (0-100)
   */
  progress?: number;
  /**
   * Use this to signal the status of the progress.
   *
   * @default ProgressBarStatus.Processing
   */
  status?: ProgressBarStatus;
  /**
   * When set to `true`, the progress bar will just show a progress animation
   * instead of a concrete progress.
   *
   * @default false
   */
  indeterminate?: boolean;
  /** CSS Class name for additional styles */
  className?: string;
}

export interface SimpleProgressBar extends ProgressBar {
  kind: 'simple';
}

export interface FullProgressBar extends ProgressBar {
  kind: 'full';

  /**
   * A text that should be written on top of the progress bar.
   * You can use `${progress}` to display the current `progress` in that place.
   */
  text?: string;
}
