import React from 'react';
import { BaseFormControl } from '../Form.models';
import { FormElementContainer } from '../FormElementContainer';
import { useFormikError } from '../useFormikError';

export type GenericFieldProps = BaseFormControl & {
  /** Height of the label (default: 50px) */
  labelHeight?: string;
  stickyLabel?: boolean;
};

/**
 * A Generic Field component which can be used to create custom Field component using the styles from @axinom/mosaic-ui.
 * @example <GenericField label={label} name={name}>
 * {fieldContent}
 * </GenericField>
 */
export const GenericField: React.FC<GenericFieldProps> = ({
  name,
  labelHeight,
  ...rest
}) => {
  const error = useFormikError(name);

  return (
    <FormElementContainer
      {...rest}
      error={error}
      minHeight={labelHeight}
      dataTestFieldType="GenericField"
    />
  );
};
