import clsx from 'clsx';
import React from 'react';
import classes from './Divider.scss';

export interface DividerProps {
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({ className = '' }) => (
  <div className={clsx(classes.container, 'divider-container', className)}>
    <div className={clsx(classes.divider)}></div>
  </div>
);
