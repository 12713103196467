import clsx from 'clsx';
import React from 'react';
import { HubItem } from './Hub.model';
import classes from './Hub.scss';
import { Tile } from './Tile';

export interface HubProps {
  /** Data for all tiles */
  items: HubItem[];
  /** Spacing between tiles */
  gridGap?: string;
  /** The minimum width the grid can shrink down to */
  minWidth?: string;
  /** The maximum width the grid can expand to */
  maxWidth?: string;
  /** CSS Class name for additional styles */
  className?: string;
}

type GroupName = string;

export const Hub: React.FC<HubProps> = ({
  children,
  items = [],
  gridGap,
  minWidth,
  maxWidth,
  className = '',
}) => {
  const customStyles = {
    gridGap: gridGap,
    minWidth: minWidth,
    maxWidth: maxWidth,
  } as React.CSSProperties;

  const groups: Record<GroupName, HubItem[]> = {};

  items.forEach((item) => {
    groups[item.groupName] = [...(groups[item.groupName] || []), item];
  });

  return (
    <div className={clsx('hub-group-container', className)} data-test-id="hub">
      {Object.keys(groups).map((group, groupIndex) => (
        <div
          key={groupIndex}
          className={clsx(classes.group)}
          data-test-id="hub-group"
        >
          <span className={clsx(classes.title)} data-test-id="hub-group-label">
            {group}
          </span>
          <div className={clsx(classes.container)} style={customStyles}>
            {groups[group].map((item, itemIndex) => (
              <Tile
                key={itemIndex}
                path={item.path}
                label={item.label}
                subLabel={item.subLabel}
                icon={item.icon}
                disabled={item.disabled}
              />
            ))}
          </div>
        </div>
      ))}
      {children}
    </div>
  );
};
