import clsx from 'clsx';
import React, { ChangeEvent } from 'react';
import { DynamicListDataEntryRenderer } from '../../../../DynamicDataList/DynamicDataList.model';
import { CreateSelectRendererConfig } from '../renderers.model';
import classes from './createSelectRenderer.scss';

/**
 * Creates a simple component that can be used as an input field
 * @param Object configuration object of type CreateSelectRendererConfig
 * @example createSelectRenderer({
        options: [
          { value: 'title 1', label: 'Item 1' },
          { value: 'title 2', label: 'Item 2' }
        ],
        defaultValue: 'title 1',
      })
 */
export const createSelectRenderer = ({
  id,
  name,
  placeholder,
  options = [],
  defaultValue,
  transform = (value: string) => value,
}: CreateSelectRendererConfig = {}): DynamicListDataEntryRenderer => {
  const selectRenderer = (
    currentValue: unknown,
    error: string | undefined,
    onValueChange: (value: unknown) => void,
    disabled = false,
  ): React.ReactNode => {
    if (defaultValue && currentValue === undefined) {
      onValueChange(defaultValue);
    }

    const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>): void => {
      onValueChange(transform(e.target.value)); // emit onChange with transformed value
    };

    return (
      <div className={classes.container}>
        <select
          id={id}
          name={name}
          placeholder={disabled ? undefined : placeholder}
          value={(currentValue as string) ?? ''}
          onChange={onChangeHandler}
          className={clsx({ [classes.hasError]: error !== undefined })}
          disabled={disabled}
        >
          {placeholder && (
            <option value="" disabled>
              {disabled ? '' : placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error !== undefined && <small>{error}</small>}
      </div>
    );
  };

  return selectRenderer;
};
