import { useIdentityService, User } from '@axinom/mosaic-id-link';
import { Breadcrumb, Header } from '@axinom/mosaic-shared-components';
import clsx from 'clsx';
import {
  ExtensionSlot,
  LayoutProps,
  PiralRoutes,
  RegisteredRouteSwitch,
} from 'piral-core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Navigation } from '../components/Navigation';
import { Slide } from '../components/Slide';
import { useBreadcrumb } from '../hooks/useBreadcrumb';
import { useNavigationPanel } from '../hooks/useNavigationPanel';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { NotFoundStation } from './ErrorBoundary/NotFoundStation/NotFoundStation';
import { Indicators } from './Indicators/Indicators';
import classNames from './Layout.module.scss';

export interface AnimatedRouteProps {
  breadcrumbs: Breadcrumb[];
}

const AnimatedRoutes: React.FC<AnimatedRouteProps> = ({ breadcrumbs }) => {
  const location = useLocation();
  return (
    <Slide breadcrumbs={breadcrumbs}>
      <div className={classNames.slideContent}>
        <ErrorBoundary>
          <PiralRoutes
            location={location}
            NotFound={NotFoundStation}
            RouteSwitch={RegisteredRouteSwitch}
          />
        </ErrorBoundary>
      </div>
    </Slide>
  );
};

export const Layout: React.FC<LayoutProps> = () => {
  const { getToken, logoutUser } = useIdentityService();
  const bc = useBreadcrumb();

  const [user, setUser] = useState<User | undefined>();

  const { showStaticPanel, showOverlayPanel, collapsed, collapse, expand } =
    useNavigationPanel();

  useEffect(() => {
    (async () => {
      const token = await getToken();
      setUser(token.user);
    })();
  }, [getToken]);

  return (
    <>
      <div
        className={clsx(classNames.root, {
          [classNames.rootNav]: showStaticPanel,
          [classNames.overlayNav]: showOverlayPanel,
        })}
      >
        <nav className={classNames.nav}>
          <Navigation
            clickHandler={collapse}
            collapseDisabled={showStaticPanel}
          />
        </nav>
        <header className={classNames.header}>
          <Header
            crumbs={bc}
            auth={{
              user: user,
              logout: logoutUser,
            }}
            menu={{ visible: collapsed, clickHandler: expand }}
            ExtensionSlot={ExtensionSlot}
          >
            <Indicators />
          </Header>
        </header>
        <main className={classNames.main}>
          <AnimatedRoutes breadcrumbs={bc} />
          <ToastContainer
            className={classNames.toastContainer}
            toastClassName={classNames.toast}
            bodyClassName={classNames.toastBody}
            closeButton={false}
          />
        </main>
      </div>
    </>
  );
};
