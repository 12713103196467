/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { TransformerFunction } from '../../Utils';
import { BaseFormElement } from '../Form.models';
import { FormElementContainer } from '../FormElementContainer';
import classes from './ReadOnlyField.scss';

export interface ReadOnlyFieldProps<T = any> extends BaseFormElement {
  /** Value to be displayed */
  value?: any;

  /** Transform functions */
  transform?: TransformerFunction<T>;

  /** Sets whether the field should not be visible if the value is empty (default: false) */
  hideIfEmpty?: boolean;
}
/**
 * This component renders a read only field into a form.
 * @props **transform**: an array of transform functions which will be executed sequentially during render time passing the output from each function to the next.
 * The last transform function must return a single object or array of a type which can be rendered directly _( i.e. string | number | string[] | number[] )_.
 */
export const ReadOnlyField = <T,>({
  value = '',
  transform,
  className = '',
  hideIfEmpty = false,
  ...rest
}: PropsWithChildren<ReadOnlyFieldProps<T>>): JSX.Element => {
  const transformedValue = transform ? transform(value) : value;
  const displayValue = Array.isArray(transformedValue)
    ? transformedValue.join(', ')
    : transformedValue;

  if (hideIfEmpty && displayValue === '') {
    return <></>;
  }

  return (
    <FormElementContainer
      {...rest}
      className={clsx(
        classes.container,
        'read-only-field-container',
        className,
      )}
      minHeight={'20px'}
      dataTestFieldType="ReadOnly"
    >
      <div className={clsx(classes.value)} data-test-id="form-field-value">
        {displayValue}
      </div>
    </FormElementContainer>
  );
};
