import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { MessageBar, MessageBarProps } from '../MessageBar';
import { PageHeader, PageHeaderProps } from '../PageHeader';
import { StationError } from '../models';
import classes from './EmptyStation.scss';

export type EmptyStationProps = PageHeaderProps & {
  /** If set, creates a MessageBar component with an 'error' type message */
  stationError?: Omit<MessageBarProps, 'type'> & StationError;
  /** If set to `true`, the station will not render the full `PageHeader` but only a small line on top */
  minimizedHeader?: boolean;
};

/**
 * Renders a blank station template
 * @example
 * <EmptyStation title="Title" subtitle="My Asset Type">
 *   <div>
 *     <h1>
 *       Station content
 *     </h1>
 *   </div>
 * </EmptyStation>
 */
export const EmptyStation = ({
  children,
  stationError,
  minimizedHeader = false,
  className = '',
  ...rest
}: PropsWithChildren<EmptyStationProps>): JSX.Element => {
  return (
    <div
      className={clsx(
        classes.container,
        {
          [classes.hasStationError]: stationError,
        },
        'empty-station-container',
        className,
      )}
    >
      {minimizedHeader ? (
        <div className={classes.minimizedHeader} />
      ) : (
        <PageHeader {...rest} />
      )}
      {stationError && (
        <MessageBar
          type="error"
          title={String(stationError.title)}
          onClose={stationError.onClose}
          onRetry={stationError.onRetry}
        >
          {stationError?.body}
        </MessageBar>
      )}
      <div className={classes.children}>{children}</div>
    </div>
  );
};
