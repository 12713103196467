import clsx from 'clsx';
import React from 'react';
import classes from './Paragraph.scss';

export interface ParagraphProps {
  /** Paragraph Title */
  title?: string;

  /** Optional class */
  className?: string;

  /** Optional styles */
  style?: React.CSSProperties;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  className,
  title,
  style,
  children,
}) => {
  if (children === null || children === undefined) {
    return null;
  }
  return (
    <div
      className={clsx(
        classes.container,
        { [classes.hasTitle]: title },
        'info-panel-paragraph-container',
        className,
      )}
      style={style}
      data-test-id="paragraph"
    >
      {title && (
        <div className={classes.title} data-test-id="paragraph-title">
          {title}
        </div>
      )}
      <div className={classes.content} data-test-id="paragraph-content">
        {children}
      </div>
    </div>
  );
};
