import { GlobalStateContext, withKey, withoutKey } from 'piral-core';
import { HomeTileData, SettingsTileData, TileSorter } from './types';

export function registerTile(
  ctx: GlobalStateContext,
  name: string,
  value: HomeTileData | SettingsTileData,
): void {
  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      home: {
        ...state.registry.home,
        tiles: withKey(state.registry.home.tiles, name, value),
      },
    },
  }));
}

export function unregisterTile(ctx: GlobalStateContext, name: string): void {
  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      home: {
        ...(state.registry.home ?? {}),
        tiles: withoutKey(state.registry.home.tiles, name),
      },
    },
  }));
}

export function setHomeTileSorter(
  ctx: GlobalStateContext,
  value: TileSorter<HomeTileData>,
): void {
  ctx.dispatch((state) => ({
    ...state,
    registry: {
      ...state.registry,
      home: {
        ...state.registry.home,
        sorter: value,
      },
    },
  }));
}
