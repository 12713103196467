import clsx from 'clsx';
import React from 'react';
import classes from './LandingPageHeader.scss';

export interface LandingPageHeaderProps {
  title: string;
  subtitle: string;
  className?: string;
}

export const LandingPageHeader: React.FC<LandingPageHeaderProps> = ({
  title,
  subtitle,
  className = '',
}) => {
  return (
    <div
      className={clsx(
        classes.container,
        'landing-page-header-container',
        className,
      )}
      data-test-id="landing-page-header"
    >
      <div className={clsx(classes.title)} data-test-id="landing-page-title">
        {title}
      </div>
      <div
        className={clsx(classes.subtitle)}
        data-test-id="landing-page-subtitle"
      >
        {subtitle}
      </div>
    </div>
  );
};
