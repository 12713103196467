import {
  Notification,
  NotificationId,
  NotificationType,
} from '@axinom/mosaic-ui';
import { GlobalStateContext } from 'piral-core';
import React from 'react';
import { toast } from 'react-toastify';
import { ToastNotification } from '../../components/ToastNotification';

const toastConfig = {
  icon: false,
  hideProgressBar: true,
  closeOnClick: false,
};

const defaultType = 'info';
const defaultErrorAutoClose = 10000;
const defaultAutoClose = 5000;

export function showNotification(
  ctx: GlobalStateContext,
  { options, title, body }: Notification,
): NotificationId {
  const { autoClose, type = defaultType } = options || {};

  return toast(<ToastNotification title={title} body={body} type={type} />, {
    ...toastConfig,
    autoClose: getAutoClose(type, autoClose),
    type,
  });
}

export function updateNotification(
  ctx: GlobalStateContext,
  id: NotificationId,
  { title, body, options }: Notification,
): void {
  const { autoClose, type = defaultType } = options || {};

  toast.update(id, {
    ...toastConfig,
    autoClose: getAutoClose(type, autoClose),
    render: <ToastNotification title={title} body={body} type={type} />,
  });
}

export function dismissNotification(
  ctx: GlobalStateContext,
  id: NotificationId,
): void {
  toast.dismiss(id);
}

function getAutoClose(
  type: NotificationType,
  autoClose?: false | number,
): number | false {
  switch (type) {
    case 'error':
      return autoClose === undefined ? defaultErrorAutoClose : autoClose;
    default:
      return autoClose === undefined ? defaultAutoClose : autoClose;
  }
}
