import { useHasPermissions } from '@axinom/mosaic-id-link';
import { useGlobalState } from 'piral-core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  HomeStateConnectorProps,
  HomeTileData,
  SettingsTileData,
} from './types';

// TProps generic can be used to define custom props for the component.
export function withState<TProps>(
  Component: React.ComponentType<TProps & HomeStateConnectorProps>,
): React.FC<TProps> {
  const HomeStateConnector: React.FC<TProps> = (props) => {
    const allTiles = useGlobalState((s) => s.registry.home.tiles);
    const pages = useGlobalState((s) => s.registry.pages);
    const sorter = useGlobalState((s) => s.registry.home.sorter);

    const checkPermission = useHasPermissions();

    const [tiles, setTiles] = useState<{
      home?: HomeTileData[];
      settings?: SettingsTileData[];
    }>();
    useEffect(() => {
      const fn = async (): Promise<void> => {
        const home: HomeTileData[] = [];
        const settings: SettingsTileData[] = [];

        await Promise.all(
          Object.keys(allTiles).map(async (tileKey) => {
            const tile = allTiles[tileKey];
            // checks if the tile has enough permission to get it rendered.
            const requiredPermissions = pages[tile.path]?.meta?.permissions;

            if (
              requiredPermissions !== undefined &&
              !(await checkPermission(requiredPermissions))
            ) {
              return;
            }

            switch (tile.kind) {
              case 'settings':
                settings.push(tile);
                break;
              case 'home':
                home.push(tile);
                break;
              default:
                break;
            }
          }),
        );

        setTiles({ home, settings });
      };
      fn();
    }, [checkPermission, pages, allTiles]);

    return (
      <Component
        homeTileData={tiles?.home}
        settingsTileData={tiles?.settings}
        tileSorter={sorter}
        {...props}
      />
    );
  };

  return HomeStateConnector;
}
