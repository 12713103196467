import React, { useCallback, useState } from 'react';
import { PageHeaderActionsGroupContext } from './PageHeaderActionsGroupsContext';

type CollapseAction = Record<string, () => Promise<void>>;

export const PageHeaderActionsGroupContextProvider: React.FC = ({
  children,
}) => {
  const [registeredCollapses, setRegisteredCollapses] =
    useState<CollapseAction>({});

  const registerCollapse = useCallback(
    (collapse: () => Promise<void>, uuid: string): void => {
      setRegisteredCollapses((prev) => ({ ...prev, [uuid]: collapse }));
    },
    [],
  );

  const collapseAll = useCallback(
    (uuid: string) => {
      return Promise.all(
        Object.keys(registeredCollapses)
          .filter((key) => key !== uuid)
          .map((key) => registeredCollapses[key]()),
      );
    },
    [registeredCollapses],
  );

  return (
    <PageHeaderActionsGroupContext.Provider
      value={{ registerCollapse, collapseAll }}
    >
      {children}
    </PageHeaderActionsGroupContext.Provider>
  );
};
