import React from 'react';
import { Link } from 'react-router-dom';
import { Message } from '../Message';
import classes from './PermissionDeniedError.module.scss';

export const PermissionDeniedError: React.FC = () => {
  return (
    <div className={classes.container}>
      <Message type="error" title="Access Denied" />
      <div className={classes.wrapper}>
        <h1>403</h1>
        <p className={classes.body}>
          Sorry, seems you don&apos;t have enough rights to view this page.
          Please contact your administrator.
          <br />
          {'Back to '}
          <Link to="/" className={classes.link}>
            Home Page
          </Link>
        </p>
      </div>
    </div>
  );
};
