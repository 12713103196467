import { GlobalStateContext } from 'piral-core';

export function addProvider(
  ctx: GlobalStateContext,
  type: string,
  data: object,
): void {
  ctx.dispatch((state) => {
    const providers = { ...state.registry.providers };

    // Check if the registration already exists. If it does, push the new data into the array, otherwise create a new registration object
    if (providers[type]) {
      const currentArray = [...providers[type]];

      const index = findIndex(currentArray, data);
      if (index === -1) {
        // it's not a duplicate, so push it
        currentArray.push({ ...data });
      } else {
        // it's a duplicate, so replace it
        currentArray[index] = { ...data };
      }

      return {
        ...state,
        registry: {
          ...state.registry,
          providers: {
            ...providers,
            [type]: currentArray,
          },
        },
      };
    } else {
      return {
        ...state,
        registry: {
          ...state.registry,
          providers: {
            ...state.registry.providers,
            [type]: [{ ...data }],
          },
        },
      };
    }
  });
}

/**
 * Tries to find the index of the object in the currentArray.
 * IMPORTANT: This does not compare the objects, but rather the stringified version of the objects.
 * @param currentArray
 * @param data
 * @returns
 */
function findIndex(currentArray: object[], data: object): number {
  const stringifiedElements = currentArray.map((element) =>
    JSON.stringify(element),
  );

  const index = stringifiedElements.indexOf(JSON.stringify(data));
  return index;
}

export function getProviders(ctx: GlobalStateContext, type: string): object[] {
  return ctx.readState((state) => state.registry.providers[type]);
}
